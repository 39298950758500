/* You can add global styles to this file, and also import other style files */
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

ngb-pagination .page-link.active, .active>.page-link{
  background-color: #000!important;
  border-color: #000!important;
  color: #fff!important;
}
.product-price del{
  padding-right: 8px!important;
}

.page-link{
  color:#000!important;
}
