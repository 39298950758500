/**=====================
     17. List CSS Start
==========================**/
.vertical-tab {
	.nav-tabs {
		display: inline-block;
		width: 100%;
		margin-right: 2%;
		margin-bottom: 0;

		.nav-link {
			border-color: $horizontal-border-color ;
			padding: 0.75rem 1.25rem;
			color: $theme-body-font-color;

			&.active {
				background-color: $primary-color;
				color: $white;
			}
		}

		.nav-item {
			border-color: $horizontal-border-color;

			&.show {
				.nav-link {
					background-color: $primary-color;
					color: $white;
				}
			}
		}
	}
}

.tab-content {
	display: inline-block;
	width: 70%;
	vertical-align: top;
}

.list-group-item.active {
	background-color: $primary-color;
	border-color: $primary-color;
	z-index: 1;
}

.list-group {
	i {
		margin-right: $list-group-margin;
	}

	img {
		height: 40px;
		width: 40px;
		margin-right: $list-group-margin;
	}
}

/**=====================
     17. List CSS Ends
==========================**/