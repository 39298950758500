/**=====================
   55. theme layout CSS Start
==========================**/
//Sidebar color scss
$color1: #405365;
$color2: #1c1d1e;
$color3: #0c0c3c;
$color4: #0747a6;
$color5: #4d3096;
@each $bg-name, $bg-color in (color1, $color1), (color2, $color2),
  (color3, $color3), (color4, $color4), (color5, $color5), (light, $light-color),
  (dark, #2f3c4e)
{
  .bg-#{$bg-name} {
    background-color: $bg-color;
  }
}
$i: 1;
@while $i<=6 {
  .bg-pattern#{$i} {
    background-image: url(../../images/sidebar-pattern/#{$i}.png);
    background-color: #2f3c4e;
  }
  .bg-img#{$i} {
    background-image: url(../../images/sidebar-image/#{$i}.jpg);
  }
  $i: $i + 1;
}
.page-wrapper {
  .page-body-wrapper {
    .page-sidebar {
      @each $sidebar-name, $sidebar-color in (color1, $color1),
        (color2, $color2), (color3, $color3), (color4, $color4),
        (color5, $color5), (light, $light-color), (dark, #2f3c4e)
      {
        &.#{$sidebar-name}-sidebar {
          // background-color: $sidebar-color;
          background-color: #fff;
          .sidebar-menu {
            > li {
              > a {
                &:hover {
                  color: $white;
                }
              }
            }
            .sidebar-submenu {
              > li {
                > a {
                  &:hover,
                  &.active {
                    color: $white;
                  }
                }
                &.active {
                  > a {
                    &.active {
                      color: $white;
                    }
                  }
                }
              }
            }
          }
          @if ($sidebar-name == light) {
            .sidebar-user {
              h6 {
                color: $primary-color;
              }
              p {
                color: $theme-body-font-color;
              }
            }
            .sidebar-menu {
              > li {
                > a {
                  color: $theme-body-font-color;
                  &:hover {
                    color: $primary-color;
                  }
                }
              }
              .sidebar-submenu {
                > li {
                  > a {
                    color: $theme-body-font-color;
                    &:hover,
                    &.active {
                      color: $primary-color;
                    }
                  }
                  &.active {
                    > a {
                      &.active {
                        color: $primary-color;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      $i: 1;
      @while $i<=6 {
        &.sidebar-pattern#{$i} {
          background-image: url(../../images/sidebar-pattern/#{$i}.png);
          background-color: #2f3c4e !important;
        }
        &.sidebar-img#{$i} {
          background-image: url(../../images/sidebar-image/#{$i}.jpg);
        }
        $i: $i + 1;
      }
    }
  }
}
//Sidebar settings
.page-wrapper {
  .page-body-wrapper {
    .page-sidebar {
      &[sidebar-layout="border-sidebar"] {
        .sidebar-header {
          border-bottom: 1px solid rgba(124, 124, 124, 0.15);
          transition: all 0.3s ease;
        }
      }
      &[sidebar-layout="iconcolor-sidebar"] {
        .sidebar-menu {
          li {
            $colors: $success-color, $secondary-color, $primary-color,
              $danger-color, $info-color, $warning-color, $light-color;
            $repeat: 100;
            @for $i from 1 through $repeat {
              &:nth-child(#{length($colors)}n + #{$i}) {
                svg {
                  color: lighten(nth($colors, random(length($colors))), 15%);
                }
                a {
                  &:hover {
                    > span {
                      color: lighten(
                        nth($colors, random(length($colors))),
                        15%
                      );
                    }
                  }
                }
              }
            }
          }
        }
        &.light-sidebar {
          .sidebar-menu {
            li {
              $colors: $success-color, $secondary-color, $primary-color,
                $danger-color, $info-color, $warning-color, $dark-color;
              $repeat: 100;
              @for $i from 1 through $repeat {
                &:nth-child(#{length($colors)}n + #{$i}) {
                  svg {
                    color: darken(nth($colors, random(length($colors))), 15%);
                  }
                  a {
                    &:hover {
                      span {
                        color: darken(
                          nth($colors, random(length($colors))),
                          15%
                        );
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .sidebar-header {
        transition: all 0.3s ease;
      }
    }
    &.sidebar-icon {
      .page-sidebar {
        &.light-sidebar {
          .sidebar-menu {
            li {
              &:hover {
                .single-header {
                  background-color: $light-color !important;
                }
                .sidebar-submenu {
                  background-color: $light-color !important;
                }
              }
              a:hover + ul {
                background-color: $light-color !important;
              }
              ul {
                li {
                  &:hover {
                    .sidebar-submenu {
                      background-color: $light-color !important;
                    }
                  }
                }
              }
            }
          }
        }
        &.color1-sidebar {
          .sidebar-menu {
            li {
              &:hover {
                .single-header {
                  background-color: $color1 !important;
                }
                .sidebar-submenu {
                  background-color: $color1 !important;
                }
              }
              a:hover + ul {
                background-color: $color1 !important;
              }
              ul {
                li {
                  &:hover {
                    .sidebar-submenu {
                      background-color: $color1 !important;
                    }
                  }
                }
              }
            }
          }
        }
        &.color2-sidebar {
          .sidebar-menu {
            li {
              &:hover {
                .single-header {
                  background-color: $color2 !important;
                }
                .sidebar-submenu {
                  background-color: $color2 !important;
                }
              }
              a:hover + ul {
                background-color: $color2 !important;
              }
              ul {
                li {
                  &:hover {
                    .sidebar-submenu {
                      background-color: $color2 !important;
                    }
                  }
                }
              }
            }
          }
        }
        &.color3-sidebar {
          .sidebar-menu {
            li {
              &:hover {
                .single-header {
                  background-color: $color3 !important;
                }
                .sidebar-submenu {
                  background-color: $color3 !important;
                }
              }
              a:hover + ul {
                background-color: $color3 !important;
              }
              ul {
                li {
                  &:hover {
                    .sidebar-submenu {
                      background-color: $color3 !important;
                    }
                  }
                }
              }
            }
          }
        }
        &.color4-sidebar {
          .sidebar-menu {
            li {
              &:hover {
                .single-header {
                  background-color: $color4 !important;
                }
                .sidebar-submenu {
                  background-color: $color4 !important;
                }
              }
              a:hover + ul {
                background-color: $color4 !important;
              }
              ul {
                li {
                  &:hover {
                    .sidebar-submenu {
                      background-color: $color4 !important;
                    }
                  }
                }
              }
            }
          }
        }
        &.color5-sidebar {
          .sidebar-menu {
            li {
              &:hover {
                .single-header {
                  background-color: $color5 !important;
                }
                .sidebar-submenu {
                  background-color: $color5 !important;
                }
              }
              a:hover + ul {
                background-color: $color5 !important;
              }
              ul {
                li {
                  &:hover {
                    .sidebar-submenu {
                      background-color: $color5 !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
$dark-body-background: #293240;
$dark-card-background: #2f3c4e;
$dark-small-font-color: #98a6ad;
$dark-all-font-color: rgba(255, 255, 255, 0.85);
$sidebar-submenu-font-color: rgba(255, 255, 255, 0.7);
$dark-border-color: #374558;
$dark-editor-document: #2b2b2b;

//Dark layout
body {
  &.light-only {
    .page-wrapper {
      .page-body-wrapper {
        .page-sidebar {
          background-color: $white;
          .sidebar-user {
            h6 {
              color: $primary-color;
            }
            p {
              color: $theme-body-font-color;
            }
          }
          .sidebar-menu {
            > li {
              > a {
                color: $theme-body-font-color;
                &:hover {
                  color: $primary-color;
                }
              }
            }
            .sidebar-submenu {
              > li {
                > a {
                  color: $theme-body-font-color;
                  &:hover,
                  &.active {
                    color: $primary-color;
                  }
                }
                &.active {
                  > a {
                    &.active {
                      color: $primary-color;
                    }
                  }
                }
              }
            }
          }
          .sidebar {
            &:hover {
              &.custom-scrollbar {
                &::-webkit-scrollbar-thumb {
                  background-color: rgba(0, 0, 0, 0.15);
                }
              }
            }
          }
        }
        .page-body {
          background-color: $light-color;
        }
      }
    }
  }
  &.dark-only {
    color: $dark-all-font-color;
    background-color: $dark-body-background;
    --bs-table-bg: $dark-body-background;
    .custom-datatable {
      table {
        border: 1px solid $dark-border-color;
      }
    }
    .dashboard-chat {
      .chat {
        .media {
          .message-main {
            &.smiley-bg {
              background-color: $dark-body-background;
            }
            p {
              background-color: $dark-body-background;
            }
          }
        }
      }
    }
    .custom-datepicker-dropdown
      .form-group
      .input-group
      .input-group-append
      .btn {
      color: $dark-all-font-color;
      background-color: $dark-body-background;
      border: 1px solid $dark-border-color;
    }
    .accordion-button:not(.collapsed) {
      box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -1) 0
        $dark-border-color;
    }
    .accordion {
      --bs-accordion-btn-icon: none;
    }
    .accordion-item {
      border-color: $dark-border-color;
      color: $dark-all-font-color;
      background-color: $dark-body-background;
    }
    .accordion-header {
      .accordion-button {
        &:not(.collapsed) {
          background-color: $dark-body-background !important;
        }
      }
    }
    .table-striped > tbody > tr:nth-of-type(odd) > * {
      --bs-table-accent-bg: $dark-body-background;
    }
    .table-hover > tbody > tr:hover > * {
      --bs-table-accent-bg: $dark-body-background;
    }
    .browser-widget {
      .mediaClass {
        h4,
        .h4 {
          color: $dark-all-font-color;
          span {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .table-striped > tbody > tr:nth-of-type(odd) > * {
      background-color: $dark-body-background;
    }
    .table > :not(caption) > * > * {
      background-color: $dark-card-background;
    }

    .ngb-dp-header {
      background-color: $primary-color;
    }
    .custom-datepicker {
      background-color: $dark-body-background;
      .ngb-dp-day,
      .ngb-dp-week-number,
      .ngb-dp-weekday,
      .ngb-dp-arrow {
        .btn-light {
          background-color: $dark-body-background !important;
          color: $dark-all-font-color !important;
        }
      }
      .ngb-dp-month {
        background-color: $dark-body-background;
        .ngb-dp-week {
          background-color: $dark-body-background;
        }
      }
      .ngb-dp-weekday {
        color: $dark-all-font-color;
      }
    }
    .widget-joins .mediaClass .details {
      border-left-color: $dark-border-color;
    }
    .chart-block {
      svg > g > g g text {
        fill: $dark-small-font-color;
      }
      svg > g > text {
        fill: $dark-all-font-color;
      }
    }
    .accordion-header .accordion-button {
      background-color: $dark-body-background;
      color: $dark-all-font-color;
    }
    .ngb-tp-hour,
    .ngb-tp-minute,
    .ngb-tp-second {
      border: 1px solid $dark-body-background;
    }
    .txt-dark {
      color: $dark-all-font-color !important;
    }
    .swal2-html-container {
      color: $dark-all-font-color;
    }
    .was-validated .form-select:valid:not([multiple]):not([size]),
    .was-validated .form-select:valid:not([multiple])[size="1"],
    .form-select.is-valid:not([multiple]):not([size]),
    .form-select.is-valid:not([multiple])[size="1"],
    .was-validated .form-select:invalid:not([multiple]):not([size]),
    .was-validated .form-select:invalid:not([multiple])[size="1"],
    .form-select.is-invalid:not([multiple]):not([size]),
    .form-select.is-invalid:not([multiple])[size="1"] {
      background-repeat: no-repeat;
    }
    .input-group-prepend,
    .input-group-append {
      span {
        background-color: $dark-body-background;
        border: $dark-card-background;
        color: $white;
      }
    }
    tbody {
      border-color: $dark-border-color;
    }
    .product-details h5,
    .product-details .h5 {
      color: inherit;
    }

    .browse {
      .browse-articles {
        h6 {
          border-bottom: 1px solid $dark-border-color;
        }
      }
    }
    .config-popup {
      border-bottom: none !important;
    }
    .modal-copy-header {
      h5 {
        color: $dark-all-font-color;
      }
      .btn-close {
        color: $dark-all-font-color;
      }
    }
    .search-knowledge {
      input {
        background-color: $dark-card-background;
        border: 1px solid $dark-border-color;
        color: $dark-small-font-color;
      }
    }
    .btn-outline-light {
      color: $dark-all-font-color;
    }
    .product-wrapper {
      &.sidebaron {
        .product-sidebar {
          .filter-section {
            .card {
              .left-filter {
                background-color: $dark-card-background;
              }
            }
          }
        }
      }
    }
    .search-page {
      .nav-tabs {
        border-bottom: none !important;
        ngb-tabset {
          .nav-tabs {
            border-bottom: 1px solid $dark-border-color !important;
          }
        }
      }
      ul {
        &.search-info {
          li {
            color: inherit;
            + li {
              border-left: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    .social-app-tab {
      .hovercard {
        background-color: $dark-body-background !important;
        box-shadow: none !important;
      }
      .nav-material {
        &.tabs-scoial {
          ul {
            &.nav-tabs {
              background: $dark-card-background;
            }
          }
          .timeline-content {
            background: $dark-card-background;
          }
        }
      }
    }
    .job-search {
      .job-description {
        .theme-form {
          .row {
            div[class^="col-"] {
              .job-select2 {
                background-color: $dark-body-background;
                border-color: $dark-border-color !important;
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
    }
    .cal-month-view {
      background-color: $dark-card-background;
      .cal-cell-row {
        &:hover {
          background-color: $dark-body-background;
        }
        .cal-cell:hover,
        .cal-cell.cal-has-events.cal-open {
          background-color: $dark-border-color;
        }
      }
      .cal-days {
        border-color: $dark-border-color;
        .cal-cell-row {
          border-bottom-color: $dark-border-color;
        }
      }
      .cal-day-cell {
        &:not(:last-child) {
          border-right-color: $dark-border-color;
        }
      }
    }
    .contact-profile {
      .icon-wrapper {
        background-color: $dark-card-background;
      }
    }
    .my-app-custom-plain-container-with-desc {
      figure {
        img,
        .caption {
          border: 1px solid $dark-border-color !important;
        }
        .caption {
          border-top: none !important;
        }
      }
    }
    .modal-gallery-wrapper {
      a {
        > div {
          border: none !important;
        }
      }
    }
    .custom-mesonry,
    .mega-inline,
    .mega-horizontal {
      img,
      .card {
        border: 1px solid $dark-border-color;
      }
    }
    .custom-datatable {
      input {
        border: 1px solid $dark-border-color;
        color: $light-text;
        &::placeholder {
          color: $light-text;
        }
      }
      .ngx-datatable {
        &.bootstrap {
          .datatable-header {
            border: 1px solid $dark-border-color;
            border-bottom: none;
            .datatable-header-cell {
              border-bottom: none;
            }
          }
          .datatable-body {
            border: 1px solid $dark-border-color;
            border-top: none;
            .datatable-selection {
              .datatable-scroll {
                .datatable-row-wrapper {
                  &:last-child {
                    border-bottom: none;
                  }
                }
              }
            }

            .datatable-body-row {
              border-top: 1px solid $dark-border-color;
              &.active {
                background-color: $primary-color;
              }
              &.datatable-row-even {
                background-color: $dark-body-background;
                &:hover {
                  background-color: #28303c;
                }
                &.active {
                  background-color: $primary-color;
                }
              }
              &.datatable-row-odd {
                &:hover {
                  background-color: #28303c;
                }
              }
            }
          }
          .datatable-footer {
            background: $dark-card-background;
            .datatable-pager {
              ul {
                li {
                  &:not(.disabled).active {
                    a {
                      background-color: $primary-color;
                    }
                  }
                  &:not(.disabled):hover {
                    a {
                      border: 1px solid $primary-color;
                    }
                  }
                }
              }
            }
          }
        }
        .datatable-footer {
          .datatable-pager {
            .pager {
              border: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    .timeline-content {
      background: $dark-body-background;
      &::after {
        border-color: transparent transparent transparent $dark-body-background;
      }
    }
    .timeline-item {
      &:nth-child(even) {
        .timeline-content {
          &::after {
            border-color: transparent $dark-body-background transparent
              transparent;
          }
        }
      }
    }
    #demo3,
    #demo4 {
      background-color: $dark-card-background;
    }
    .my-drop-zone {
      background-color: $dark-border-color;
      border: dotted 3px $dark-body-background;
    }
    .upload-input,
    .upload-table {
      input,
      table {
        border: 1px solid $dark-border-color;
      }
    }
    .swal2-popup {
      background: $dark-card-background;
      &.text-dark {
        .swal2-title {
          color: $theme-body-font-color;
        }
      }
      .swal2-title {
        color: $dark-all-font-color;
      }
      .swal2-content {
        color: $dark-small-font-color;
      }
      .swal2-footer {
        border-top: 1px solid $dark-border-color;
      }
      .swal2-styled {
        &:focus {
          box-shadow: 0 0 0 2px $dark-card-background,
            0 0 0 4px rgba(50, 100, 150, 0.4);
        }
      }
      .swal2-file:focus,
      .swal2-input:focus,
      .swal2-textarea {
        color: $dark-all-font-color;
        &:focus {
          border: 1px solid $dark-border-color;
          box-shadow: 0 0 3px $dark-card-background;
        }
      }
    }
    .accordion {
      > .card {
        .card-header {
          .btn-link {
            color: $dark-all-font-color;
          }
        }
      }
    }
    #project {
      .nav-tabs {
        .nav-link {
          color: $dark-all-font-color;
          &.active {
            color: $primary-color;
          }
        }
      }
    }
    .vertical-tab {
      .nav-tabs {
        .nav-item {
          border-color: $dark-border-color;
          .nav-link {
            color: $dark-all-font-color;
            border-color: $dark-border-color;
            &.active {
              background-color: $primary-color;
            }
          }
          &:hover {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .server-datatable {
      .table-responsive {
        table {
          border: 1px solid $dark-border-color;
          tr {
            border-top: 1px solid $dark-border-color;
            &:nth-child(even) {
              background-color: #293240;
              td,
              th {
                &:first-child {
                  background-color: #28303c;
                }
              }
              &:hover {
                background-color: #28303c;
              }
            }
            &:nth-child(odd) {
              td,
              th {
                &:first-child {
                  background-color: #2e3a4c;
                }
              }
              &:hover {
                background-color: #28303c;
                td,
                th {
                  &:first-child {
                    background-color: #282f3a;
                  }
                }
              }
            }
          }
        }
      }
    }
    .ecommerce-widget {
      border: 1px solid $dark-border-color;
    }
    .cropper {
      outline-color: rgba($dark-card-background, 0.3) !important;
    }
    .drag-n-drop {
      &.parent {
        background-color: rgba($dark-card-background, 0.2);
      }
    }
    .bit-coin-tab {
      .nav-tabs {
        .nav-link {
          &.active {
            background-color: $primary-color;
          }
        }
      }
    }
    .custom-datepicker,
    .mutliple-datepicker {
      .ngb-dp-navigation-select {
        .form-select {
          background-color: $white !important;
        }
      }
      .form-select,
      .custom-file-label {
        color: $theme-body-font-color !important;
      }
      .form-select {
        &:disabled {
          color: #6c757d !important;
          background-color: #e9ecef !important;
        }
      }
    }
    .mutliple-datepicker,
    .custom-datepicker-dropdown {
      .custom-day {
        color: $theme-body-font-color;
        &.range,
        &:hover {
          color: $white;
        }
      }
      ngb-datepicker,
      .dropdown-menu {
        background-color: $white !important;
        border: none !important;
      }
      .align-content-between {
        .form-select {
          color: $dark-all-font-color !important;
          border: 1px solid $dark-border-color !important;
        }
      }
      hr {
        border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
      }
    }
    .dropdown-item {
      color: $dark-small-font-color;
      &:hover,
      &:focus {
        background-color: $dark-card-background;
      }
    }
    .offcanvas-bookmark {
      &.page-wrapper {
        .page-body-wrapper {
          .page-body {
            .bookmark {
              ul {
                li {
                  form {
                    .form-control-search {
                      .Typeahead-menu {
                        .ProfileCard {
                          .ProfileCard-details {
                            .ProfileCard-realName {
                              a {
                                color: $dark-small-font-color !important;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .modal-footer {
      border-top: 1px solid $dark-border-color;
    }
    .bookmark {
      ul {
        li {
          a {
            color: $dark-small-font-color !important;
          }
          .search-form {
            .form-control-search {
              input {
                background-color: $dark-body-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
              &:before {
                background: $dark-border-color;
              }
            }
          }
        }
      }
    }
    .cart {
      .qty-box {
        .input-group {
          .btn {
            background-color: $dark-border-color !important;
            border-color: $dark-border-color !important;
          }
        }
      }
    }
    .checkout {
      .checkout-details {
        background-color: $dark-body-background;
        border: 1px solid $dark-border-color;
        padding: 40px;
      }
    }
    .order-box {
      .title-box {
        color: #bfc2c6;
        border-bottom: 1px solid $dark-border-color;
      }
      .qty {
        li {
          color: #bfc2c6;
          span {
            color: #bfc2c6;
          }
        }
        border-bottom: 1px solid $dark-border-color;
      }
      .sub-total {
        li {
          color: #bfc2c6;
        }
      }
      .total {
        li {
          color: #bfc2c6;
        }
      }
    }
    .shopping-checkout-option {
      .checkbox_animated {
        &:after {
          border: 2px solid $dark-border-color;
        }
      }
    }
    .animate-chk {
      .radio_animated {
        &:after {
          border: 2px solid $dark-border-color;
        }
      }
    }
    .custom-scrollbar::-webkit-scrollbar-thumb {
      background-color: rgba(41, 50, 64, 0.52);
    }
    .product-box {
      .product-img {
        .product-hover {
          ul {
            li {
              .btn {
                color: $dark-small-font-color;
              }
              &:hover {
                .btn {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
    .radio_animated,
    .checkbox_animated {
      &:after {
        background: $dark-body-background;
        border: 2px solid $dark-body-background;
      }
    }
    .slider-product {
      border-top: 1px solid $dark-border-color;
      border-bottom: 1px solid $dark-border-color;
    }
    .square-product-setting {
      .icon-grid {
        background-color: $dark-card-background;
        svg {
          color: $dark-all-font-color;
        }
      }
    }
    .bitcoin-form {
      .bitcoin-form-dropdown {
        .onhover-dropdown {
          .btn {
            background-color: $dark-border-color;
            color: $dark-all-font-color;
          }
          .onhover-show-div {
            background-color: $dark-body-background;
            box-shadow: 0 0 2px 2px $dark-body-background;
          }
        }
      }
      .row {
        .input-group {
          .input-group-prepend {
            .input-group-text {
              border: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    .active-order-table,
    .market-table {
      table {
        thead {
          tr {
            th {
              border-top: none !important;
            }
          }
        }
        tbody {
          tr {
            td {
              border-bottom: 1px solid $dark-border-color;
            }
            &:last-child {
              td {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
    .pricing-card-design-2 {
      .pricing-block {
        .pricing-inner {
          ul {
            li {
              border-bottom: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    .flot-chart-container {
      .legend {
        .table {
          tbody {
            background-color: $dark-card-background;
            color: $white;
            .legendLabel {
              padding-left: 5px;
            }
          }
        }
      }
    }
    .google-visualization-tooltip {
      text {
        fill: rgba(0, 0, 0, 0.7) !important;
      }
    }
    .nav-dark,
    .nav-pills.nav-dark {
      .nav-link {
        color: $white;
      }
    }
    .loader-wrapper {
      background-color: $dark-body-background;
      .loader {
        background-color: $dark-body-background !important;
      }
    }
    .page-wrapper {
      /* Main Header start */
      .Typeahead-menu {
        background-color: $dark-body-background;
      }
      .ProfileCard {
        &:hover {
          background: $dark-card-background;
        }
      }
      .ProfileCard-realName {
        a,
        span {
          color: $dark-small-font-color !important;
        }
      }
      .page-main-header {
        background-color: $dark-card-background;
        .main-header-right {
          background-color: $dark-card-background;
          li {
            i {
              color: $light-color;
            }
          }
          .nav-right {
            > ul {
              > li {
                border-left: 1px solid $dark-border-color;
                &:last-child,
                &:nth-child(5),
                &:nth-child(1) {
                  border-left: none;
                }
                &:nth-child(5) {
                  border-right: 1px solid $dark-border-color;
                }
                &:first-child {
                  .search-form {
                    .form-group {
                      &:before {
                        background: $dark-border-color;
                      }
                    }
                  }
                }
              }
            }
            .nav-menus {
              li {
                .media {
                  .media-body {
                    .txt-dark {
                      color: $dark-all-font-color !important;
                    }
                  }
                }
                .onhover-show-div {
                  background-color: $dark-body-background;
                  box-shadow: 0 0 2px 2px $dark-card-background;
                  &:before {
                    border-bottom: 7px solid $dark-body-background;
                  }
                  &:after {
                    border-bottom: 7px solid $dark-body-background;
                  }
                }
                .notification-dropdown.onhover-show-div {
                  li {
                    border-bottom: 1px solid $dark-border-color;
                    span {
                      color: $dark-small-font-color;
                    }
                    &:hover {
                      background-color: #282e38;
                    }
                  }
                  .bg-light {
                    background-color: #282e38 !important;
                    color: $dark-all-font-color !important;
                  }
                }
                .language-dropdown {
                  li {
                    a {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
            }
            .profile-dropdown {
              li {
                a {
                  color: $dark-all-font-color;
                  svg {
                    color: $dark-all-font-color !important;
                    path,
                    line,
                    circle {
                      color: $dark-all-font-color !important;
                    }
                  }
                  &:hover {
                    color: $primary-color !important;
                    svg {
                      line,
                      path,
                      circle {
                        color: $primary-color !important;
                      }
                    }
                  }
                }
              }
            }
            .search-form {
              input {
                background-color: $dark-body-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
            }
            > ul {
              > li {
                .media {
                  .media-body {
                    .txt-dark {
                      color: $dark-all-font-color !important;
                    }
                  }
                }
                .onhover-show-div {
                  background-color: $dark-body-background;
                  box-shadow: 0 0 2px 2px $dark-card-background;
                  &:before {
                    border-bottom: 7px solid $dark-body-background;
                  }
                  &:after {
                    border-bottom: 7px solid $dark-body-background;
                  }
                }
                .notification-dropdown.onhover-show-div {
                  li {
                    border-bottom: 1px solid $dark-border-color;
                    span {
                      color: $dark-small-font-color;
                    }
                  }
                }
                .language-dropdown {
                  li {
                    a {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
            }
          }
          .mega-menu-header {
            .vertical-menu-main {
              .main-nav {
                .main-menu {
                  li {
                    background-color: $dark-card-background;
                  }
                }
              }
            }
          }
        }
        .main-header-left {
          background-color: $dark-card-background;
          .logo-wrapper {
            a {
              .image-dark {
                display: none;
              }
              .image-light {
                display: block;
              }
            }
          }
        }
        @media only screen and (max-width: 991px) {
          .main-header-right {
            .nav-right {
              > ul {
                background-color: $dark-card-background;
                box-shadow: 0 2px 2px 2px $dark-border-color;
              }
            }
          }
        }
        @media only screen and (max-width: 575px) {
          .nav-right {
            > ul {
              .search-form {
                .form-control-plaintext {
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                  color: $dark-all-font-color;
                }
              }
            }
          }
        }
      }
      .right-sidebar {
        background-color: $dark-body-background;
        box-shadow: 0 0 11px rgba(69, 110, 243, 0.13);
        .modal-header {
          border-bottom: 1px solid $dark-border-color;
        }
        .friend-list-search {
          background-color: $dark-card-background;
          input {
            background-color: $dark-body-background;
            border: 1px solid $dark-border-color;
          }
        }
      }
      /* Main Header ends */
      .page-body-wrapper {
        /* sidebar start */
        &.sidebar-icon {
          .page-sidebar {
            .sidebar-menu {
              .sidebar-header {
                > i {
                  color: $dark-all-font-color;
                }
              }
              li {
                background-color: $dark-card-background;
                &:hover {
                  .single-header,
                  .sidebar-submenu {
                    background-color: $dark-card-background;
                  }
                }
                a:hover + ul {
                  background-color: $dark-card-background;
                }
              }
            }
            .sidebar-user {
              box-shadow: 3px 2px 7px -1px rgb(41, 50, 64);
            }
          }
        }
        .page-sidebar {
          &.native-image-bg {
            background-blend-mode: overlay;
            background-color: #17191d;
          }
          &.navigation-bordered {
            .sidebar-header {
              border-bottom: 1px solid rgba(241, 241, 241, 0.15);
            }
          }
          background: $dark-card-background;
          .sidebar-user {
            background-color: $dark-card-background;
            box-shadow: 3px 2px 8px -1px rgba(255, 255, 255, 0.13);
            h6 {
              color: $dark-all-font-color;
            }
            p {
              color: $white;
            }
          }
          .sidebar-menu {
            .sidebar-title {
              color: $primary-color;
              border-bottom: 1px solid rgba(241, 241, 241, 0.35);
            }
            > li {
              > a {
                color: $dark-all-font-color;
                &.active {
                  color: $white;
                }
                &:hover {
                  color: $white;
                }
              }
              .label {
                margin-top: 3px;
                margin-right: 5px;
              }
              &:hover > a {
                @extend %common;
                color: $dark-all-font-color;
              }
            }
            li {
              &.sidebar-header {
                color: $warning-color;
              }
            }
            a {
              color: $light-color;
            }
          }
          .sidebar-widget {
            border-top: 1px solid $dark-border-color;
            .sidebar-widget-top {
              i {
                background-color: $dark-card-background;
              }
            }
            .sidebar-widget-bottom {
              background-color: $dark-body-background;
            }
          }
        }
        /* sidebar end */

        /* body start*/
        .page-body {
          .topper-lists {
            table {
              tbody {
                tr {
                  td {
                    p {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
            }
          }
          .header-small {
            color: $dark-small-font-color;
          }
          .statistics {
            p {
              color: $dark-small-font-color;
            }
          }
          .feather-main,
          .professor-table {
            .media-body,
            .professor-block {
              p {
                color: $dark-small-font-color;
              }
            }
          }
          .logs-element {
            span {
              + span {
                color: $dark-small-font-color;
              }
            }
          }
          .progress-block {
            .progress-title {
              span {
                + span {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .ecommerce-icons {
            div {
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .new-users,
          .recent-notification {
            .d-flex {
              .flex-grow-1 {
                p {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .progress-media {
            .media {
              .media-body {
                span {
                  color: $dark-small-font-color;
                }
              }
            }
            .progress-change {
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .topper-lists {
            table {
              tbody {
                tr {
                  td {
                    border-top: none !important;
                    border-bottom: 1px solid $dark-border-color;
                  }
                  &:last-child {
                    td {
                      border-bottom: none;
                    }
                  }
                }
              }
            }
          }
          .notifiaction-media {
            .media {
              .media-body {
                .circle-left {
                  border: 5px solid #334154;
                }
              }
            }
          }
          .upcoming-event {
            .upcoming-innner {
              border-bottom: 1px solid $dark-border-color;
              &:last-child {
                border-bottom: none;
              }
            }
          }
          .professor-table {
            table {
              tbody {
                tr {
                  td {
                    border-top: none !important;
                  }
                }
              }
            }
          }
          .number-widgets {
            .media {
              .media-body {
                h6 {
                  color: $dark-all-font-color;
                }
              }
            }
          }
          .activity {
            .media {
              .gradient-round {
                &.gradient-line-1,
                &.small-line,
                &.medium-line {
                  &:after {
                    background-color: $dark-border-color;
                  }
                }
              }
              .media-body {
                h6 {
                  span {
                    color: $dark-small-font-color;
                  }
                }
              }
            }
          }
          .card.custom-card {
            .card-header {
              border-bottom: none !important;
            }
          }
        }
        .custom-card .card-header img {
          background-color: $black;
          opacity: 0.8;
        }

        .page-header {
          .row {
            h3 {
              small {
                color: $dark-small-font-color;
              }
            }
          }
          .breadcrumb-item {
            &.active {
              color: $dark-small-font-color;
            }
          }
        }
        .page-body {
          background-color: $dark-body-background;
          .card {
            background-color: $dark-card-background;
            box-shadow: 0px 0px 1px 1px $dark-border-color;
            .card-header {
              background-color: $dark-card-background;
              border-bottom: 1px solid $dark-border-color;
              .card-header-right {
                background-color: $dark-card-background;
              }
              .buttons-right {
                .right-header-dropdown.onhover-show-div {
                  background-color: $dark-body-background;
                  box-shadow: 0 0 2px 2px $dark-body-background;
                  a {
                    color: $dark-all-font-color;
                  }
                }
              }
              &.card-header-border {
                .right-header {
                  span {
                    &:first-child {
                      color: $dark-small-font-color;
                    }
                  }
                  .right-header-dropdown.onhover-show-div {
                    background-color: $dark-body-background;
                    box-shadow: 0 0 2px 2px $dark-body-background;
                    a {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
              > span {
                color: $dark-all-font-color;
              }
              h1,
              h2,
              h3,
              h4,
              h5,
              h6 {
                color: $dark-all-font-color;
              }
              .card-header-right {
                i {
                  color: $dark-all-font-color;
                }
              }
            }
            .alert-dark {
              color: $dark-small-font-color;
              a {
                color: $dark-small-font-color;
              }
            }
            .alert {
              &.alert-custom {
                border-color: $dark-border-color;
              }
            }
            .alert-light.outline,
            .alert-light.outline-2x,
            .alert-light.inverse {
              color: $dark-all-font-color;
            }
            #animation-box {
              .animate-widget {
                p {
                  color: $dark-small-font-color !important;
                }
              }
            }
            .grid-item {
              img {
                background-color: $dark-card-background;
                border: 1px solid $dark-border-color;
              }
            }
            .line {
              color: $dark-all-font-color;
            }
            .table {
              th,
              td {
                color: $dark-all-font-color;
              }
              thead {
                th {
                  border-bottom: 2px solid $dark-border-color;
                }
                .border-bottom-primary {
                  th {
                    border-bottom: 1px solid $primary-color;
                  }
                }
              }
              .table-primary,
              .table-secondary,
              .table-success,
              .table-info,
              .table-warning,
              .table-light,
              .table-danger {
                th,
                td {
                  color: $sidebar-submenu-font-color;
                }
              }
              .bg-primary,
              .bg-secondary,
              .bg-success,
              .bg-info,
              .bg-warning,
              .bg-danger {
                color: $white;
              }
              .bg-light {
                color: $black;
              }
              .double,
              .dotted,
              .dashed {
                border-color: $dark-border-color;
              }
              tbody {
                .border-bottom-primary {
                  th,
                  td {
                    border-bottom: 1px solid $primary-color;
                  }
                }
              }
            }
            .table-responsive {
              .table {
                th,
                td {
                  border-top: 1px solid $dark-border-color;
                }
              }
            }
            .table[class*="bg-"] {
              th,
              td {
                color: $white;
              }
            }
            .table-striped {
              tbody {
                tr {
                  &:nth-of-type(odd) {
                    background-color: rgba(0, 0, 0, 0.05);
                    &:hover {
                      th,
                      td {
                        color: $white;
                      }
                    }
                  }
                }
              }
            }
            .table-bordered {
              td,
              th {
                border-color: $dark-border-color;
              }
            }
            .table-inverse {
              tbody {
                tr {
                  &:nth-of-type(odd) {
                    &:hover {
                      td {
                        color: $dark-all-font-color;
                      }
                    }
                  }
                }
              }
            }
            .table-border-vertical {
              tr,
              th,
              td {
                border-right: 1px solid $dark-border-color;
              }
            }
            .table-styling {
              thead,
              tbody {
                th,
                td {
                  color: $white;
                }
              }
            }
            .card-footer {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-border-color;
            }
            .switch {
              .switch-state {
                background-color: $dark-body-background;
              }
              input {
                &:checked {
                  + .switch-state {
                    background-color: $primary-color;
                  }
                }
              }
            }
            .bg-white {
              background-color: $dark-card-background !important;
            }
            .b-l-light {
              border-left: 1px solid $dark-border-color !important;
            }
            .ct-grid {
              stroke: $dark-small-font-color;
            }
            .ct-label {
              color: $dark-small-font-color;
            }
            hr {
              border-top: 1px solid $dark-border-color;
            }
            .calender-widget {
              .cal-date {
                background-color: $dark-card-background;
                border: 1px solid $dark-border-color;
              }
            }
            .contact-form {
              .theme-form {
                border: 1px solid $dark-border-color;
                .form-icon {
                  background-color: $dark-card-background;
                  border: 1px solid $dark-border-color;
                  color: $dark-all-font-color;
                }
              }
            }
            .btn-outline-light,
            .btn-outline-dark,
            .btn-outline-light-2x {
              color: $white !important;
            }
            .btn-outline-light {
              &:hover {
                color: $black !important;
              }
            }

            .border-right {
              border-right: 1px solid $dark-border-color !important;
            }
            .flot-chart-placeholder {
              .flot-text {
                color: $dark-all-font-color !important;
              }
              svg {
                text {
                  fill: $dark-all-font-color;
                }
              }
            }
            .chart-overflow {
              &#line-chart,
              &#column-chart1,
              &#column-chart2 {
                rect {
                  fill: $dark-card-background;
                }
              }
              svg {
                > rect {
                  fill: $dark-card-background;
                }
                > g {
                  > g {
                    > g {
                      text {
                        fill: rgb(177, 177, 178);
                      }
                    }
                  }
                  > text {
                    fill: $dark-all-font-color;
                  }
                }
              }
            }
            .radial-bar {
              &:after {
                background-color: $dark-card-background;
                color: $dark-all-font-color;
              }
            }
            .bar-chart-widget {
              .bottom-content {
                .num {
                  color: $dark-all-font-color;
                  .color-bottom {
                    color: $dark-all-font-color;
                  }
                }
              }
            }
            .b-r-light {
              border-right: 1px solid $dark-border-color !important;
            }
            .chart-container {
              .live-products,
              .turnover,
              .monthly,
              .uses {
                .ct-labels {
                  .ct-label {
                    color: $white;
                  }
                }
                .ct-grid {
                  stroke: $white;
                }
              }
              #browser-uses-chart,
              #website-visiter-chart {
                svg {
                  text {
                    fill: $dark-all-font-color;
                  }
                }
              }
            }
            .status-details {
              h4 {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
            .clipboaard-container {
              .form-control {
                background-color: $dark-body-background;
                color: $dark-small-font-color;
                border: 1px solid $dark-border-color;
              }
            }
            .img-thumbnail {
              background-color: $dark-body-background;
              border: 1px solid $dark-border-color;
            }
            .dataTables_wrapper {
              .dataTables_length {
                select {
                  background-color: $dark-body-background;
                  color: $dark-all-font-color;
                  border: 1px solid $dark-border-color;
                }
              }
              .dataTables_length,
              .dataTables_filter,
              .dataTables_info,
              .dataTables_processing,
              .dataTables_paginate {
                color: $dark-all-font-color;
              }
              .dataTables_paginate {
                border: 1px solid $dark-border-color;
              }
              .dataTables_filter {
                input[type="search"] {
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                  color: $dark-all-font-color;
                }
              }
              table.dataTable.display,
              table.dataTable.order-column.stripe {
                tbody {
                  tr {
                    background-color: $dark-card-background;
                    &:hover {
                      > .sorting_1 {
                        background-color: #28303c;
                      }
                    }
                  }
                  tr.odd {
                    background-color: $dark-body-background;
                    > .sorting_1 {
                      background-color: #28303c;
                    }
                  }
                  tr.even {
                    > .sorting_1 {
                      background-color: rgb(46, 58, 76);
                    }
                  }
                }
              }
              table.dataTable {
                border: 1px solid $dark-border-color;
                thead {
                  th,
                  td {
                    border-bottom: 2px solid $dark-border-color;
                  }
                }
                input,
                select {
                  background-color: $dark-body-background;
                  color: $dark-all-font-color;
                  border: 1px solid $dark-border-color;
                }
                tbody {
                  tr {
                    background-color: $dark-card-background;
                  }
                  td.select-checkbox,
                  th.select-checkbox {
                    &:before {
                      border: 1px solid $dark-small-font-color;
                    }
                  }
                }
              }
              .table-striped {
                tbody {
                  tr {
                    &:nth-of-type(odd) {
                      background-color: $dark-body-background;
                    }
                  }
                }
              }
              .dataTables_paginate {
                .paginate_button {
                  color: $dark-all-font-color !important;
                  &.current,
                  &:active {
                    border-color: $primary-color;
                  }
                }
                .paginate_button.disabled {
                  color: $dark-small-font-color !important;
                  &:hover,
                  :active {
                    color: $dark-small-font-color !important;
                  }
                }
              }
              table.dataTable.row-border,
              table.dataTable.display {
                tbody {
                  th,
                  td {
                    border-top: 1px solid $dark-border-color;
                  }
                }
              }
              table.dataTable.display,
              table.dataTable.order-column.hover {
                tbody {
                  tr.even {
                    &:hover {
                      > .sorting_1 {
                        background-color: #282f3a;
                      }
                    }
                  }
                }
              }
              table.dataTable.hover,
              table.dataTable.display {
                tbody {
                  tr {
                    &:hover {
                      background-color: #28303c;
                    }
                  }
                }
              }
              table.dataTable.cell-border {
                th,
                td {
                  border-top: 1px solid $dark-border-color;
                  border-right: 1px solid $dark-border-color;
                  &:first-child {
                    border-left: 1px solid $dark-border-color;
                  }
                }
              }
              table.dataTable.order-column,
              table.dataTable.display {
                tbody {
                  tr {
                    > .sorting_1,
                    > .sorting_2,
                    > .sorting_3 {
                      background-color: $dark-body-background;
                    }
                  }
                }
              }
            }
            #example-style-3_wrapper {
              #example-style-3 {
                tfoot {
                  border-top: 2px solid $dark-border-color;
                }
              }
            }
            .page-item.disabled {
              .page-link {
                background-color: $dark-card-background;
                border-color: $dark-border-color;
              }
            }
            .page-link {
              background-color: $dark-card-background;
              border: 1px solid $dark-border-color;
              &:hover {
                background-color: $dark-border-color;
              }
            }
            .page-item.active {
              .page-link {
                background-color: $primary-color;
              }
            }
            .ecommerce-widget {
              .icon {
                color: $dark-body-background;
              }
              .total-num {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
            .flot-chart-container-small {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-border-color;
            }
            .product-table {
              #API-2_wrapper {
                #API-2 {
                  tbody {
                    td {
                      span,
                      p {
                        color: $dark-small-font-color;
                      }
                    }
                  }
                }
              }
              h6 {
                color: $dark-small-font-color;
              }
            }
            .nav-material.nav-tabs {
              border-bottom: 1px solid $dark-border-color;
              .nav-item {
                .nav-link {
                  color: $dark-all-font-color;
                }
              }
              .nav-link {
                &.active,
                &:focus,
                &:hover {
                  color: $primary-color;
                }
              }
            }
            .br-theme-bars-1to10,
            .br-theme-bars-movie,
            .br-theme-bars-pill,
            .br-theme-bars-reversed,
            .br-theme-bars-horizontal {
              .br-widget {
                a {
                  background-color: $dark-border-color;
                  &.br-active,
                  &.br-selected {
                    background-color: $primary-color;
                  }
                }
              }
            }
            .br-theme-bars-square {
              .br-widget {
                a {
                  border: 2px solid $dark-border-color;
                  background-color: $dark-card-background;
                  color: $dark-all-font-color;
                  &.br-active,
                  &.br-selected {
                    border: 2px solid $primary-color;
                    color: $primary-color;
                  }
                }
              }
            }
            .br-theme-fontawesome-stars,
            .br-theme-fontawesome-stars-o {
              .br-widget {
                a {
                  &:after {
                    color: $dark-border-color;
                  }
                  &.br-selected,
                  &.br-active {
                    &:after {
                      color: $primary-color;
                    }
                  }
                }
              }
            }
            .scroll-demo {
              border: 1px solid $dark-border-color;
            }
            .search-form {
              input {
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
                background-color: $dark-body-background;
              }
              .form-group {
                &:before {
                  background: $dark-card-background;
                }
              }
            }
            .cd-timeline-content {
              background-color: $dark-body-background;
              &::before {
                border-left: 7px solid $dark-border-color;
              }
            }
            .cd-timeline-block {
              &:nth-child(even) {
                .cd-timeline-content {
                  &::before {
                    border-right-color: $dark-border-color;
                    border-left: transparent;
                  }
                }
              }
            }
            .breadcrumb {
              background-color: $dark-body-background;
            }
            .user-status {
              table {
                td,
                th {
                  border-top: none !important;
                }
              }
            }
            #donut-color-chart-morris-daily,
            #donut-color-chart-morris,
            #browser-uses-chart {
              svg {
                opacity: 0.5;
              }
            }
          }
          .form-builder-header-1,
          .form-builder-2-header {
            background-color: $dark-body-background;
          }
          .form-builder {
            .nav-primary {
              .show {
                > .nav-link {
                  color: $white;
                }
              }
              .nav-link {
                color: $dark-all-font-color;
                &.active {
                  color: $white;
                }
              }
              .nav-pills.nav-primary {
                .show {
                  > .nav-link {
                    color: $white;
                  }
                }
                .nav-link {
                  color: $dark-all-font-color;
                  &.active {
                    color: $white;
                  }
                }
              }
            }
            .drag-box {
              fieldset {
                border: 1px solid $dark-border-color;
              }
            }
            .help-block {
              color: $dark-small-font-color;
            }
          }
          #viewhtml {
            .render {
              background-color: $dark-card-background;
              color: $dark-all-font-color;
              border-color: $dark-border-color;
            }
          }
          .form-builder-column {
            .drag-bx {
              border: 1px dotted $dark-border-color;
            }
          }
          .wizard-4 {
            ul.anchor {
              li {
                a.disabled {
                  color: $dark-body-background;
                }
              }
            }
          }
          .gallery-with-description {
            h4 {
              color: $dark-all-font-color;
            }
            a {
              > div {
                border: 1px solid $dark-border-color;
              }
            }
            p {
              color: $dark-small-font-color;
            }
          }
          .jsgrid-grid-header {
            background-color: $dark-card-background;
            border: 1px solid $dark-border-color;
          }
          .jsgrid-header-row,
          .jsgrid-filter-row {
            > .jsgrid-header-cell,
            > .jsgrid-cell {
              background: $dark-card-background;
              border: 1px solid $dark-border-color;
              input {
                background-color: $dark-body-background;
                border-color: $dark-border-color;
                color: $dark-all-font-color;
              }
            }
            select {
              background-color: $dark-body-background;
              border-color: $dark-border-color;
              color: $dark-all-font-color;
            }
          }
          .jsgrid-row {
            > .jsgrid-cell {
              background-color: $dark-card-background;
            }
          }
          .jsgrid-alt-row {
            > .jsgrid-cell {
              background-color: $dark-body-background;
            }
          }
          .jsgrid-cell,
          .jsgrid-grid-body {
            border: 1px solid $dark-border-color;
          }
          .jsgrid-selected-row {
            > .jsgrid-cell {
              background-color: #28303c;
            }
          }
          .jsgrid {
            .jsgrid-pager {
              [class*="jsgrid-pager"] {
                border: 1px solid $dark-border-color;
              }
              .jsgrid-pager-page {
                a {
                  color: $dark-all-font-color;
                }
              }
            }
          }
          .jsgrid-pager-current-page {
            color: $dark-all-font-color;
            font-weight: 700;
          }
          .sub-title {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          }
          .navs-icon {
            li {
              a {
                color: $dark-all-font-color;
                &:hover {
                  color: $primary-color;
                }
              }
            }
            .separator {
              border: 1px solid $dark-border-color;
            }
          }
          .default-according {
            li {
              .text-muted {
                color: $dark-all-font-color !important;
              }
            }
          }
          .navs-icon.default-according.style-1 {
            li {
              button {
                &:hover {
                  color: $primary-color !important;
                }
              }
            }
          }
          .nav-list {
            .nav-list-disc {
              li {
                a {
                  color: $dark-all-font-color;
                }
                &:hover {
                  color: $primary-color;
                  a {
                    color: $primary-color;
                  }
                }
              }
            }
          }
          .navs-dropdown {
            .onhover-show-div {
              background-color: $dark-body-background;
              box-shadow: 0 0 2px 2px $dark-border-color;
              .navs-icon {
                li {
                  a {
                    svg {
                      path,
                      line,
                      polyline,
                      polygon,
                      rect {
                        color: $dark-all-font-color !important;
                      }
                    }
                    &:hover {
                      svg {
                        path,
                        line,
                        polyline,
                        polygon,
                        rect {
                          color: $primary-color !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .onhover-dropdown {
            &:hover {
              .onhover-show-div {
                &:before {
                  border-bottom: 7px solid $dark-body-background;
                }
              }
            }
          }
          .default-according {
            .card {
              box-shadow: 0px 0px 1px 1px rgb(55, 66, 82);
              .btn-link {
                color: $dark-all-font-color;
              }
              .card-body {
                color: $dark-small-font-color;
              }
            }
          }
          .border {
            border: 1px solid $dark-border-color !important;
          }
          .blog-box {
            .blog-date {
              color: $dark-all-font-color;
            }
            .blog-details,
            .blog-details-main {
              .blog-social {
                li {
                  color: $dark-small-font-color;
                  border-right: 1px solid $dark-border-color;
                  &:first-child {
                    border-right: 1px solid $dark-border-color;
                  }
                  &:last-child {
                    border-right: none;
                  }
                }
              }
              p {
                color: $dark-all-font-color;
              }
              .single-blog-content-top {
                border-top: 1px solid $dark-border-color;
                p {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .comment-box {
            .media {
              h6 {
                span {
                  color: $dark-small-font-color;
                }
              }
              img {
                border: 1px solid $dark-border-color;
              }
            }
            p {
              color: $dark-small-font-color;
            }
            .comment-social {
              li {
                color: $dark-small-font-color;
                &:first-child {
                  border-right: 1px solid $dark-border-color;
                }
              }
            }
            hr {
              border-top: 1px solid $dark-border-color;
            }
          }
          .table-hover {
            tbody {
              tr {
                &:hover {
                  background-color: $dark-body-background;
                  td,
                  th {
                    color: $white;
                  }
                }
              }
            }
          }
          ul.the-icons {
            li {
              border: 1px dotted $dark-border-color;
              color: $sidebar-submenu-font-color;
              &:hover {
                background: $black;
                box-shadow: 0 0 3px $dark-card-background;
              }
            }
          }
          .button-builder-wrap {
            .box {
              background-color: $dark-card-background;
              border: 1px solid $dark-border-color;
            }
            .button-preview {
              h2 {
                color: $dark-all-font-color;
              }
            }
            pre.well {
              background-color: $dark-card-background !important;
            }
          }
          .crm-activity {
            > li {
              + li {
                border-top: 1px solid $dark-border-color;
              }
              h6 {
                color: $dark-all-font-color;
              }
            }
            ul.dates {
              li {
                color: $dark-small-font-color;
              }
            }
          }
          #donut-color-chart-morris,
          #donut-color-chart-morris-daily {
            svg {
              text {
                fill: $dark-all-font-color;
              }
            }
          }
          .custom-card {
            .card-profile {
              img {
                background-color: $dark-card-background;
              }
            }
            .profile-details {
              h6 {
                color: $dark-small-font-color;
              }
            }
            .card-social {
              li {
                a {
                  color: $dark-small-font-color;
                }
              }
            }
            .card-footer {
              > div {
                h6 {
                  color: $dark-small-font-color;
                }
                + div {
                  border-left: 1px solid $dark-border-color;
                }
              }
            }
          }
          .form-control {
            background-color: $dark-body-background;
            color: $dark-all-font-color;
            border: 1px solid $dark-border-color;
          }
          .checkbox,
          .radio {
            label {
              &::before {
                background-color: $dark-body-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
            }
          }
          .dropdown-basic {
            .dropdown {
              .dropdown-content {
                background-color: $dark-body-background;
                a {
                  color: $dark-all-font-color;
                  &:hover {
                    background-color: $dark-card-background;
                  }
                }
                .dropdown-divider {
                  border: 1px solid $dark-border-color;
                }
              }
            }
          }
          .dropup-basic {
            .dropup {
              .dropup-content {
                background-color: $dark-body-background;
                a {
                  color: $dark-all-font-color;
                  &:hover,
                  &.active {
                    background-color: $dark-card-background;
                  }
                }
              }
            }
          }
          .dropzone.dz-clickable {
            .dz-message {
              h6 {
                color: $dark-all-font-color;
              }
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .dropzone {
            .dz-preview {
              background-color: $dark-body-background;
              .dz-details {
                background-color: $dark-card-background;
              }
            }
          }
          .browser-widget {
            .media-body {
              column-rule: 1px solid $dark-border-color;
              h4 {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .email-wrap {
            .email-app-sidebar {
              .media {
                img {
                  border: 2px solid $dark-body-background;
                }
              }
              .main-menu {
                > li {
                  a {
                    color: $dark-all-font-color;
                    &:hover {
                      background-color: $dark-body-background;
                    }
                  }
                }
              }
            }
            .email-right-aside {
              .email-body {
                .inbox {
                  .media.active {
                    background-color: $dark-body-background;
                  }
                }
              }
            }
            .media-body {
              h6 {
                color: $dark-all-font-color;
                small {
                  color: $dark-small-font-color;
                  span {
                    color: $dark-small-font-color;
                  }
                }
              }
              p {
                color: $dark-small-font-color;
              }
            }
            .email-top {
              border-bottom: 1px solid $dark-border-color;
            }
            p {
              color: $dark-small-font-color;
            }
            .email-content {
              .email-top {
                .user-emailid:after {
                  border: 1px solid $dark-border-color;
                }
              }
            }
          }
          .dropdown-menu {
            background-color: $dark-body-background;
            border: 1px solid $dark-border-color;
            a {
              color: $dark-all-font-color;
              &:hover {
                background-color: $dark-card-background;
              }
            }
          }
          .icon-lists {
            div {
              i {
                color: $dark-small-font-color;
              }
              &:hover {
                background-color: $dark-body-background;
              }
            }
          }
          .widget-joins {
            .media {
              .media-body {
                > span {
                  color: $dark-small-font-color;
                }
              }
              .details {
                border-left: 1px solid $dark-border-color;
              }
            }
            &:before,
            &:after {
              background-color: $dark-border-color;
            }
          }
          .redial-social-widget {
            i {
              background-color: $dark-card-background;
            }
          }
          .social-widget-card {
            h5,
            h4 {
              color: $dark-all-font-color;
            }
            span {
              color: $dark-small-font-color;
            }
          }
          .b-b-light {
            border-bottom: 1px solid $dark-border-color !important;
          }
          .b-r-dark {
            border-right: 1px solid $white !important;
          }
          .testimonial {
            i {
              color: $dark-border-color;
            }
            p {
              color: $dark-all-font-color;
            }
            h5 {
              color: $dark-all-font-color;
            }
            span {
              color: $dark-small-font-color;
            }
          }
          .grid-showcase {
            span {
              background-color: $dark-card-background;
              border: 1px solid $dark-border-color;
            }
          }
          .grid-align {
            .row {
              background-color: $dark-border-color;
              border: 1px solid $dark-border-color;
            }
          }
          .border-style {
            .card {
              border: 1px solid $dark-border-color;
            }
          }
          .offer-style {
            .card {
              border: 1px dashed $dark-border-color;
            }
          }
          .page-builder {
            .btn-grid {
              background-color: $dark-card-background;
              color: $dark-small-font-color !important;
              border-right: 1px solid $dark-border-color;
            }
            .ge-mainControls {
              .ge-addRowGroup {
                .ge-row-icon {
                  .column {
                    border-left: 3px solid $dark-card-background;
                  }
                }
              }
            }
            .btn-code,
            .btn-screen {
              color: $dark-small-font-color;
              border-right: 1px solid $dark-border-color;
            }
            .ge-canvas.ge-editing {
              .row {
                background-color: $dark-body-background;
              }
              .column {
                border: 1px solid $dark-border-color;
                background-color: $dark-card-background;
              }
              .ge-tools-drawer {
                .ge-details {
                  input {
                    background-color: $dark-body-background;
                    border-color: $dark-border-color;
                    color: $dark-small-font-color;
                  }
                  .btn-group {
                    a {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
            }
            .ge-content-type-ckeditor {
              color: $dark-all-font-color;
            }
          }
          .search-page {
            .info-block {
              + .info-block {
                border-top: 1px solid $dark-border-color;
              }
            }
          }
          .card-absolute {
            .bg-primary,
            .bg-secondary {
              h5 {
                color: $white;
              }
            }
          }
          .timeline-small {
            .media {
              .timeline-round {
                &.timeline-line-1,
                &.small-line,
                &.medium-line {
                  &:after {
                    background-color: $dark-border-color;
                  }
                }
              }
            }
          }
          /* body end*/
        }
        footer {
          border-top: 1px solid $dark-body-background;
          background-color: $dark-card-background;
        }
        .form-select,
        .custom-file-label {
          background: $dark-body-background;
          color: $dark-all-font-color;
        }
        .footer-fix {
          background-color: $dark-card-background;
          border-top: 1px solid $dark-border-color;
        }
      }
      .dt-button-info {
        background-color: $dark-card-background;
        border: 1px solid $dark-small-font-color;
        h2 {
          background-color: $dark-card-background;
        }
      }
      .chat-box {
        .about {
          .name {
            color: $dark-all-font-color;
          }
        }
        .chat-menu {
          border-left: 1px solid $dark-border-color;
          .nav-tabs {
            border-bottom: 1px solid $dark-border-color;
            .nav-item {
              .nav-link {
                &.active {
                  color: $dark-all-font-color !important;
                }
              }
            }
          }

          .user-profile {
            .image {
              .icon-wrapper {
                background-color: $dark-card-background;
                box-shadow: 1px 1px 3px 1px $dark-body-background;
              }
              .avatar {
                img {
                  border: 5px solid $dark-border-color;
                }
              }
            }
            .social-media {
              a {
                color: $dark-small-font-color;
              }
            }
            .follow {
              span {
                color: $dark-small-font-color;
              }
              .follow-num {
                color: $dark-all-font-color;
              }
            }
          }
        }
        .status {
          color: $dark-small-font-color;
          p {
            color: $dark-all-font-color !important;
          }
        }
        .chat-right-aside {
          .chat {
            .chat-header {
              border-bottom: 1px solid $dark-border-color;
              .chat-menu-icons {
                li {
                  a {
                    i {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
              img {
                box-shadow: 1px 1px 4px 1px $dark-body-background;
              }
            }
            .chat-msg-box {
              .my-message {
                border: 1px solid $dark-border-color;
              }
              .message {
                color: $dark-all-font-color;
              }
              .other-message {
                background-color: $dark-body-background;
              }
            }
            .chat-message {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-border-color;
              .input-group {
                .form-control {
                  background-color: $dark-card-background;
                }
              }
            }
          }
        }
        .chat-history {
          .call-icons {
            ul {
              li {
                border: 1px solid $dark-border-color;
                a {
                  color: $dark-small-font-color;
                }
              }
            }
          }
        }
        .bitcoin-chat {
          .chat {
            .chat-msg-box {
              .message {
                &.my-message {
                  background-color: #1c222b;
                  .chat-user-img {
                    border: 2px solid $dark-body-background;
                  }
                }
                &.other-message {
                  .chat-user-img {
                    border: 2px solid $dark-body-background;
                  }
                }
              }
            }
          }
        }
      }
      pre {
        background-color: $dark-border-color;
        color: $dark-all-font-color;
      }
      .scorlled {
        background-color: $dark-body-background;
      }
      .input-group-air {
        box-shadow: 0 3px 20px 0 $dark-border-color;
      }
      .input-group-solid {
        .input-group-text,
        .form-control {
          background: $dark-body-background;
          border: 1px solid $dark-border-color;
          color: $dark-all-font-color;
        }
      }
      .semilight-bg-color,
      .header-bg-color {
        background-color: $white;
      }
      .list-group-item {
        background-color: $dark-card-background;
        border: 1px solid rgba(255, 255, 255, 0.3);
        color: $dark-all-font-color;
        &.active {
          background-color: $primary-color;
          border-color: $primary-color;
          color: $white;
        }
        &:hover,
        &:focus {
          z-index: 1;
          text-decoration: none;
        }
      }
      .list-group-item-action {
        &:hover:not(.active),
        &:focus {
          background-color: $dark-body-background;
        }
      }
      .list-group-flush {
        .list-group-item {
          border-left: 0;
          border-right: 0;
          border-radius: 0;
        }
      }
      .list-group-item-primary {
        color: #004085;
        background-color: #b8daff;
      }
      .list-group-item-secondary {
        color: #383d41;
        background-color: #d6d8db;
      }
      .list-group-item-success {
        color: #155724;
        background-color: #c3e6cb;
      }
      .list-group-item-danger {
        color: #721c24;
        background-color: #f5c6cb;
      }
      .list-group-item-warning {
        color: #856404;
        background-color: #ffeeba;
      }
      .list-group-item-info {
        color: #0c5460;
        background-color: #bee5eb;
      }
      .list-group-item-light {
        color: #818182;
        background-color: #fdfdfe;
      }
      .list-group-item-dark {
        color: #1b1e21;
        background-color: #c6c8ca;
      }
      .auth-bg {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.9);
        .authentication-box {
          .card {
            background-color: $dark-card-background;
            .theme-form {
              .form-group {
                input {
                  color: $dark-all-font-color;
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                }
                label {
                  color: $dark-all-font-color;
                }
              }
              .checkbox {
                label {
                  color: $dark-all-font-color;
                  &::before {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
          }
          h3,
          h4,
          h6 {
            color: $white;
          }
        }
      }
      .auth-bg-video {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.7);
        .authentication-box {
          h4,
          h3 {
            color: $white;
          }
          h6 {
            color: $dark-small-font-color;
          }
          .card {
            background-color: $dark-card-background;
            .theme-form {
              .form-group {
                input[type="text"],
                input[type="password"] {
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                  color: $dark-all-font-color;
                }
              }
              .checkbox {
                label {
                  &::before {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
          }
        }
      }
      .error-wrapper {
        .maintenance-icons {
          li {
            i {
              color: $dark-border-color;
            }
            &:nth-child(3) {
              i {
                color: $dark-border-color;
              }
            }
          }
        }
      }
      .modal-content {
        background-color: $dark-card-background;
        .modal-header {
          border-bottom: 1px solid $dark-border-color;
          .btn-close {
            color: $dark-small-font-color;
          }
        }
        .modal-footer {
          border-top: 1px solid $dark-border-color;
        }
      }
      .comingsoon {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.9);
        .comingsoon-inner {
          h5 {
            color: $dark-all-font-color;
          }
          .countdown {
            border-top: 1px solid $dark-border-color;
            border-bottom: 1px solid $dark-border-color;
            .title {
              color: $dark-all-font-color;
            }
          }
          .coming-soon-bottom-link {
            a {
              color: $dark-all-font-color;
            }
          }
        }
      }
      .theme-form {
        .login-divider {
          border-top: 1px solid $dark-border-color;
          &:before {
            background: $dark-border-color;
            color: $dark-all-font-color;
          }
        }
      }
      .authentication-main {
        background-color: $dark-border-color;
        .auth-innerright {
          .reset-password-box {
            .card {
              background-color: $dark-card-background;
            }
            .reset-password-link {
              color: $dark-small-font-color;
            }
            .theme-form {
              .form-group {
                label {
                  color: $dark-small-font-color;
                }
                .form-control {
                  background-color: $dark-body-background;
                  color: $dark-all-font-color;
                  border-color: $dark-border-color;
                }
              }
              .opt-box {
                background-color: $dark-border-color;
              }
            }
          }
          .authentication-box {
            h4 {
              color: $dark-all-font-color;
            }
            h6 {
              color: $dark-small-font-color;
            }
            h3 {
              color: $dark-all-font-color;
            }
            .card {
              background-color: $dark-card-background;
              .theme-form {
                .form-group {
                  input[type="text"],
                  input[type="password"] {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-border-color;
                    color: $dark-all-font-color;
                  }
                }
                .checkbox {
                  label {
                    &::before {
                      background-color: $dark-body-background;
                      border: 1px solid $dark-border-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .vertical-menu-main {
        background-color: $dark-card-background;
      }
      .pixelstrap {
        a {
          color: $dark-all-font-color;
          &:focus,
          &:active,
          &:hover {
            color: $dark-all-font-color;
          }
        }
        > {
          li {
            > a.highlighted {
              &:before {
                background-color: $dark-card-background;
              }
              &:after {
                background-color: $dark-border-color;
              }
            }
          }
        }
      }
      @media (min-width: 1200px) {
        .pixelstrap {
          ul {
            background: $dark-body-background;
            border: 1px solid $dark-border-color;
            a {
              &:hover,
              &:focus,
              &:active,
              &.highlighted {
                color: $primary-color;
              }
              &.current {
                background-color: $dark-body-background;
                color: $primary-color;
              }
            }
          }
        }
      }
      .mega-menu {
        .title {
          color: $dark-all-font-color;
          border-bottom: 1px solid $dark-border-color;
        }
        .galleria-list {
          .galleria {
            > div {
              .username {
                color: $dark-all-font-color;
                small {
                  color: $dark-small-font-color;
                }
              }
            }
          }
        }
        .list-unstyled {
          div {
            a {
              &:hover {
                color: $primary-color;
              }
            }
          }
        }
      }
      .default-according {
        .card {
          background-color: $dark-card-background;
          .btn-link {
            background-color: $dark-card-background;
            border: 1px solid $dark-card-background;
            color: $white;
          }
          .text-muted {
            color: $dark-small-font-color !important;
          }
        }
        .bg-primary {
          .btn-link {
            background-color: $primary-color;
            border: 1px solid $primary-color;
          }
        }
        .bg-secondary {
          .btn-link {
            background-color: $secondary-color;
            border: 1px solid $secondary-color;
          }
        }
      }
      .collapse {
        .card-body {
          background-color: $dark-card-background;
        }
      }
      @media screen and (max-width: 1660px) {
        .chat-box {
          .chat-history {
            .call-icons {
              ul {
                li {
                  border: 1px solid $dark-border-color;
                }
              }
            }
            .total-time {
              h2 {
                color: $dark-all-font-color;
              }
            }
          }
        }
        .caller-img {
          img {
            opacity: 0.7;
          }
        }
        .chat-box {
          .chat-history {
            .call-content {
              > div {
                background-color: rgba(0, 0, 0, 0.75);
                background-blend-mode: overlay;
              }
            }
          }
        }
      }
      @media only screen and (max-width: 1199px) {
        .sm {
          background-color: $dark-card-background;
          border: 1px solid $dark-border-color;
        }
        .pixelstrap {
          .mobile-back {
            border-bottom: 1px solid $dark-border-color;
          }
          ul {
            li {
              border-top: 1px solid $dark-border-color;
            }
            a {
              background: $dark-card-background;
              &:hover,
              &:focus,
              &:active {
                background: $dark-card-background;
              }
            }
          }
        }
        .chat-menu {
          border-top: 1px solid $dark-border-color;
          background-color: $dark-card-background;
        }
        .error-wrapper {
          .maintenance-icons {
            li {
              i {
                color: $dark-color;
              }
            }
          }
        }
      }
      .pixelstrap {
        ul {
          background: $dark-card-background;
        }
      }
      @media only screen and (max-width: 575px) {
        .user-profile {
          .hovercard {
            .info {
              .user-designation {
                border-top: 1px solid $dark-border-color;
                border-bottom: 1px solid $dark-border-color;
              }
            }
          }
        }
        .widget-joins {
          .border-after-xs {
            &:after {
              background-color: $dark-border-color;
            }
          }
        }
      }
    }
    .lg-backdrop {
      background-color: $dark-body-background;
    }
    .lg-outer {
      .lg-thumb-outer {
        background-color: $dark-card-background;
      }
    }
    .drag {
      background-color: $dark-card-background;
      form {
        background: $dark-card-background;
        box-shadow: 0 0 30px $dark-border-color;
        border: 1px dotted $dark-border-color;
        input {
          background-color: $dark-body-background;
          border: 1px solid $dark-border-color;
          color: $dark-all-font-color;
        }
        .help-block {
          text-transform: capitalize;
          color: $dark-small-font-color;
        }
      }
    }
    .draggable {
      input {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
        border: 1px solid $dark-border-color;
      }
      p {
        color: $dark-small-font-color;
      }
      select {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
        border: 1px solid $dark-border-color;
      }
      .radio,
      .checkbox {
        label {
          &::before {
            background-color: $dark-body-background;
            border: 1px solid $dark-border-color;
          }
        }
      }
    }
    .select2-container--default {
      .select2-selection--multiple,
      .select2-selection--single {
        background-color: $dark-body-background;
        border: 1px solid $dark-border-color !important;
      }
      .select2-search--inline {
        .select2-search__field {
          color: $dark-all-font-color;
        }
      }
      .select2-selection--single {
        .select2-selection__rendered {
          color: $dark-all-font-color;
        }
      }
      .select2-search--dropdown {
        .select2-search__field {
          background-color: $dark-body-background;
          border: 1px solid $dark-border-color;
          color: $dark-all-font-color;
        }
      }
    }
    .select2-dropdown {
      background-color: $dark-body-background;
      border: 1px solid $dark-border-color;
    }
    .select2-drpdwn {
      .form-control-primary {
        border-color: $primary-color !important;
        color: $primary-color !important;
      }
      .form-control-secondary {
        border-color: $secondary-color !important;
        color: $secondary-color !important;
      }
      .form-control-success {
        border-color: $success-color !important;
        color: $success-color !important;
      }
      .form-control-info {
        border-color: $info-color !important;
        color: $info-color !important;
      }
      .form-control-warning {
        border-color: $warning-color !important;
        color: $warning-color !important;
      }
      .form-control-danger {
        border-color: $danger-color !important;
        color: $danger-color !important;
      }
      .form-control-inverse {
        border-color: $white !important;
        color: $white !important;
      }
      .form-control-primary-fill {
        background-color: $primary-color !important;
        color: $white !important;
      }
      .form-control-secondary-fill {
        background-color: $secondary-color !important;
        color: $white !important;
      }
      .form-control-success-fill {
        background-color: $success-color !important;
        color: $white !important;
      }
      .form-control-info-fill {
        background-color: $info-color !important;
        color: $white !important;
      }
      .form-control-warning-fill {
        background-color: $warning-color !important;
        color: $white !important;
      }
      .form-control-danger-fill {
        background-color: $danger-color !important;
        color: $white !important;
      }
      .form-control-inverse-fill {
        background-color: $white !important;
        color: $black !important;
      }
    }
    .select2-container--default.select2-container--disabled {
      .select2-selection--single {
        background-color: $dark-border-color;
      }
    }
    .theme-form {
      .form-group {
        select.form-control:not([size]):not([multiple]) {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          border-color: $dark-border-color;
        }
        input[type="text"],
        input[type="email"],
        input[type="search"],
        input[type="password"],
        input[type="number"],
        input[type="tel"],
        input[type="date"],
        input[type="datetime-local"],
        input[type="time"],
        input[type="datetime-local"],
        input[type="month"],
        input[type="week"],
        input[type="url"],
        input[type="file"],
        select {
          border-color: $dark-border-color;
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          &::-webkit-input-placeholder {
            color: $dark-small-font-color;
          }
        }
        .form-control {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
        }
        textarea {
          border-color: $dark-border-color;
        }
      }
      .form-divider {
        border-top: 1px solid $dark-border-color;
        &::before {
          background: $dark-card-background;
          color: $dark-all-font-color;
        }
      }
    }
    .CodeMirror {
      background: $dark-card-background;
      border: 1px solid $dark-small-font-color;
      color: $dark-all-font-color;
      .CodeMirror-code {
        pre {
          background: $dark-card-background;
        }
      }
    }
    .editor-toolbar {
      border-top: 1px solid $dark-small-font-color;
      border-left: 1px solid $dark-small-font-color;
      border-right: 1px solid $dark-small-font-color;
      a {
        color: $dark-all-font-color !important;
        &:hover,
        &.active {
          background: $dark-border-color;
        }
      }
      i.separator {
        border-left: 1px solid $dark-small-font-color;
        border-right: 1px solid $dark-small-font-color;
      }
    }
    .editor-toolbar.disabled-for-preview {
      a {
        &:not(.no-disable) {
          background: $dark-border-color;
        }
      }
    }
    .editor-preview {
      background-color: $dark-card-background;
    }
    .editor-toolbar.fullscreen,
    .editor-preview-side {
      background: $dark-card-background;
      border: 1px solid $dark-small-font-color;
    }
    .u-step {
      background: $dark-border-color;
      &.active,
      &.current {
        background: $primary-color;
        color: $white;
      }
    }
    .u-step-title,
    .u-pearl-title {
      color: $dark-all-font-color;
    }
    .u-step-number {
      background-color: $dark-card-background;
    }
    .u-pearl {
      &:before {
        background-color: $dark-border-color;
      }
    }
    .u-pearl-number,
    .u-pearl-icon {
      background: $dark-body-background;
      border: 2px solid $dark-body-background;
    }
    .u-pearl.disabled {
      .u-pearl-icon,
      .u-pearl-number {
        background: $dark-border-color;
        border: 2px solid $dark-border-color;
      }
      &:after {
        background-color: #334053;
      }
    }
    .u-pearl.error {
      &:after {
        background-color: $dark-body-background;
      }
    }
    .note-editor.note-frame {
      border-color: $dark-border-color;
      .note-editing-area {
        .note-editable {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
        }
      }
    }
    .swal-modal {
      background-color: $dark-card-background;
      .swal-title {
        color: $dark-all-font-color;
      }
      .swal-text {
        color: $dark-small-font-color;
      }
      .swal-content__input {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
      }
    }
    .nav-tabs {
      border-bottom: 1px solid $dark-border-color;
      .nav-link {
        &.active {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border-color: $dark-border-color $dark-border-color
            $dark-card-background;
        }
        &:hover,
        &:focus {
          border-color: $dark-border-color $dark-border-color $dark-border-color;
        }
      }
      .nav-item.show {
        .nav-link {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border-color: $dark-border-color $dark-border-color
            $dark-card-background;
        }
      }
    }
    .nav-tabs.nav-bottom {
      .nav-item {
        .nav-link {
          &.active,
          &.focus,
          &.hover {
            border-color: $dark-card-background $dark-border-color
              $dark-border-color;
          }
        }
      }
    }
    .nav-material.nav-left,
    .nav-material.nav-right {
      .nav-link {
        color: $dark-all-font-color;
        &.active {
          color: $primary-color;
        }
      }
      .show {
        > .nav-link {
          color: $primary-color;
        }
      }
    }
    .nav-material.nav-left.nav-secondary {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          border-left-color: $secondary-color;
          color: $secondary-color !important;
        }
      }
      .show > .nav-link {
        border-left-color: $secondary-color;
        color: $secondary-color !important;
      }
      .nav-item.show {
        color: $secondary-color !important;
        border-left-color: $secondary-color;
      }
    }
    .nav-material.nav-left.nav-success {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $success-color !important;
        }
      }
      .show > .nav-link {
        border-left-color: $success-color;
        color: $success-color !important;
      }
      .nav-item.show {
        color: $success-color !important;
        border-left-color: $success-color;
      }
    }
    .nav-material.nav-right.nav-info {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $info-color !important;
        }
      }
      .show > .nav-link {
        border-left-color: $info-color;
        color: $info-color !important;
      }
      .nav-item.show {
        color: $info-color !important;
        border-left-color: $info-color;
      }
    }
    .nav-material.nav-secondary {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $secondary-color !important;
        }
      }
      .nav-item {
        &.show {
          color: $secondary-color !important;
        }
      }
    }
    .nav-material.nav-success {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $success-color !important;
        }
      }
      .nav-item {
        &.show {
          color: $success-color !important;
        }
      }
    }
    .dropdown-divider {
      border-top: 1px solid $dark-border-color;
    }
    .icon-hover-bottom {
      background-color: $dark-card-background;
      box-shadow: 0px 0px 1px 1px $dark-border-color;
      .icon-title {
        color: $dark-all-font-color;
      }
      span {
        color: $dark-small-font-color;
      }
      .form-group {
        input {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          border: 1px solid $dark-border-color;
        }
      }
      .icon-first {
        i {
          color: $dark-small-font-color;
        }
      }
    }
    code {
      background-color: #d8ecff;
      border-radius: 2px;
    }
    #cd-timeline {
      &::before {
        background: $dark-border-color;
      }
    }
    .timeliny {
      border-top: 1px solid $dark-border-color;
      border-bottom: 1px solid $dark-border-color;
      &::before {
        background-color: $white;
      }
      .timeliny-dot {
        background-color: $dark-card-background;
        border: 1px solid $white;
        &::before {
          color: $dark-all-font-color;
        }
      }
      .timeliny-timeline {
        .timeliny-timeblock {
          &.active {
            .timeliny-dot {
              &::before {
                color: $dark-all-font-color;
              }
            }
          }
          &:not(.inactive):not(.active) {
            .timeliny-dot {
              &:hover {
                background-color: $white;
                &::before {
                  color: $dark-all-font-color;
                }
              }
            }
          }
          .timeliny-dot {
            &:hover {
              &::after {
                background-color: $dark-card-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
    }
    .todo {
      .todo-list-wrapper {
        #todo-list {
          li {
            .task-container {
              background: $dark-card-background;
              border-bottom: 1px solid $dark-border-color;
              .task-label {
                color: $dark-all-font-color;
              }
              &:hover {
                h4 {
                  color: $white;
                }
              }
              .task-action-btn {
                .action-box {
                  &:hover:not(.active) {
                    background: $dark-card-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
            &.completed {
              .task-container {
                .task-label {
                  color: $primary-color;
                }
                .complete-btn {
                  &:hover {
                    border: 1px solid $success-color !important;
                  }
                }
              }
            }
          }
        }
        .todo-list-footer {
          .new-task-wrapper {
            textarea {
              background-color: $dark-card-background;
              border: 1px solid $dark-border-color;
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    .user-profile {
      .ttl-info {
        h6 {
          color: $dark-small-font-color;
        }
        span {
          color: $dark-all-font-color;
        }
      }
      .hovercard {
        .info {
          .title {
            a {
              color: $dark-all-font-color;
            }
          }
        }
        .user-image {
          .avatar {
            img {
              border: 10px solid $dark-card-background;
            }
          }
          .icon-wrapper {
            background-color: $dark-card-background;
          }
        }
        .tabs-scoial {
          border-bottom: none !important;
        }
      }
      .follow {
        .follow-num {
          color: $dark-all-font-color;
        }
      }
      .profile-img-style {
        .user-name {
          color: $dark-all-font-color;
        }
        p {
          color: $dark-small-font-color;
        }
      }
    }
    .introjs-helperLayer {
      background-color: rgba(54, 64, 74, 0.9);
      border: rgba(255, 255, 255, 0.5);
    }
    .jstree-default {
      .jstree-node,
      .jstree-icon {
        color: #455869 !important;
      }
      .jstree-anchor {
        color: $dark-small-font-color;
      }
      .jstree-clicked {
        color: $white;
        background-color: transparent;
      }
      .jstree-hovered {
        background-color: $dark-body-background;
        border: 1px solid $dark-border-color;
      }
    }
    span.twitter-typeahead {
      .tt-menu {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
      }
      .tt-suggestion {
        color: $dark-all-font-color;
        &:hover,
        &:focus {
          background-color: $dark-card-background;
        }
      }
    }
    .typography {
      small {
        color: $dark-all-font-color;
      }
    }
    .blockquote-footer {
      color: $dark-small-font-color;
    }
    .code-box-copy {
      pre {
        background-color: $dark-body-background;
        code {
          background-color: $dark-body-background;
        }
      }
      pre[class*="language-"] {
        border: 1px solid $dark-border-color;
      }
      .code-box-copy__btn {
        background-color: $dark-body-background;
        border: 1px solid $dark-border-color;
        color: $white;
      }
      code[class*="language-"],
      pre[class*="language-"] {
        text-shadow: 0px 1px $black;
        ::selection {
          text-shadow: none;
          background: $dark-card-background;
        }
      }
    }
    table.fixedHeader-floating {
      background-color: $dark-body-background;
    }
    .dt-button-info {
      background-color: $dark-card-background;
      border: 1px solid $dark-border-color;
      h2 {
        background-color: $dark-card-background;
        border-bottom: 1px solid $dark-border-color;
      }
    }
    #keytable_wrapper,
    #column-selector_wrapper,
    #auto-fill_wrapper,
    #custom-button_wrapper,
    #class-button_wrapper,
    #keyboard-btn_wrapper,
    #multilevel-btn_wrapper,
    #pagelength-btn_wrapper,
    #basic-colreorder_wrapper,
    #state-saving_wrapper,
    #real-time_wrapper,
    #basic-fixed-header_wrapper,
    #fixed-header-footer_wrapper,
    #export-button_wrapper,
    #excel-cust-bolder_wrapper,
    #cust-json_wrapper,
    #basic-key-table_wrapper,
    #focus-cell_wrapper,
    #responsive_wrapper,
    #new-cons_wrapper,
    #show-hidden-row_wrapper,
    #basic-row-reorder_wrapper,
    #full-row_wrapper,
    #rest-column_wrapper {
      .dataTables_paginate {
        border: none;
      }
    }
    #example-style-8_wrapper {
      table.dataTable.stripe,
      table.dataTable.display {
        tbody {
          tr.odd {
            background-color: $dark-border-color;
          }
        }
      }
    }
    .error-wrapper {
      .sub-content {
        color: $dark-all-font-color;
      }
    }
    .b-light {
      border: 1px solid $dark-border-color !important;
    }
    .modal-content {
      background-color: $dark-card-background;
      .modal-header {
        border-bottom: 1px solid $dark-border-color;
      }
      .ui-front {
        .form-control {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border: 1px solid $dark-border-color;
        }
      }
    }
    .stepwizard {
      .stepwizard-row {
        &:before {
          background-color: $dark-small-font-color;
        }
      }
    }
    .modal {
      .theme-close {
        background-color: $dark-card-background !important;
      }
    }
    .token.atrule,
    .token.attr-value,
    .token.keyword {
      color: $info-color;
    }
    .token {
      &.boolean,
      &.constant,
      &.deleted,
      &.number,
      &.property,
      &.symbol,
      &.tag {
        color: $danger-color;
      }
    }
    .loader-box {
      &.card-loader {
        background-color: $dark-card-background;
      }
    }
    .my-gallery {
      &.gallery-with-description {
        img {
          border: 1px solid $dark-border-color !important;
          border-bottom: none !important;
        }
      }
    }
    @each $var in $alert-name {
      $i: index($alert-name, $var);
      .alert-#{$var}.inverse {
        color: $dark-all-font-color;
      }
    }
    .alert-theme {
      span {
        + span {
          + span {
            border: 1px solid $primary-color;
            background-color: $dark-body-background;
            border-radius: 4px;
          }
        }
      }
      i {
        background-color: $primary-color;
        color: $white;
        padding: 20px;
        margin-right: 20px !important;
      }
    }
    .user-card {
      .user-deatils {
        h6 {
          color: $dark-small-font-color;
        }
      }
      .card-footer {
        > div {
          &:first-child {
            border-right: 1px solid $dark-border-color;
          }
        }
        .user-footer {
          h6 {
            color: $dark-small-font-color;
          }
          svg {
            path,
            rect {
              color: $dark-small-font-color;
            }
          }
        }
      }
    }
    .dashboard-chat {
      .chat {
        .media {
          .media-body {
            .message-main {
              p {
                background-color: $dark-body-background;
              }
              &.smiley-bg {
                background-color: $dark-body-background;
              }
            }
          }
        }
        .right-side-chat {
          .media-body {
            p {
              &:before {
                border-left: 7px solid $dark-body-background;
              }
            }
          }
        }
        .left-side-chat {
          .media-body {
            p {
              &:before {
                border-right: 7px solid $dark-body-background;
              }
            }
          }
        }
      }
    }
    .selling-update {
      border: 2px solid $dark-border-color;
    }
    .bitcoin-accordion {
      .card {
        .media-accordion {
          .media:nth-child(2) {
            background-color: #1c222b;
          }
          .media {
            .media-body {
              p {
                color: $dark-small-font-color;
              }
            }
            + .media {
              border-top: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    .invest-dropdown {
      .right-header-dropdown {
        &.onhover-show-div {
          background-color: $dark-body-background;
          box-shadow: 0 0 2px 2px $dark-body-background;
          a {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .server-card-bg {
      background-color: #181f27 !important;
    }
    .server-widgets {
      .bottom-server {
        h5 {
          .second-color {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .select2-drpdwn-project {
      .form-control {
        background-color: $dark-card-background !important;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
      }
    }
    .tabs-project {
      .nav-item {
        .nav-link {
          color: $dark-all-font-color;
        }
      }
    }
    .current-progress,
    .order-history {
      table {
        tr {
          td,
          th {
            border-top: none !important;
          }
        }
      }
      .title-orders {
        background-color: $dark-body-background;
      }
    }
    .navigation-option {
      ul {
        li {
          a {
            color: $dark-all-font-color;
          }
          &:hover {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .product-box {
      .product-details {
        h6 {
          color: $dark-all-font-color;
        }
        span {
          color: $dark-small-font-color;
        }
      }
      .product-img {
        .product-hover {
          ul {
            li {
              background-color: $dark-card-background;
              &:hover {
                background-color: $black;
                color: $white;
              }
            }
          }
        }
      }
    }
    .browse {
      .browse-articles {
        ul {
          li {
            a {
              color: $dark-all-font-color;
            }
            &:hover {
              background-color: $dark-body-background;
            }
          }
        }
      }
    }
    @each $btn-name, $btn-color in (primary, $primary-color),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .radio-#{$btn-name} {
        input[type="radio"] {
          & + label {
            &::before {
              border-color: $btn-color !important;
            }
            &::after {
              background-color: $btn-color;
            }
          }
          &:checked {
            & + label {
              &::before {
                border-color: $btn-color !important;
              }
              &::after {
                background-color: $btn-color;
              }
            }
          }
        }
      }
    }

    @each $btn-name, $btn-color in (primary, $primary-color),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .checkbox-#{$btn-name} {
        label {
          &::before {
            border-color: $btn-color !important;
          }
        }
      }
    }

    @each $btn-name, $btn-color in (primary, $primary-color),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .checkbox-solid-#{$btn-name} {
        label {
          &:before {
            background-color: $btn-color !important;
            border-color: $btn-color !important;
            color: $white !important;
          }
        }
        input[type="checkbox"] {
          &:checked {
            & + label {
              &::before {
                background-color: $btn-color !important;
                border-color: $btn-color !important;
                color: $white !important;
              }
              &::after {
                color: $white !important;
              }
            }
          }
        }
      }
    }
    .bootstrap-datetimepicker-widget {
      ul {
        li {
          color: $dark-all-font-color;
          border-bottom: 1px solid $dark-border-color;
        }
      }
      tr {
        th,
        td {
          &:hover {
            background-color: $dark-card-background;
          }
          span {
            &:hover {
              background-color: $dark-card-background;
            }
          }
          &.prev,
          &.next {
            &:hover {
              background-color: $dark-card-background;
            }
          }
        }
        &:first-child {
          th {
            &:hover {
              background-color: $dark-card-background;
            }
          }
        }
      }
    }
    .btn-transparent {
      color: $dark-all-font-color;
    }
    #cal-basic {
      .fc-toolbar {
        .fc-left,
        .fc-right {
          .fc-next-button {
            .fc-icon-right-single-arrow {
              &:after {
                color: $dark-small-font-color;
              }
            }
          }
          .fc-prev-button {
            .fc-icon-left-single-arrow {
              &:after {
                color: $dark-small-font-color;
              }
            }
          }
        }
        .fc-left {
          .fc-button-group {
            .fc-month-button {
              color: $white !important;
            }
          }
        }
      }
    }
    .fc-button-group {
      .fc-basicWeek-button,
      .fc-basicDay-button,
      .fc-agendaWeek-button,
      .fc-agendaDay-button {
        color: $black !important;
      }
      .fc-month-button {
        color: $white !important;
      }
    }
    #cal-agenda-view {
      .fc-toolbar {
        .fc-right {
          .fc-button-group {
            .fc-month-button,
            .btn-light {
              color: $black !important;
            }
            .fc-agendaWeek-button {
              color: $white !important;
              &:hover {
                color: $black !important;
              }
            }
          }
        }
      }
    }
    .basic-calendar,
    #cal-bg-events,
    #cal-event-colors {
      .fc-toolbar {
        .fc-right {
          .fc-button-group {
            .fc-basicWeek-button,
            .btn-light {
              color: $black !important;
            }
            .fc-month-button {
              color: $white !important;
              &:hover {
                color: $black !important;
              }
            }
          }
        }
      }
    }
    .categories {
      ul {
        li {
          a {
            color: $dark-small-font-color;
          }
          &:hover {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .main {
      .langChoice {
        background: $dark-body-background;
        color: $white;
        border: 1px solid $dark-border-color;
      }
    }
    .socialprofile {
      .likes-profile {
        h5 {
          span {
            color: $dark-all-font-color;
          }
        }
      }
      span {
        color: $dark-small-font-color;
      }
    }
    .social-status {
      .media {
        .media-body {
          span {
            + span {
              color: $dark-small-font-color;
            }
          }
          p,
          .light-span {
            color: $dark-small-font-color;
          }
        }
      }
      form {
        .form-group {
          .form-control-plaintext {
            border: 1px solid $dark-border-color;
          }
        }
      }
    }
    .filter-cards-view,
    .timeline-content {
      p {
        color: $dark-small-font-color;
      }
      .comment-number {
        i {
          color: $dark-small-font-color;
        }
      }
      .comments-box {
        .input-group {
          .form-control {
            border: none !important;
          }
          .input-group-append {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .social-chat {
      .media-body {
        border: 1px solid $dark-border-color;
        &:after {
          border-right: 7px solid $dark-card-background;
        }
      }
      span {
        span {
          color: $dark-small-font-color;
        }
      }
    }
    .details-about {
      .your-details {
        p {
          color: $dark-small-font-color;
        }
      }
    }
    .activity-log {
      .my-activity {
        p {
          color: $dark-small-font-color;
        }
      }
    }
    &.rtl {
      .page-main-header {
        .main-header-right {
          .nav-right {
            > ul {
              > li {
                border-right: none;
                &:nth-child(2) {
                  border-right: 1px solid $dark-border-color;
                }
                &:nth-child(5) {
                  border-right: none;
                  border-left: 1px solid $dark-border-color;
                }
                &:nth-child(4) {
                  border-left: none;
                }
              }
            }
          }
        }
      }
    }
    .bookmark {
      ul {
        li {
          a {
            color: $dark-all-font-color;
          }
        }
      }
    }
  }
  &.dark-body-only {
    .custom-datatable {
      table {
        border: 1px solid $dark-border-color;
      }
    }
    .accordion {
      > .card {
        .card-header {
          .btn-link {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .config-popup {
      border-bottom: none !important;
      color: $dark-all-font-color;
    }
    .modal-copy-header {
      h5 {
        color: $dark-all-font-color;
      }
      .btn-close {
        color: $dark-all-font-color;
      }
    }
    .browse {
      .browse-articles {
        h6 {
          border-bottom: 1px solid $dark-border-color;
        }
      }
    }
    .search-knowledge {
      input {
        background-color: $dark-card-background;
        border: 1px solid $dark-border-color;
        color: $dark-small-font-color;
      }
    }
    .btn-outline-light {
      color: $dark-all-font-color;
    }
    .product-wrapper {
      &.sidebaron {
        .product-sidebar {
          .filter-section {
            .card {
              .left-filter {
                background-color: $dark-card-background;
              }
            }
          }
        }
      }
    }
    .search-page {
      .nav-tabs {
        border-bottom: none !important;
        ngb-tabset {
          .nav-tabs {
            border-bottom: 1px solid $dark-border-color !important;
          }
        }
      }
      ul {
        &.search-info {
          li {
            color: inherit;
            + li {
              border-left: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    #project {
      .nav-tabs {
        .nav-link {
          color: $dark-all-font-color;
          &.active {
            color: $primary-color;
          }
        }
      }
    }
    .cal-month-view {
      background-color: $dark-card-background;
      .cal-cell-row {
        &:hover {
          background-color: $dark-body-background;
        }
        .cal-cell:hover,
        .cal-cell.cal-has-events.cal-open {
          background-color: $dark-border-color;
        }
      }
      .cal-days {
        border-color: $dark-border-color;
        .cal-cell-row {
          border-bottom-color: $dark-border-color;
        }
      }
      .cal-day-cell {
        &:not(:last-child) {
          border-right-color: $dark-border-color;
        }
      }
    }
    .contact-profile {
      .icon-wrapper {
        background-color: $dark-card-background;
      }
    }
    .my-app-custom-plain-container-with-desc {
      figure {
        img,
        .caption {
          border: 1px solid $dark-border-color !important;
        }
        .caption {
          border-top: none !important;
        }
      }
    }
    .modal-gallery-wrapper {
      a {
        > div {
          border: none !important;
        }
      }
    }
    .custom-mesonry,
    .mega-inline,
    .mega-horizontal {
      img,
      .card {
        border: 1px solid $dark-border-color;
      }
    }
    .custom-datatable {
      input {
        border: 1px solid $dark-border-color;
        color: $light-text;
        &::placeholder {
          color: $light-text;
        }
      }
      .ngx-datatable {
        &.bootstrap {
          .datatable-header {
            border: 1px solid $dark-border-color;
            border-bottom: none;
            .datatable-header-cell {
              border-bottom: none;
            }
          }
          .datatable-body {
            border: 1px solid $dark-border-color;
            border-top: none;
            .datatable-selection {
              .datatable-scroll {
                .datatable-row-wrapper {
                  &:last-child {
                    border-bottom: none;
                  }
                }
              }
            }

            .datatable-body-row {
              border-top: 1px solid $dark-border-color;
              &.active {
                background-color: $primary-color;
              }
              &.datatable-row-even {
                background-color: $dark-body-background;
                &:hover {
                  background-color: #28303c;
                }
                &.active {
                  background-color: $primary-color;
                }
              }
              &.datatable-row-odd {
                &:hover {
                  background-color: #28303c;
                }
              }
            }
          }
          .datatable-footer {
            background: $dark-card-background;
            .datatable-pager {
              ul {
                li {
                  &:not(.disabled).active {
                    a {
                      background-color: $primary-color;
                    }
                  }
                  &:not(.disabled):hover {
                    a {
                      border: 1px solid $primary-color;
                    }
                  }
                }
              }
            }
          }
        }
        .datatable-footer {
          .datatable-pager {
            .pager {
              border: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    .timeline-content {
      background: $dark-body-background;
      &::after {
        border-color: transparent transparent transparent $dark-body-background;
      }
    }
    .timeline-item {
      &:nth-child(even) {
        .timeline-content {
          &::after {
            border-color: transparent $dark-body-background transparent
              transparent;
          }
        }
      }
    }
    #demo3,
    #demo4 {
      background-color: $dark-card-background;
    }
    .my-drop-zone {
      background-color: $dark-border-color;
      border: dotted 3px $dark-body-background;
    }
    .upload-input,
    .upload-table {
      input,
      table {
        border: 1px solid $dark-border-color;
      }
    }
    .swal2-popup {
      background: $dark-card-background;
      &.text-dark {
        .swal2-title {
          color: $theme-body-font-color;
        }
      }
      .swal2-title {
        color: $dark-all-font-color;
      }
      .swal2-content {
        color: $dark-small-font-color;
      }
      .swal2-footer {
        border-top: 1px solid $dark-border-color;
      }
      .swal2-styled {
        &:focus {
          box-shadow: 0 0 0 2px $dark-card-background,
            0 0 0 4px rgba(50, 100, 150, 0.4);
        }
      }
      .swal2-file:focus,
      .swal2-input:focus,
      .swal2-textarea {
        color: $dark-all-font-color;
        &:focus {
          border: 1px solid $dark-border-color;
          box-shadow: 0 0 3px $dark-card-background;
        }
      }
    }
    .custom-datepicker,
    .mutliple-datepicker {
      .ngb-dp-navigation-select {
        .form-select {
          background-color: $white !important;
        }
      }
      .form-select,
      .custom-file-label {
        color: $theme-body-font-color !important;
      }
      .form-select {
        &:disabled {
          color: #6c757d !important;
          background-color: #e9ecef !important;
        }
      }
    }
    .mutliple-datepicker,
    .custom-datepicker-dropdown {
      .custom-day {
        color: $theme-body-font-color;
        &.range,
        &:hover {
          color: $white;
        }
      }
      ngb-datepicker,
      .dropdown-menu {
        background-color: $white !important;
        border: none !important;
      }
      .align-content-between {
        .form-select {
          color: $dark-all-font-color !important;
          border: 1px solid $dark-border-color !important;
        }
      }
      hr {
        border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
      }
    }
    .dropdown-item {
      color: $dark-small-font-color;
      &:hover,
      &:focus {
        background-color: $dark-card-background;
      }
    }
    .drag-n-drop {
      &.parent {
        background-color: rgba($dark-card-background, 0.2);
      }
    }
    .cropper {
      outline-color: rgba($dark-card-background, 0.3) !important;
    }
    .modal-footer {
      border-top: 1px solid $dark-border-color;
    }
    .vertical-tab {
      .nav-tabs {
        .nav-item {
          border-color: $dark-border-color;
          .nav-link {
            color: $dark-all-font-color;
            border-color: $dark-border-color;
            &.active {
              background-color: $primary-color;
            }
          }
          &:hover {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .server-datatable {
      .table-responsive {
        table {
          border: 1px solid $dark-border-color;
          tr {
            border-top: 1px solid $dark-border-color;
            &:nth-child(even) {
              background-color: #293240;
              td,
              th {
                &:first-child {
                  background-color: #28303c;
                }
              }
              &:hover {
                background-color: #28303c;
              }
            }
            &:nth-child(odd) {
              td,
              th {
                &:first-child {
                  background-color: #2e3a4c;
                }
              }
              &:hover {
                background-color: #28303c;
                td,
                th {
                  &:first-child {
                    background-color: #282f3a;
                  }
                }
              }
            }
          }
        }
      }
    }
    .bit-coin-tab {
      .nav-tabs {
        .nav-link {
          &.active {
            background-color: $primary-color;
          }
        }
      }
    }
    .offcanvas-bookmark {
      &.page-wrapper {
        .page-body-wrapper {
          .page-body {
            .bookmark {
              ul {
                li {
                  form {
                    .form-control-search {
                      .Typeahead-menu {
                        .ProfileCard {
                          .ProfileCard-details {
                            .ProfileCard-realName {
                              a {
                                color: $dark-small-font-color !important;
                              }
                            }
                          }
                          &:hover {
                            background-color: $dark-card-background;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .page-wrapper {
      .page-body-wrapper {
        .page-sidebar {
          background-color: $white;
          .sidebar-user {
            h6 {
              color: $primary-color;
            }
            p {
              color: $theme-body-font-color;
            }
          }
          .sidebar-menu {
            > li {
              > a {
                color: $theme-body-font-color;
                &:hover {
                  color: $primary-color;
                }
              }
            }
            .sidebar-submenu {
              > li {
                > a {
                  color: $theme-body-font-color;
                  &:hover,
                  &.active {
                    color: $primary-color;
                  }
                }
                &.active {
                  > a {
                    &.active {
                      color: $primary-color;
                    }
                  }
                }
              }
            }
          }
        }
        .page-body {
          color: $dark-all-font-color;
          background-color: $dark-body-background;
          .custom-datepicker {
            .form-select,
            .custom-file-label {
              background: $white !important;
              color: $theme-body-font-color !important;
            }
          }
          .bookmark {
            ul {
              li {
                a {
                  color: $dark-small-font-color !important;
                }
                .search-form {
                  .form-control-search {
                    input {
                      background-color: $dark-body-background;
                      border: 1px solid $dark-border-color;
                      color: $dark-all-font-color;
                    }
                    &:before {
                      background: $dark-border-color;
                    }
                  }
                }
                .Typeahead-menu {
                  background-color: $dark-body-background;
                }
              }
            }
          }
          .topper-lists {
            table {
              tbody {
                tr {
                  td {
                    p {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
            }
          }
          .header-small {
            color: $dark-small-font-color;
          }
          .statistics {
            p {
              color: $dark-small-font-color;
            }
          }
          .feather-main,
          .professor-table {
            .media-body,
            .professor-block {
              p {
                color: $dark-small-font-color;
              }
            }
          }
          .logs-element {
            span {
              + span {
                color: $dark-small-font-color;
              }
            }
          }
          .progress-block {
            .progress-title {
              span {
                + span {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .ecommerce-icons {
            div {
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .new-users,
          .recent-notification {
            .d-flex {
              .flex-grow-1 {
                p {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .progress-media {
            .media {
              .media-body {
                span {
                  color: $dark-small-font-color;
                }
              }
            }
            .progress-change {
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .topper-lists {
            table {
              tbody {
                tr {
                  td {
                    border-top: none !important;
                    border-bottom: 1px solid $dark-border-color;
                  }
                  &:last-child {
                    td {
                      border-bottom: none;
                    }
                  }
                }
              }
            }
          }
          .notifiaction-media {
            .media {
              .media-body {
                .circle-left {
                  border: 5px solid #334154;
                }
              }
            }
          }
          .upcoming-event {
            .upcoming-innner {
              border-bottom: 1px solid $dark-border-color;
              &:last-child {
                border-bottom: none;
              }
            }
          }
          .professor-table {
            table {
              tbody {
                tr {
                  td {
                    border-top: none !important;
                  }
                }
              }
            }
          }
          .number-widgets {
            .media {
              .media-body {
                h6 {
                  color: $dark-all-font-color;
                }
              }
            }
          }
          .activity {
            .media {
              .gradient-round {
                &.gradient-line-1,
                &.small-line,
                &.medium-line {
                  &:after {
                    background-color: $dark-border-color;
                  }
                }
              }
              .media-body {
                h6 {
                  span {
                    color: $dark-small-font-color;
                  }
                }
              }
            }
          }
          .card.custom-card {
            .card-header {
              border-bottom: none !important;
            }
          }
        }
        .custom-card .card-header img {
          background-color: $black;
          opacity: 0.8;
        }
        .page-header {
          .row {
            h3 {
              small {
                color: $dark-small-font-color;
              }
            }
          }
          .breadcrumb-item {
            &.active {
              color: $dark-small-font-color;
            }
          }
        }
        .page-body {
          background-color: $dark-body-background;
          .card {
            background-color: $dark-card-background;
            box-shadow: 0 0 1px 1px $dark-border-color;
            .card-header {
              background-color: $dark-card-background;
              border-bottom: 1px solid $dark-border-color;
              .buttons-right {
                .right-header-dropdown.onhover-show-div {
                  background-color: $dark-body-background;
                  box-shadow: 0 0 2px 2px $dark-body-background;
                  a {
                    color: $dark-all-font-color;
                  }
                }
              }
              &.card-header-border {
                .right-header {
                  span {
                    &:first-child {
                      color: $dark-small-font-color;
                    }
                  }
                  .right-header-dropdown.onhover-show-div {
                    background-color: $dark-body-background;
                    box-shadow: 0 0 2px 2px $dark-body-background;
                    a {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
              > span {
                color: $dark-all-font-color;
              }
              h1,
              h2,
              h3,
              h4,
              h5,
              h6 {
                color: $dark-all-font-color;
              }
              .card-header-right {
                i {
                  color: $dark-all-font-color;
                }
              }
            }
            .alert-dark {
              color: $dark-small-font-color;
              a {
                color: $dark-small-font-color;
              }
            }
            .alert {
              &.alert-custom {
                border-color: $dark-border-color;
              }
            }
            .alert-light.outline,
            .alert-light.outline-2x,
            .alert-light.inverse {
              color: $dark-all-font-color;
            }
            #animation-box {
              .animate-widget {
                p {
                  color: $dark-small-font-color !important;
                }
              }
            }
            .grid-item {
              img {
                background-color: $dark-card-background;
                border: 1px solid $dark-border-color;
              }
            }
            .line {
              color: $dark-all-font-color;
            }
            .table {
              th,
              td {
                color: $dark-all-font-color;
              }
              thead {
                th {
                  border-bottom: 2px solid $dark-border-color;
                }
                .border-bottom-primary {
                  th {
                    border-bottom: 1px solid $primary-color;
                  }
                }
              }
              .table-primary,
              .table-secondary,
              .table-success,
              .table-info,
              .table-warning,
              .table-light,
              .table-danger {
                th,
                td {
                  color: $sidebar-submenu-font-color;
                }
              }
              .bg-primary,
              .bg-secondary,
              .bg-success,
              .bg-info,
              .bg-warning,
              .bg-danger {
                color: $white;
              }
              .bg-light {
                color: $black;
              }
              .thead-light {
                th {
                  color: $black;
                }
              }
              .double,
              .dotted,
              .dashed {
                border-color: $dark-border-color;
              }
              tbody {
                .border-bottom-primary {
                  th,
                  td {
                    border-bottom: 1px solid $primary-color;
                  }
                }
              }
            }
            .table-responsive {
              .table {
                th,
                td {
                  border-top: 1px solid $dark-border-color;
                }
              }
            }
            .table[class*="bg-"] {
              th,
              td {
                color: $white;
              }
            }
            .table-striped {
              tbody {
                tr {
                  &:nth-of-type(odd) {
                    background-color: rgba(0, 0, 0, 0.05);
                    &:hover {
                      th,
                      td {
                        color: $white;
                      }
                    }
                  }
                }
              }
            }
            .table-bordered {
              td,
              th {
                border-color: $dark-border-color;
              }
            }
            .table-inverse {
              tbody {
                tr {
                  &:nth-of-type(odd) {
                    &:hover {
                      td {
                        color: $dark-all-font-color;
                      }
                    }
                  }
                }
              }
            }
            .table-border-vertical {
              tr,
              th,
              td {
                border-right: 1px solid $dark-border-color;
              }
            }
            .table-styling {
              thead,
              tbody {
                th,
                td {
                  color: $white;
                }
              }
            }
            .card-footer {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-border-color;
            }
            .switch {
              .switch-state {
                background-color: $dark-body-background;
              }
              input {
                &:checked {
                  + .switch-state {
                    background-color: $primary-color;
                  }
                }
              }
            }
            .bg-white {
              background-color: $dark-card-background !important;
            }
            .b-l-light {
              border-left: 1px solid $dark-border-color !important;
            }
            .ct-grid {
              stroke: $dark-small-font-color;
            }
            .ct-label {
              color: $dark-small-font-color;
            }
            hr {
              border-top: 1px solid $dark-border-color;
            }
            .calender-widget {
              .cal-date {
                background-color: $dark-card-background;
                border: 1px solid $dark-border-color;
              }
            }
            .contact-form {
              .theme-form {
                border: 1px solid $dark-border-color;
                .form-icon {
                  background-color: $dark-card-background;
                  border: 1px solid $dark-border-color;
                  color: $dark-all-font-color;
                }
              }
            }
            .btn-outline-light,
            .btn-outline-dark,
            .btn-outline-light-2x {
              color: $white !important;
            }
            .btn-outline-light {
              &:hover {
                color: $black !important;
              }
            }

            .border-right {
              border-right: 1px solid $dark-border-color !important;
            }
            .flot-chart-placeholder {
              .flot-text {
                color: $dark-all-font-color !important;
              }
              svg {
                text {
                  fill: $dark-all-font-color;
                }
              }
            }
            .chart-overflow {
              &#line-chart,
              &#column-chart1,
              &#column-chart2 {
                rect {
                  fill: $dark-card-background;
                }
              }
              svg {
                > rect {
                  fill: $dark-card-background;
                }
                > g {
                  > g {
                    > g {
                      text {
                        fill: rgb(177, 177, 178);
                      }
                    }
                  }
                  > text {
                    fill: $dark-all-font-color;
                  }
                }
              }
            }
            .radial-bar {
              &:after {
                background-color: $dark-card-background;
                color: $dark-all-font-color;
              }
            }
            .bar-chart-widget {
              .bottom-content {
                .num {
                  color: $dark-all-font-color;
                  .color-bottom {
                    color: $dark-all-font-color;
                  }
                }
              }
            }
            .b-r-light {
              border-right: 1px solid $dark-border-color !important;
            }
            .chart-container {
              .live-products,
              .turnover,
              .monthly,
              .uses {
                .ct-labels {
                  .ct-label {
                    color: $white;
                  }
                }
                .ct-grid {
                  stroke: $white;
                }
              }
              #browser-uses-chart,
              #website-visiter-chart {
                svg {
                  text {
                    fill: $dark-all-font-color;
                  }
                }
              }
            }
            .status-details {
              h4 {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
            .clipboaard-container {
              .form-control {
                background-color: $dark-body-background;
                color: $dark-small-font-color;
                border: 1px solid $dark-border-color;
              }
            }
            .img-thumbnail {
              background-color: $dark-body-background;
              border: 1px solid $dark-border-color;
            }
            .dataTables_wrapper {
              .dataTables_length {
                select {
                  background-color: $dark-body-background;
                  color: $dark-all-font-color;
                  border: 1px solid $dark-border-color;
                }
              }
              .dataTables_length,
              .dataTables_filter,
              .dataTables_info,
              .dataTables_processing,
              .dataTables_paginate {
                color: $dark-all-font-color;
              }
              .dataTables_paginate {
                border: 1px solid $dark-border-color;
              }
              .dataTables_filter {
                input[type="search"] {
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                  color: $dark-all-font-color;
                }
              }
              table.dataTable.display,
              table.dataTable.order-column.stripe {
                tbody {
                  tr {
                    background-color: $dark-card-background;
                    &:hover {
                      > .sorting_1 {
                        background-color: #28303c;
                      }
                    }
                  }
                  tr.odd {
                    background-color: $dark-body-background;
                    > .sorting_1 {
                      background-color: #28303c;
                    }
                  }
                  tr.even {
                    > .sorting_1 {
                      background-color: rgb(46, 58, 76);
                    }
                  }
                }
              }
              table.dataTable {
                border: 1px solid $dark-border-color;
                thead {
                  th,
                  td {
                    border-bottom: 2px solid $dark-border-color;
                  }
                }
                input,
                select {
                  background-color: $dark-body-background;
                  color: $dark-all-font-color;
                  border: 1px solid $dark-border-color;
                }
                tbody {
                  tr {
                    background-color: $dark-card-background;
                  }
                  td.select-checkbox,
                  th.select-checkbox {
                    &:before {
                      border: 1px solid $dark-small-font-color;
                    }
                  }
                }
              }
              .table-striped {
                tbody {
                  tr {
                    &:nth-of-type(odd) {
                      background-color: $dark-body-background;
                    }
                  }
                }
              }
              .dataTables_paginate {
                .paginate_button {
                  color: $dark-all-font-color !important;
                  &.current,
                  &:active {
                    border-color: $primary-color;
                  }
                }
                .paginate_button.disabled {
                  color: $dark-small-font-color !important;
                  &:hover,
                  :active {
                    color: $dark-small-font-color !important;
                  }
                }
              }
              table.dataTable.row-border,
              table.dataTable.display {
                tbody {
                  th,
                  td {
                    border-top: 1px solid $dark-border-color;
                  }
                }
              }
              table.dataTable.display,
              table.dataTable.order-column.hover {
                tbody {
                  tr.even {
                    &:hover {
                      > .sorting_1 {
                        background-color: #282f3a;
                      }
                    }
                  }
                }
              }
              table.dataTable.hover,
              table.dataTable.display {
                tbody {
                  tr {
                    &:hover {
                      background-color: #28303c;
                    }
                  }
                }
              }
              table.dataTable.cell-border {
                th,
                td {
                  border-top: 1px solid $dark-border-color;
                  border-right: 1px solid $dark-border-color;
                  &:first-child {
                    border-left: 1px solid $dark-border-color;
                  }
                }
              }
              table.dataTable.order-column,
              table.dataTable.display {
                tbody {
                  tr {
                    > .sorting_1,
                    > .sorting_2,
                    > .sorting_3 {
                      background-color: $dark-body-background;
                    }
                  }
                }
              }
            }
            #example-style-3_wrapper {
              #example-style-3 {
                tfoot {
                  border-top: 2px solid $dark-border-color;
                }
              }
            }
            .page-item.disabled {
              .page-link {
                background-color: $dark-card-background;
                border-color: $dark-border-color;
              }
            }
            .page-link {
              background-color: $dark-card-background;
              border: 1px solid $dark-border-color;
              &:hover {
                background-color: $dark-border-color;
              }
            }
            .page-item.active {
              .page-link {
                background-color: $primary-color;
              }
            }
            .ecommerce-widget {
              .icon {
                color: $dark-body-background;
              }
              .total-num {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
            .flot-chart-container-small {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-border-color;
            }
            .product-table {
              #API-2_wrapper {
                #API-2 {
                  tbody {
                    td {
                      span,
                      p {
                        color: $dark-small-font-color;
                      }
                    }
                  }
                }
              }
              h6 {
                color: $dark-small-font-color;
              }
            }
            .nav-material.nav-tabs {
              border-bottom: 1px solid $dark-border-color;
              .nav-item {
                .nav-link {
                  color: $dark-all-font-color;
                }
              }
              .nav-link {
                &.active,
                &:focus,
                &:hover {
                  color: $primary-color;
                }
              }
            }
            .br-theme-bars-1to10,
            .br-theme-bars-movie,
            .br-theme-bars-pill,
            .br-theme-bars-reversed,
            .br-theme-bars-horizontal {
              .br-widget {
                a {
                  background-color: $dark-border-color;
                  &.br-active,
                  &.br-selected {
                    background-color: $primary-color;
                  }
                }
              }
            }
            .br-theme-bars-square {
              .br-widget {
                a {
                  border: 2px solid $dark-border-color;
                  background-color: $dark-card-background;
                  color: $dark-all-font-color;
                  &.br-active,
                  &.br-selected {
                    border: 2px solid $primary-color;
                    color: $primary-color;
                  }
                }
              }
            }
            .br-theme-fontawesome-stars,
            .br-theme-fontawesome-stars-o {
              .br-widget {
                a {
                  &:after {
                    color: $dark-border-color;
                  }
                  &.br-selected,
                  &.br-active {
                    &:after {
                      color: $primary-color;
                    }
                  }
                }
              }
            }
            .scroll-demo {
              border: 1px solid $dark-border-color;
            }
            .search-form {
              input {
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
                background-color: $dark-body-background;
              }
              .form-group {
                &:before {
                  background: $dark-card-background;
                }
              }
            }
            .cd-timeline-content {
              background-color: $dark-border-color;
              &::before {
                border-left: 7px solid $dark-border-color;
              }
            }
            .cd-timeline-block {
              &:nth-child(even) {
                .cd-timeline-content {
                  &::before {
                    border-right-color: $dark-border-color;
                    border-left: transparent;
                  }
                }
              }
            }
            .breadcrumb {
              background-color: $dark-body-background;
            }
            .user-status {
              table {
                td,
                th {
                  border-top: none !important;
                }
              }
            }
            #donut-color-chart-morris-daily,
            #donut-color-chart-morris,
            #browser-uses-chart {
              svg {
                opacity: 0.5;
              }
            }
          }
          .form-builder-header-1,
          .form-builder-2-header {
            background-color: $dark-body-background;
          }
          .form-builder {
            .nav-primary {
              .show {
                > .nav-link {
                  color: $white;
                }
              }
              .nav-link {
                color: $dark-all-font-color;
                &.active {
                  color: $white;
                }
              }
              .nav-pills.nav-primary {
                .show {
                  > .nav-link {
                    color: $white;
                  }
                }
                .nav-link {
                  color: $dark-all-font-color;
                  &.active {
                    color: $white;
                  }
                }
              }
            }
            .drag-box {
              fieldset {
                border: 1px solid $dark-border-color;
              }
            }
            .help-block {
              color: $dark-small-font-color;
            }
          }
          #viewhtml {
            .render {
              background-color: $dark-card-background;
              color: $dark-all-font-color;
              border-color: $dark-border-color;
            }
          }
          .form-builder-column {
            .drag-bx {
              border: 1px dotted $dark-border-color;
            }
          }
          .wizard-4 {
            ul.anchor {
              li {
                a.disabled {
                  color: $dark-body-background;
                }
              }
            }
          }
          .gallery-with-description {
            h4 {
              color: $dark-all-font-color;
            }
            a {
              > div {
                border: 1px solid $dark-border-color;
              }
            }
            p {
              color: $dark-small-font-color;
            }
          }
          .jsgrid-grid-header {
            background-color: $dark-card-background;
            border: 1px solid $dark-border-color;
          }
          .jsgrid-header-row,
          .jsgrid-filter-row {
            > .jsgrid-header-cell,
            > .jsgrid-cell {
              background: $dark-card-background;
              border: 1px solid $dark-border-color;
              input {
                background-color: $dark-body-background;
                border-color: $dark-border-color;
                color: $dark-all-font-color;
              }
            }
            select {
              background-color: $dark-body-background;
              border-color: $dark-border-color;
              color: $dark-all-font-color;
            }
          }
          .jsgrid-row {
            > .jsgrid-cell {
              background-color: $dark-card-background;
            }
          }
          .jsgrid-alt-row {
            > .jsgrid-cell {
              background-color: $dark-body-background;
            }
          }
          .jsgrid-cell,
          .jsgrid-grid-body {
            border: 1px solid $dark-border-color;
          }
          .jsgrid-selected-row {
            > .jsgrid-cell {
              background-color: #28303c;
            }
          }
          .jsgrid {
            .jsgrid-pager {
              [class*="jsgrid-pager"] {
                border: 1px solid $dark-border-color;
              }
              .jsgrid-pager-page {
                a {
                  color: $dark-all-font-color;
                }
              }
            }
          }
          .jsgrid-pager-current-page {
            color: $dark-all-font-color;
            font-weight: 700;
          }
          .sub-title {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          }
          .navs-icon {
            li {
              a {
                color: $dark-all-font-color;
                &:hover {
                  color: $primary-color;
                }
              }
            }
            .separator {
              border: 1px solid $dark-border-color;
            }
          }
          .default-according {
            li {
              .text-muted {
                color: $dark-all-font-color !important;
              }
            }
          }
          .navs-icon.default-according.style-1 {
            li {
              button {
                &:hover {
                  color: $primary-color !important;
                }
              }
            }
          }
          .nav-list {
            .nav-list-disc {
              li {
                a {
                  color: $dark-all-font-color;
                }
                &:hover {
                  color: $primary-color;
                  a {
                    color: $primary-color;
                  }
                }
              }
            }
          }
          .navs-dropdown {
            .onhover-show-div {
              background-color: $dark-body-background;
              box-shadow: 0 0 2px 2px $dark-border-color;
              .navs-icon {
                li {
                  a {
                    svg {
                      path,
                      line,
                      polyline,
                      polygon,
                      rect {
                        color: $dark-all-font-color !important;
                      }
                    }
                    &:hover {
                      svg {
                        path,
                        line,
                        polyline,
                        polygon,
                        rect {
                          color: $primary-color !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .onhover-dropdown {
            &:hover {
              .onhover-show-div {
                &:before {
                  border-bottom: 7px solid $dark-body-background;
                }
              }
            }
          }
          .default-according {
            .card {
              box-shadow: 0px 0px 1px 1px rgb(55, 66, 82);
              .btn-link {
                color: $dark-all-font-color;
              }
              .card-body {
                color: $dark-small-font-color;
              }
            }
          }
          .border {
            border: 1px solid $dark-border-color !important;
          }
          .blog-box {
            .blog-date {
              color: $dark-all-font-color;
            }
            .blog-details,
            .blog-details-main {
              .blog-social {
                li {
                  color: $dark-small-font-color;
                  border-right: 1px solid $dark-border-color;
                  &:first-child {
                    border-right: 1px solid $dark-border-color;
                  }
                  &:last-child {
                    border-right: none;
                  }
                }
              }
              p {
                color: $dark-all-font-color;
              }
              .single-blog-content-top {
                border-top: 1px solid $dark-border-color;
                p {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .comment-box {
            .media {
              h6 {
                span {
                  color: $dark-small-font-color;
                }
              }
              img {
                border: 1px solid $dark-border-color;
              }
            }
            p {
              color: $dark-small-font-color;
            }
            .comment-social {
              li {
                color: $dark-small-font-color;
                &:first-child {
                  border-right: 1px solid $dark-border-color;
                }
              }
            }
            hr {
              border-top: 1px solid $dark-border-color;
            }
          }
          .table-hover {
            tbody {
              tr {
                &:hover {
                  background-color: $dark-body-background;
                  td,
                  th {
                    color: $white;
                  }
                }
              }
            }
          }
          ul.the-icons {
            li {
              border: 1px dotted $dark-border-color;
              color: $sidebar-submenu-font-color;
              &:hover {
                background: $black;
                box-shadow: 0 0 3px $dark-card-background;
              }
            }
          }
          .button-builder-wrap {
            .box {
              background-color: $dark-card-background;
              border: 1px solid $dark-border-color;
            }
            .button-preview {
              h2 {
                color: $dark-all-font-color;
              }
            }
            pre.well {
              background-color: $dark-card-background !important;
            }
          }
          .crm-activity {
            > li {
              + li {
                border-top: 1px solid $dark-border-color;
              }
              h6 {
                color: $dark-all-font-color;
              }
            }
            ul.dates {
              li {
                color: $dark-small-font-color;
              }
            }
          }
          #donut-color-chart-morris,
          #donut-color-chart-morris-daily {
            svg {
              text {
                fill: $dark-all-font-color;
              }
            }
          }
          .custom-card {
            .card-profile {
              img {
                background-color: $dark-card-background;
              }
            }
            .profile-details {
              h6 {
                color: $dark-small-font-color;
              }
            }
            .card-social {
              li {
                a {
                  color: $dark-small-font-color;
                }
              }
            }
            .card-footer {
              > div {
                h6 {
                  color: $dark-small-font-color;
                }
                + div {
                  border-left: 1px solid $dark-border-color;
                }
              }
            }
          }
          .form-control {
            background-color: $dark-body-background;
            color: $dark-all-font-color;
            border: 1px solid $dark-border-color;
          }
          .checkbox,
          .radio {
            label {
              &::before {
                background-color: $dark-body-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
            }
          }
          .dropdown-basic {
            .dropdown {
              .dropdown-content {
                background-color: $dark-body-background;
                a {
                  color: $dark-all-font-color;
                  &:hover {
                    background-color: $dark-card-background;
                  }
                }
                .dropdown-divider {
                  border: 1px solid $dark-border-color;
                }
              }
            }
          }
          .dropup-basic {
            .dropup {
              .dropup-content {
                background-color: $dark-body-background;
                a {
                  color: $dark-all-font-color;
                  &:hover,
                  &.active {
                    background-color: $dark-card-background;
                  }
                }
              }
            }
          }
          .dropzone.dz-clickable {
            .dz-message {
              h6 {
                color: $dark-all-font-color;
              }
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .dropzone {
            .dz-preview {
              background-color: $dark-body-background;
              .dz-details {
                background-color: $dark-card-background;
              }
            }
          }
          .browser-widget {
            .media-body {
              column-rule: 1px solid $dark-border-color;
              h4 {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .email-wrap {
            .email-app-sidebar {
              .media {
                img {
                  border: 2px solid $dark-body-background;
                }
              }
              .main-menu {
                > li {
                  a {
                    color: $dark-all-font-color;
                    &:hover {
                      background-color: $dark-body-background;
                    }
                  }
                }
              }
            }
            .email-right-aside {
              .email-body {
                .inbox {
                  .media.active {
                    background-color: $dark-body-background;
                  }
                }
              }
            }
            .media-body {
              h6 {
                color: $dark-all-font-color;
                small {
                  color: $dark-small-font-color;
                  span {
                    color: $dark-small-font-color;
                  }
                }
              }
              p {
                color: $dark-small-font-color;
              }
            }
            .email-top {
              border-bottom: 1px solid $dark-border-color;
            }
            p {
              color: $dark-small-font-color;
            }
            .email-content {
              .email-top {
                .user-emailid:after {
                  border: 1px solid $dark-border-color;
                }
              }
            }
          }
          .dropdown-menu {
            background-color: $dark-body-background;
            border: 1px solid $dark-border-color;
            a {
              color: $dark-all-font-color;
              &:hover {
                background-color: $dark-card-background;
              }
            }
          }
          .icon-lists {
            div {
              i {
                color: $dark-small-font-color;
              }
              &:hover {
                background-color: $dark-body-background;
              }
            }
          }
          .widget-joins {
            .media {
              .media-body {
                > span {
                  color: $dark-small-font-color;
                }
              }
              .details {
                border-left: 1px solid $dark-border-color;
              }
            }
            &:before,
            &:after {
              background-color: $dark-border-color;
            }
          }
          .redial-social-widget {
            i {
              background-color: $dark-card-background;
            }
          }
          .social-widget-card {
            h5,
            h4 {
              color: $dark-all-font-color;
            }
            span {
              color: $dark-small-font-color;
            }
          }
          .b-b-light {
            border-bottom: 1px solid $dark-border-color !important;
          }
          .b-r-dark {
            border-right: 1px solid $white !important;
          }
          .testimonial {
            i {
              color: $dark-border-color;
            }
            p {
              color: $dark-all-font-color;
            }
            h5 {
              color: $dark-all-font-color;
            }
            span {
              color: $dark-small-font-color;
            }
          }
          .grid-showcase {
            span {
              background-color: $dark-card-background;
              border: 1px solid $dark-border-color;
            }
          }
          .grid-align {
            .row {
              background-color: $dark-border-color;
              border: 1px solid $dark-border-color;
            }
          }
          .border-style {
            .card {
              border: 1px solid $dark-border-color;
            }
          }
          .offer-style {
            .card {
              border: 1px dashed $dark-border-color;
            }
          }
          .page-builder {
            .btn-grid {
              background-color: $dark-card-background;
              color: $dark-small-font-color !important;
              border-right: 1px solid $dark-border-color;
            }
            .ge-mainControls {
              .ge-addRowGroup {
                .ge-row-icon {
                  .column {
                    border-left: 3px solid $dark-card-background;
                  }
                }
              }
            }
            .btn-code,
            .btn-screen {
              color: $dark-small-font-color;
              border-right: 1px solid $dark-border-color;
            }
            .ge-canvas.ge-editing {
              .row {
                background-color: $dark-body-background;
              }
              .column {
                border: 1px solid $dark-border-color;
                background-color: $dark-card-background;
              }
              .ge-tools-drawer {
                .ge-details {
                  input {
                    background-color: $dark-body-background;
                    border-color: $dark-border-color;
                    color: $dark-small-font-color;
                  }
                  .btn-group {
                    a {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
            }
            .ge-content-type-ckeditor {
              color: $dark-all-font-color;
            }
          }
          .search-page {
            .info-block {
              + .info-block {
                border-top: 1px solid $dark-border-color;
              }
            }
          }
          .card-absolute {
            .bg-primary,
            .bg-secondary {
              h5 {
                color: $white;
              }
            }
          }
          .timeline-small {
            .media {
              .timeline-round {
                &.timeline-line-1,
                &.small-line,
                &.medium-line {
                  &:after {
                    background-color: $dark-border-color;
                  }
                }
              }
            }
          }
          /* body end*/
        }
        footer {
          border-top: 1px solid $dark-body-background;
          background-color: $dark-card-background;
        }
        .form-select,
        .custom-file-label {
          background: $dark-body-background;
          color: $dark-all-font-color;
        }
        .footer-fix {
          background-color: $dark-card-background;
          border-top: 1px solid $dark-border-color;
        }
      }
      .dt-button-info {
        background-color: $dark-card-background;
        border: 1px solid $dark-small-font-color;
        h2 {
          background-color: $dark-card-background;
        }
      }
      .chat-box {
        .chat-menu {
          border-left: 1px solid $dark-border-color;
          .nav-tabs {
            border-bottom: 1px solid $dark-border-color;
            .nav-item {
              .nav-link {
                &.active {
                  color: $dark-all-font-color !important;
                }
              }
            }
          }

          .user-profile {
            .image {
              .icon-wrapper {
                background-color: $dark-card-background;
                box-shadow: 1px 1px 3px 1px $dark-body-background;
              }
              .avatar {
                img {
                  border: 5px solid $dark-border-color;
                }
              }
            }
            .social-media {
              a {
                color: $dark-small-font-color;
              }
            }
            .follow {
              span {
                color: $dark-small-font-color;
              }
              .follow-num {
                color: $dark-all-font-color;
              }
            }
          }
        }
        .status {
          color: $dark-small-font-color;
          p {
            color: $dark-all-font-color !important;
          }
        }
        .chat-right-aside {
          .chat {
            .chat-header {
              border-bottom: 1px solid $dark-border-color;
              .chat-menu-icons {
                li {
                  a {
                    i {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
              img {
                box-shadow: 1px 1px 4px 1px $dark-body-background;
              }
            }
            .chat-msg-box {
              .my-message {
                border: 1px solid $dark-border-color;
              }
              .message {
                color: $dark-all-font-color;
              }
              .other-message {
                background-color: $dark-body-background;
              }
            }
            .chat-message {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-border-color;
              .input-group {
                .form-control {
                  background-color: $dark-card-background;
                }
              }
            }
          }
        }
        .chat-history {
          .call-icons {
            ul {
              li {
                border: 1px solid $dark-border-color;
                a {
                  color: $dark-small-font-color;
                }
              }
            }
          }
        }
        .bitcoin-chat {
          .chat {
            .chat-msg-box {
              .message {
                &.my-message {
                  background-color: #1c222b;
                  .chat-user-img {
                    border: 2px solid $dark-body-background;
                  }
                }
                &.other-message {
                  .chat-user-img {
                    border: 2px solid $dark-body-background;
                  }
                }
              }
            }
          }
        }
      }
      pre {
        background-color: $dark-border-color;
        color: $dark-all-font-color;
      }
      .scorlled {
        background-color: $dark-body-background;
      }
      .input-group-air {
        box-shadow: 0 3px 20px 0 $dark-border-color;
      }
      .input-group-solid {
        .input-group-text,
        .form-control {
          background: $dark-body-background;
          border: 1px solid $dark-border-color;
          color: $dark-all-font-color;
        }
      }
      .semilight-bg-color,
      .header-bg-color {
        background-color: $white;
      }
      .list-group-item {
        background-color: $dark-card-background;
        border: 1px solid rgba(255, 255, 255, 0.3);
        color: $dark-all-font-color;
        &.active {
          background-color: $primary-color;
          border-color: $primary-color;
          color: $white;
        }
        &:hover,
        &:focus {
          z-index: 1;
          text-decoration: none;
        }
      }
      .list-group-item-action {
        &:hover:not(.active),
        &:focus {
          background-color: $dark-body-background;
        }
      }
      .list-group-flush {
        .list-group-item {
          border-left: 0;
          border-right: 0;
          border-radius: 0;
        }
      }
      .list-group-item-primary {
        color: #004085;
        background-color: #b8daff;
      }
      .list-group-item-secondary {
        color: #383d41;
        background-color: #d6d8db;
      }
      .list-group-item-success {
        color: #155724;
        background-color: #c3e6cb;
      }
      .list-group-item-danger {
        color: #721c24;
        background-color: #f5c6cb;
      }
      .list-group-item-warning {
        color: #856404;
        background-color: #ffeeba;
      }
      .list-group-item-info {
        color: #0c5460;
        background-color: #bee5eb;
      }
      .list-group-item-light {
        color: #818182;
        background-color: #fdfdfe;
      }
      .list-group-item-dark {
        color: #1b1e21;
        background-color: #c6c8ca;
      }
      .auth-bg {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.9);
        .authentication-box {
          .card {
            background-color: $dark-card-background;
            .theme-form {
              .form-group {
                input {
                  color: $dark-all-font-color;
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                }
                label {
                  color: $dark-all-font-color;
                }
              }
              .checkbox {
                label {
                  color: $dark-all-font-color;
                  &::before {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
          }
          h3,
          h4,
          h6 {
            color: $white;
          }
        }
      }
      .auth-bg-video {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.7);
        .authentication-box {
          h4,
          h3 {
            color: $white;
          }
          h6 {
            color: $dark-small-font-color;
          }
          .card {
            background-color: $dark-card-background;
            .theme-form {
              .form-group {
                input[type="text"],
                input[type="password"] {
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                  color: $dark-all-font-color;
                }
              }
              .checkbox {
                label {
                  &::before {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
          }
        }
      }
      .error-wrapper {
        .maintenance-icons {
          li {
            i {
              color: $dark-border-color;
            }
            &:nth-child(3) {
              i {
                color: $dark-border-color;
              }
            }
          }
        }
      }
      .modal-content {
        background-color: $dark-card-background;
        .modal-header {
          border-bottom: 1px solid $dark-border-color;
          .btn-close {
            color: $dark-small-font-color;
          }
        }
        .modal-footer {
          border-top: 1px solid $dark-border-color;
        }
      }
      .comingsoon {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.9);
        .comingsoon-inner {
          h5 {
            color: $dark-all-font-color;
          }
          .countdown {
            border-top: 1px solid $dark-border-color;
            border-bottom: 1px solid $dark-border-color;
            .title {
              color: $dark-all-font-color;
            }
          }
          .coming-soon-bottom-link {
            a {
              color: $dark-all-font-color;
            }
          }
        }
      }
      .theme-form {
        .login-divider {
          border-top: 1px solid $dark-border-color;
          &:before {
            background: $dark-border-color;
            color: $dark-all-font-color;
          }
        }
      }
      .authentication-main {
        background-color: $dark-border-color;
        .auth-innerright {
          .reset-password-box {
            .card {
              background-color: $dark-card-background;
            }
            .reset-password-link {
              color: $dark-small-font-color;
            }
            .theme-form {
              .form-group {
                label {
                  color: $dark-small-font-color;
                }
                .form-control {
                  background-color: $dark-body-background;
                  color: $dark-all-font-color;
                  border-color: $dark-border-color;
                }
              }
              .opt-box {
                background-color: $dark-border-color;
              }
            }
          }
          .authentication-box {
            h4 {
              color: $dark-all-font-color;
            }
            h6 {
              color: $dark-small-font-color;
            }
            h3 {
              color: $dark-all-font-color;
            }
            .card {
              background-color: $dark-card-background;
              .theme-form {
                .form-group {
                  input[type="text"],
                  input[type="password"] {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-border-color;
                    color: $dark-all-font-color;
                  }
                }
                .checkbox {
                  label {
                    &::before {
                      background-color: $dark-body-background;
                      border: 1px solid $dark-border-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .vertical-menu-main {
        background-color: $dark-card-background;
      }
      .pixelstrap {
        a {
          color: $dark-all-font-color;
          &:focus,
          &:active,
          &:hover {
            color: $dark-all-font-color;
          }
        }
        > {
          li {
            > a.highlighted {
              &:before {
                background-color: $dark-card-background;
              }
              &:after {
                background-color: $dark-border-color;
              }
            }
          }
        }
      }
      @media (min-width: 1200px) {
        .pixelstrap {
          ul {
            background: $dark-body-background;
            border: 1px solid $dark-border-color;
            a {
              &:hover,
              &:focus,
              &:active,
              &.highlighted {
                color: $primary-color;
              }
              &.current {
                background-color: $dark-body-background;
                color: $primary-color;
              }
            }
          }
        }
      }
      .mega-menu {
        .title {
          color: $dark-all-font-color;
          border-bottom: 1px solid $dark-border-color;
        }
        .galleria-list {
          .galleria {
            > div {
              .username {
                color: $dark-all-font-color;
                small {
                  color: $dark-small-font-color;
                }
              }
            }
          }
        }
        .list-unstyled {
          div {
            a {
              &:hover {
                color: $primary-color;
              }
            }
          }
        }
      }
      .default-according {
        .card {
          background-color: $dark-card-background;
          .btn-link {
            background-color: $dark-card-background;
            border: 1px solid $dark-card-background;
            color: $white;
          }
          .text-muted {
            color: $dark-small-font-color !important;
          }
        }
        .bg-primary {
          .btn-link {
            background-color: $primary-color;
            border: 1px solid $primary-color;
          }
        }
        .bg-secondary {
          .btn-link {
            background-color: $secondary-color;
            border: 1px solid $secondary-color;
          }
        }
      }
      .collapse {
        .card-body {
          background-color: $dark-card-background;
        }
      }
      @media screen and (max-width: 1660px) {
        .chat-box {
          .chat-history {
            .call-icons {
              ul {
                li {
                  border: 1px solid $dark-border-color;
                }
              }
            }
            .total-time {
              h2 {
                color: $dark-all-font-color;
              }
            }
          }
        }
        .caller-img {
          img {
            opacity: 0.7;
          }
        }
        .chat-box {
          .chat-history {
            .call-content {
              > div {
                background-color: rgba(0, 0, 0, 0.75);
                background-blend-mode: overlay;
              }
            }
          }
        }
      }
      @media only screen and (max-width: 1199px) {
        .sm {
          background-color: $dark-card-background;
          border: 1px solid $dark-border-color;
        }
        .pixelstrap {
          .mobile-back {
            border-bottom: 1px solid $dark-border-color;
          }
          ul {
            li {
              border-top: 1px solid $dark-border-color;
            }
            a {
              background: $dark-card-background;
              &:hover,
              &:focus,
              &:active {
                background: $dark-card-background;
              }
            }
          }
        }
        .chat-menu {
          border-top: 1px solid $dark-border-color;
          background-color: $dark-card-background;
        }
        .error-wrapper {
          .maintenance-icons {
            li {
              i {
                color: $dark-color;
              }
            }
          }
        }
      }
      .pixelstrap {
        ul {
          background: $dark-card-background;
        }
      }
      @media only screen and (max-width: 575px) {
        .user-profile {
          .hovercard {
            .info {
              .user-designation {
                border-top: 1px solid $dark-border-color;
                border-bottom: 1px solid $dark-border-color;
              }
            }
          }
        }
        .widget-joins {
          .border-after-xs {
            &:after {
              background-color: $dark-border-color;
            }
          }
        }
      }
    }

    .ecommerce-widget {
      border: 1px solid $dark-border-color;
    }
    .cart {
      .qty-box {
        .input-group {
          .btn {
            background-color: $dark-border-color !important;
            border-color: $dark-border-color !important;
          }
        }
      }
    }
    .checkout {
      .checkout-details {
        background-color: $dark-body-background;
        border: 1px solid $dark-border-color;
        padding: 40px;
      }
    }
    .shopping-checkout-option {
      .checkbox_animated {
        &:after {
          border: 2px solid $dark-border-color;
        }
      }
    }
    .animate-chk {
      .radio_animated {
        &:after {
          border: 2px solid $dark-border-color;
        }
      }
    }
    .order-box {
      .title-box {
        color: #bfc2c6;
        border-bottom: 1px solid $dark-border-color;
      }
      .qty {
        border-bottom: 1px solid $dark-border-color;
        li {
          color: #bfc2c6;
          span {
            color: #bfc2c6;
          }
        }
      }
      .sub-total {
        li {
          color: #bfc2c6;
        }
      }
      .total {
        li {
          color: #bfc2c6;
        }
      }
    }
    .custom-scrollbar::-webkit-scrollbar-thumb {
      background-color: rgba(41, 50, 64, 0.52);
    }
    .product-box {
      .product-img {
        .product-hover {
          ul {
            li {
              .btn {
                color: $dark-small-font-color;
              }
              &:hover {
                .btn {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
    .radio_animated,
    .checkbox_animated {
      &:after {
        background: $dark-body-background;
        border: 2px solid $dark-body-background;
      }
    }
    .slider-product {
      border-top: 1px solid $dark-border-color;
      border-bottom: 1px solid $dark-border-color;
    }
    .square-product-setting {
      .icon-grid {
        background-color: $dark-card-background;
        svg {
          color: $dark-all-font-color;
        }
      }
    }
    .bitcoin-form {
      .bitcoin-form-dropdown {
        .onhover-dropdown {
          .btn {
            background-color: $dark-border-color;
            color: $dark-all-font-color;
          }
          .onhover-show-div {
            background-color: $dark-body-background;
            box-shadow: 0 0 2px 2px $dark-body-background;
          }
        }
      }
      .row {
        .input-group {
          .input-group-prepend {
            .input-group-text {
              border: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    .active-order-table,
    .market-table {
      table {
        thead {
          tr {
            th {
              border-top: none !important;
            }
          }
        }
        tbody {
          tr {
            td {
              border-bottom: 1px solid $dark-border-color;
            }
            &:last-child {
              td {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
    .pricing-card-design-2 {
      .pricing-block {
        .pricing-inner {
          ul {
            li {
              border-bottom: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    .flot-chart-container {
      .legend {
        .table {
          tbody {
            background-color: $dark-card-background;
            color: $white;
            .legendLabel {
              padding-left: 5px;
            }
          }
        }
      }
    }
    .google-visualization-tooltip {
      text {
        fill: rgba(0, 0, 0, 0.7) !important;
      }
    }
    .nav-dark,
    .nav-pills.nav-dark {
      .nav-link {
        color: $white;
      }
    }
    .loader-wrapper {
      background-color: $dark-body-background;
      .loader {
        background-color: $dark-body-background !important;
      }
    }
    .lg-backdrop {
      background-color: $dark-body-background;
    }
    .lg-outer {
      .lg-thumb-outer {
        background-color: $dark-card-background;
      }
    }
    .drag {
      background-color: $dark-card-background;
      form {
        background: $dark-card-background;
        box-shadow: 0 0 30px $dark-border-color;
        border: 1px dotted $dark-border-color;
        input {
          background-color: $dark-body-background;
          border: 1px solid $dark-border-color;
          color: $dark-all-font-color;
        }
        .help-block {
          text-transform: capitalize;
          color: $dark-small-font-color;
        }
      }
    }
    .draggable {
      input {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
        border: 1px solid $dark-border-color;
      }
      p {
        color: $dark-small-font-color;
      }
      select {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
        border: 1px solid $dark-border-color;
      }
      .radio,
      .checkbox {
        label {
          &::before {
            background-color: $dark-body-background;
            border: 1px solid $dark-border-color;
          }
        }
      }
    }
    .select2-container--default {
      .select2-selection--multiple,
      .select2-selection--single {
        background-color: $dark-body-background;
        border: 1px solid $dark-border-color !important;
      }
      .select2-search--inline {
        .select2-search__field {
          color: $dark-all-font-color;
        }
      }
      .select2-selection--single {
        .select2-selection__rendered {
          color: $dark-all-font-color;
        }
      }
      .select2-search--dropdown {
        .select2-search__field {
          background-color: $dark-body-background;
          border: 1px solid $dark-border-color;
          color: $dark-all-font-color;
        }
      }
    }
    .select2-dropdown {
      background-color: $dark-body-background;
      border: 1px solid $dark-border-color;
    }
    .select2-drpdwn {
      .form-control-primary {
        border-color: $primary-color !important;
        color: $primary-color !important;
      }
      .form-control-secondary {
        border-color: $secondary-color !important;
        color: $secondary-color !important;
      }
      .form-control-success {
        border-color: $success-color !important;
        color: $success-color !important;
      }
      .form-control-info {
        border-color: $info-color !important;
        color: $info-color !important;
      }
      .form-control-warning {
        border-color: $warning-color !important;
        color: $warning-color !important;
      }
      .form-control-danger {
        border-color: $danger-color !important;
        color: $danger-color !important;
      }
      .form-control-inverse {
        border-color: $white !important;
        color: $white !important;
      }
      .form-control-primary-fill {
        background-color: $primary-color !important;
        color: $white !important;
      }
      .form-control-secondary-fill {
        background-color: $secondary-color !important;
        color: $white !important;
      }
      .form-control-success-fill {
        background-color: $success-color !important;
        color: $white !important;
      }
      .form-control-info-fill {
        background-color: $info-color !important;
        color: $white !important;
      }
      .form-control-warning-fill {
        background-color: $warning-color !important;
        color: $white !important;
      }
      .form-control-danger-fill {
        background-color: $danger-color !important;
        color: $white !important;
      }
      .form-control-inverse-fill {
        background-color: $white !important;
        color: $black !important;
      }
    }
    .select2-container--default.select2-container--disabled {
      .select2-selection--single {
        background-color: $dark-border-color;
      }
    }
    .theme-form {
      .form-group {
        select.form-control:not([size]):not([multiple]) {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          border-color: $dark-border-color;
        }
        input[type="text"],
        input[type="email"],
        input[type="search"],
        input[type="password"],
        input[type="number"],
        input[type="tel"],
        input[type="date"],
        input[type="datetime-local"],
        input[type="time"],
        input[type="datetime-local"],
        input[type="month"],
        input[type="week"],
        input[type="url"],
        input[type="file"],
        select {
          border-color: $dark-border-color;
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          &::-webkit-input-placeholder {
            color: $dark-small-font-color;
          }
        }
        .form-control {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
        }
        textarea {
          border-color: $dark-border-color;
        }
      }
      .form-divider {
        border-top: 1px solid $dark-border-color;
        &::before {
          background: $dark-card-background;
          color: $dark-all-font-color;
        }
      }
    }
    .CodeMirror {
      background: $dark-card-background;
      border: 1px solid $dark-small-font-color;
      color: $dark-all-font-color;
      .CodeMirror-code {
        pre {
          background: $dark-card-background;
        }
      }
    }
    .editor-toolbar {
      border-top: 1px solid $dark-small-font-color;
      border-left: 1px solid $dark-small-font-color;
      border-right: 1px solid $dark-small-font-color;
      a {
        color: $dark-all-font-color !important;
        &:hover,
        &.active {
          background: $dark-border-color;
        }
      }
      i.separator {
        border-left: 1px solid $dark-small-font-color;
        border-right: 1px solid $dark-small-font-color;
      }
    }
    .editor-toolbar.disabled-for-preview {
      a {
        &:not(.no-disable) {
          background: $dark-border-color;
        }
      }
    }
    .editor-preview {
      background-color: $dark-card-background;
    }
    .editor-toolbar.fullscreen,
    .editor-preview-side {
      background: $dark-card-background;
      border: 1px solid $dark-small-font-color;
    }
    .u-step {
      background: $dark-border-color;
      &.active,
      &.current {
        background: $primary-color;
        color: $white;
      }
    }
    .u-step-title,
    .u-pearl-title {
      color: $dark-all-font-color;
    }
    .u-step-number {
      background-color: $dark-card-background;
    }
    .u-pearl {
      &:before {
        background-color: $dark-border-color;
      }
    }
    .u-pearl-number,
    .u-pearl-icon {
      background: $dark-body-background;
      border: 2px solid $dark-body-background;
    }
    .u-pearl.disabled {
      .u-pearl-icon,
      .u-pearl-number {
        background: $dark-border-color;
        border: 2px solid $dark-border-color;
      }
      &:after {
        background-color: #334053;
      }
    }
    .u-pearl.error {
      &:after {
        background-color: $dark-body-background;
      }
    }
    .note-editor.note-frame {
      border-color: $dark-border-color;
      .note-editing-area {
        .note-editable {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
        }
      }
    }
    .swal-modal {
      background-color: $dark-card-background;
      .swal-title {
        color: $dark-all-font-color;
      }
      .swal-text {
        color: $dark-small-font-color;
      }
      .swal-content__input {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
      }
    }
    .nav-tabs {
      border-bottom: 1px solid $dark-border-color;
      .nav-link {
        &.active {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border-color: $dark-border-color $dark-border-color
            $dark-card-background;
        }
        &:hover,
        &:focus {
          border-color: $dark-border-color $dark-border-color $dark-border-color;
        }
      }
      .nav-item.show {
        .nav-link {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border-color: $dark-border-color $dark-border-color
            $dark-card-background;
        }
      }
    }
    .nav-tabs.nav-bottom {
      .nav-item {
        .nav-link {
          &.active,
          &.focus,
          &.hover {
            border-color: $dark-card-background $dark-border-color
              $dark-border-color;
          }
        }
      }
    }
    .nav-material.nav-left,
    .nav-material.nav-right {
      .nav-link {
        color: $dark-all-font-color;
        &.active {
          color: $primary-color;
        }
      }
      .show {
        > .nav-link {
          color: $primary-color;
        }
      }
    }
    .nav-material.nav-left.nav-secondary {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          border-left-color: $secondary-color;
          color: $secondary-color !important;
        }
      }
      .show > .nav-link {
        border-left-color: $secondary-color;
        color: $secondary-color !important;
      }
      .nav-item.show {
        color: $secondary-color !important;
        border-left-color: $secondary-color;
      }
    }
    .nav-material.nav-left.nav-success {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $success-color !important;
        }
      }
      .show > .nav-link {
        border-left-color: $success-color;
        color: $success-color !important;
      }
      .nav-item.show {
        color: $success-color !important;
        border-left-color: $success-color;
      }
    }
    .nav-material.nav-right.nav-info {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $info-color !important;
        }
      }
      .show > .nav-link {
        border-left-color: $info-color;
        color: $info-color !important;
      }
      .nav-item.show {
        color: $info-color !important;
        border-left-color: $info-color;
      }
    }
    .nav-material.nav-secondary {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $secondary-color !important;
        }
      }
      .nav-item {
        &.show {
          color: $secondary-color !important;
        }
      }
    }
    .nav-material.nav-success {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $success-color !important;
        }
      }
      .nav-item {
        &.show {
          color: $success-color !important;
        }
      }
    }
    .dropdown-divider {
      border-top: 1px solid $dark-border-color;
    }
    .icon-hover-bottom {
      background-color: $dark-card-background;
      box-shadow: 0px 0px 1px 1px $dark-border-color;
      .icon-title {
        color: $dark-all-font-color;
      }
      span {
        color: $dark-small-font-color;
      }
      .form-group {
        input {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          border: 1px solid $dark-border-color;
        }
      }
      .icon-first {
        i {
          color: $dark-small-font-color;
        }
      }
    }
    code {
      background-color: #d8ecff;
      border-radius: 2px;
    }
    #cd-timeline {
      &::before {
        background: $dark-border-color;
      }
    }
    .timeliny {
      border-top: 1px solid $dark-border-color;
      border-bottom: 1px solid $dark-border-color;
      &::before {
        background-color: $white;
      }
      .timeliny-dot {
        background-color: $dark-card-background;
        border: 1px solid $white;
        &::before {
          color: $dark-all-font-color;
        }
      }
      .timeliny-timeline {
        .timeliny-timeblock {
          &.active {
            .timeliny-dot {
              &::before {
                color: $dark-all-font-color;
              }
            }
          }
          &:not(.inactive):not(.active) {
            .timeliny-dot {
              &:hover {
                background-color: $white;
                &::before {
                  color: $dark-all-font-color;
                }
              }
            }
          }
          .timeliny-dot {
            &:hover {
              &::after {
                background-color: $dark-card-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
    }
    .todo {
      .todo-list-wrapper {
        #todo-list {
          li {
            .task-container {
              background: $dark-card-background;
              border-bottom: 1px solid $dark-border-color;
              .task-label {
                color: $dark-all-font-color;
              }
              &:hover {
                h4 {
                  color: $white;
                }
              }
              .task-action-btn {
                .action-box {
                  &:hover:not(.active) {
                    background: $dark-card-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
            &.completed {
              .task-container {
                .task-label {
                  color: $primary-color;
                }
                .complete-btn {
                  &:hover {
                    border: 1px solid $success-color !important;
                  }
                }
              }
            }
          }
        }
        .todo-list-footer {
          .new-task-wrapper {
            textarea {
              background-color: $dark-card-background;
              border: 1px solid $dark-border-color;
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    .user-profile {
      .ttl-info {
        h6 {
          color: $dark-small-font-color;
        }
        span {
          color: $dark-all-font-color;
        }
      }
      .hovercard {
        .info {
          .title {
            a {
              color: $dark-all-font-color;
            }
          }
        }
        .user-image {
          .avatar {
            img {
              border: 10px solid $dark-card-background;
            }
          }
          .icon-wrapper {
            background-color: $dark-card-background;
          }
        }
        .tabs-scoial {
          border-bottom: none !important;
        }
      }
      .follow {
        .follow-num {
          color: $dark-all-font-color;
        }
      }
      .profile-img-style {
        .user-name {
          color: $dark-all-font-color;
        }
        p {
          color: $dark-small-font-color;
        }
      }
    }
    .introjs-helperLayer {
      background-color: rgba(54, 64, 74, 0.9);
      border: rgba(255, 255, 255, 0.5);
    }
    .jstree-default {
      .jstree-node,
      .jstree-icon {
        color: #455869 !important;
      }
      .jstree-anchor {
        color: $dark-small-font-color;
      }
      .jstree-clicked {
        color: $white;
        background-color: transparent;
      }
      .jstree-hovered {
        background-color: $dark-body-background;
        border: 1px solid $dark-border-color;
      }
    }
    span.twitter-typeahead {
      .tt-menu {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
      }
      .tt-suggestion {
        color: $dark-all-font-color;
        &:hover,
        &:focus {
          background-color: $dark-card-background;
        }
      }
    }
    .typography {
      small {
        color: $dark-all-font-color;
      }
    }
    .blockquote-footer {
      color: $dark-small-font-color;
    }
    .code-box-copy {
      pre {
        background-color: $dark-body-background;
        code {
          background-color: $dark-body-background;
        }
      }
      pre[class*="language-"] {
        border: 1px solid $dark-border-color;
      }
      .code-box-copy__btn {
        background-color: $dark-body-background;
        border: 1px solid $dark-border-color;
        color: $white;
      }
      code[class*="language-"],
      pre[class*="language-"] {
        text-shadow: 0px 1px $black;
        ::selection {
          text-shadow: none;
          background: $dark-card-background;
        }
      }
    }
    table.fixedHeader-floating {
      background-color: $dark-body-background;
    }
    .dt-button-info {
      background-color: $dark-card-background;
      border: 1px solid $dark-border-color;
      h2 {
        background-color: $dark-card-background;
        border-bottom: 1px solid $dark-border-color;
      }
    }
    #keytable_wrapper,
    #column-selector_wrapper,
    #auto-fill_wrapper,
    #custom-button_wrapper,
    #class-button_wrapper,
    #keyboard-btn_wrapper,
    #multilevel-btn_wrapper,
    #pagelength-btn_wrapper,
    #basic-colreorder_wrapper,
    #state-saving_wrapper,
    #real-time_wrapper,
    #basic-fixed-header_wrapper,
    #fixed-header-footer_wrapper,
    #export-button_wrapper,
    #excel-cust-bolder_wrapper,
    #cust-json_wrapper,
    #basic-key-table_wrapper,
    #focus-cell_wrapper,
    #responsive_wrapper,
    #new-cons_wrapper,
    #show-hidden-row_wrapper,
    #basic-row-reorder_wrapper,
    #full-row_wrapper,
    #rest-column_wrapper {
      .dataTables_paginate {
        border: none;
      }
    }
    #example-style-8_wrapper {
      table.dataTable.stripe,
      table.dataTable.display {
        tbody {
          tr.odd {
            background-color: $dark-border-color;
          }
        }
      }
    }
    .error-wrapper {
      .sub-content {
        color: $dark-all-font-color;
      }
    }
    .b-light {
      border: 1px solid $dark-border-color !important;
    }
    .modal-content {
      background-color: $dark-card-background;
      .modal-header {
        border-bottom: 1px solid $dark-border-color;
      }
      .ui-front {
        .form-control {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border: 1px solid $dark-border-color;
        }
      }
    }
    .stepwizard {
      .stepwizard-row {
        &:before {
          background-color: $dark-small-font-color;
        }
      }
    }
    .modal {
      .theme-close {
        background-color: $dark-card-background !important;
      }
    }
    .token.atrule,
    .token.attr-value,
    .token.keyword {
      color: $info-color;
    }
    .token {
      &.boolean,
      &.constant,
      &.deleted,
      &.number,
      &.property,
      &.symbol,
      &.tag {
        color: $danger-color;
      }
    }
    .loader-box {
      &.card-loader {
        background-color: $dark-card-background;
      }
    }
    .my-gallery {
      &.gallery-with-description {
        img {
          border: 1px solid $dark-border-color !important;
          border-bottom: none !important;
        }
      }
    }
    @each $var in $alert-name {
      $i: index($alert-name, $var);
      .alert-#{$var}.inverse {
        color: $dark-all-font-color;
      }
    }
    .alert-theme {
      span {
        + span {
          + span {
            border: 1px solid $primary-color;
            background-color: $dark-body-background;
            border-radius: 4px;
          }
        }
      }
      i {
        background-color: $primary-color;
        color: $white;
        padding: 20px;
        margin-right: 20px !important;
      }
    }
    .user-card {
      .user-deatils {
        h6 {
          color: $dark-small-font-color;
        }
      }
      .card-footer {
        > div {
          &:first-child {
            border-right: 1px solid $dark-border-color;
          }
        }
        .user-footer {
          h6 {
            color: $dark-small-font-color;
          }
          svg {
            path,
            rect {
              color: $dark-small-font-color;
            }
          }
        }
      }
    }
    .dashboard-chat {
      .chat {
        .media {
          .media-body {
            .message-main {
              p {
                background-color: $dark-body-background;
              }
              &.smiley-bg {
                background-color: $dark-body-background;
              }
            }
          }
        }
        .right-side-chat {
          .media-body {
            p {
              &:before {
                border-left: 7px solid $dark-body-background;
              }
            }
          }
        }
        .left-side-chat {
          .media-body {
            p {
              &:before {
                border-right: 7px solid $dark-body-background;
              }
            }
          }
        }
      }
    }
    .selling-update {
      border: 2px solid $dark-border-color;
    }
    .bitcoin-accordion {
      .card {
        .media-accordion {
          .media:nth-child(2) {
            background-color: #1c222b;
          }
          .media {
            .media-body {
              p {
                color: $dark-small-font-color;
              }
            }
            + .media {
              border-top: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    .invest-dropdown {
      .right-header-dropdown {
        &.onhover-show-div {
          background-color: $dark-body-background;
          box-shadow: 0 0 2px 2px $dark-body-background;
          a {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .server-card-bg {
      background-color: #181f27 !important;
    }
    .server-widgets {
      .bottom-server {
        h5 {
          .second-color {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .select2-drpdwn-project {
      .form-control {
        background-color: $dark-card-background !important;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
      }
    }
    .tabs-project {
      .nav-item {
        .nav-link {
          color: $dark-all-font-color;
        }
      }
    }
    .current-progress,
    .order-history {
      table {
        tr {
          td,
          th {
            border-top: none !important;
          }
        }
      }
      .title-orders {
        background-color: $dark-body-background;
      }
    }
    .navigation-option {
      ul {
        li {
          a {
            color: $dark-all-font-color;
          }
          &:hover {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .product-box {
      .product-details {
        h6 {
          color: $dark-all-font-color;
        }
        span {
          color: $dark-small-font-color;
        }
      }
      .product-img {
        .product-hover {
          ul {
            li {
              background-color: $dark-card-background;
              &:hover {
                background-color: $black;
                color: $white;
              }
            }
          }
        }
      }
    }
    .browse {
      .browse-articles {
        ul {
          li {
            a {
              color: $dark-all-font-color;
            }
            &:hover {
              background-color: $dark-body-background;
            }
          }
        }
      }
    }
    @each $btn-name, $btn-color in (primary, $primary-color),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .radio-#{$btn-name} {
        input[type="radio"] {
          & + label {
            &::before {
              border-color: $btn-color !important;
            }
            &::after {
              background-color: $btn-color;
            }
          }
          &:checked {
            & + label {
              &::before {
                border-color: $btn-color !important;
              }
              &::after {
                background-color: $btn-color;
              }
            }
          }
        }
      }
    }

    @each $btn-name, $btn-color in (primary, $primary-color),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .checkbox-#{$btn-name} {
        label {
          &::before {
            border-color: $btn-color !important;
          }
        }
      }
    }

    @each $btn-name, $btn-color in (primary, $primary-color),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .checkbox-solid-#{$btn-name} {
        label {
          &:before {
            background-color: $btn-color !important;
            border-color: $btn-color !important;
            color: $white !important;
          }
        }
        input[type="checkbox"] {
          &:checked {
            & + label {
              &::before {
                background-color: $btn-color !important;
                border-color: $btn-color !important;
                color: $white !important;
              }
              &::after {
                color: $white !important;
              }
            }
          }
        }
      }
    }
    .bootstrap-datetimepicker-widget {
      ul {
        li {
          color: $dark-all-font-color;
          border-bottom: 1px solid $dark-border-color;
        }
      }
      tr {
        th,
        td {
          &:hover {
            background-color: $dark-card-background;
          }
          span {
            &:hover {
              background-color: $dark-card-background;
            }
          }
          &.prev,
          &.next {
            &:hover {
              background-color: $dark-card-background;
            }
          }
        }
        &:first-child {
          th {
            &:hover {
              background-color: $dark-card-background;
            }
          }
        }
      }
    }
    .btn-transparent {
      color: $dark-all-font-color;
    }
    #cal-basic {
      .fc-toolbar {
        .fc-left,
        .fc-right {
          .fc-next-button {
            .fc-icon-right-single-arrow {
              &:after {
                color: $dark-small-font-color;
              }
            }
          }
          .fc-prev-button {
            .fc-icon-left-single-arrow {
              &:after {
                color: $dark-small-font-color;
              }
            }
          }
        }
        .fc-left {
          .fc-button-group {
            .fc-month-button {
              color: $white !important;
            }
          }
        }
      }
    }
    .fc-button-group {
      .fc-basicWeek-button,
      .fc-basicDay-button,
      .fc-agendaWeek-button,
      .fc-agendaDay-button {
        color: $black !important;
      }
      .fc-month-button {
        color: $white !important;
      }
    }
    #cal-agenda-view {
      .fc-toolbar {
        .fc-right {
          .fc-button-group {
            .fc-month-button,
            .btn-light {
              color: $black !important;
            }
            .fc-agendaWeek-button {
              color: $white !important;
              &:hover {
                color: $black !important;
              }
            }
          }
        }
      }
    }
    .basic-calendar,
    #cal-bg-events,
    #cal-event-colors {
      .fc-toolbar {
        .fc-right {
          .fc-button-group {
            .fc-basicWeek-button,
            .btn-light {
              color: $black !important;
            }
            .fc-month-button {
              color: $white !important;
              &:hover {
                color: $black !important;
              }
            }
          }
        }
      }
    }
    .categories {
      ul {
        li {
          a {
            color: $dark-small-font-color;
          }
          &:hover {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .main {
      .langChoice {
        background: $dark-body-background;
        color: $white;
        border: 1px solid $dark-border-color;
      }
    }
    .socialprofile {
      .likes-profile {
        h5 {
          span {
            color: $dark-all-font-color;
          }
        }
      }
      span {
        color: $dark-small-font-color;
      }
    }
    .social-status {
      .media {
        .media-body {
          span {
            + span {
              color: $dark-small-font-color;
            }
          }
          p,
          .light-span {
            color: $dark-small-font-color;
          }
        }
      }
      form {
        .form-group {
          .form-control-plaintext {
            border: 1px solid $dark-border-color;
          }
        }
      }
    }
    .filter-cards-view,
    .timeline-content {
      p {
        color: $dark-small-font-color;
      }
      .comment-number {
        i {
          color: $dark-small-font-color;
        }
      }
      .comments-box {
        .input-group {
          .form-control {
            border: none !important;
          }
          .input-group-append {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .social-chat {
      .media-body {
        border: 1px solid $dark-border-color;
        &:after {
          border-right: 7px solid $dark-card-background;
        }
      }
      span {
        span {
          color: $dark-small-font-color;
        }
      }
    }
    .details-about {
      .your-details {
        p {
          color: $dark-small-font-color;
        }
      }
    }
    .activity-log {
      .my-activity {
        p {
          color: $dark-small-font-color;
        }
      }
    }
    &.rtl {
      .page-main-header {
        .main-header-right {
          .nav-right {
            > ul {
              > li {
                border-right: none;
                &:nth-child(2) {
                  border-right: 1px solid $dark-border-color;
                }
                &:nth-child(5) {
                  border-right: none;
                  border-left: 1px solid $dark-border-color;
                }
                &:nth-child(4) {
                  border-left: none;
                }
              }
            }
          }
        }
      }
    }
    .bookmark {
      ul {
        li {
          a {
            color: $dark-all-font-color;
          }
        }
      }
    }
  }
  &.dark-header-sidebar-mix {
    color: $dark-all-font-color;
    background-color: $dark-body-background;
    .page-wrapper {
      /* Main Header start */
      .page-main-header {
        background-color: $dark-card-background;
        .main-header-right {
          background-color: $dark-card-background;
          li {
            i {
              color: $light-color;
            }
          }
          .nav-right {
            > ul {
              > li {
                border-left: 1px solid $dark-border-color;
                &:last-child,
                &:nth-child(5),
                &:nth-child(1) {
                  border-left: none;
                }
                &:nth-child(5) {
                  border-right: 1px solid $dark-border-color;
                }
                &:first-child {
                  .search-form {
                    .form-group {
                      &:before {
                        background: $dark-border-color;
                      }
                    }
                  }
                }
              }
            }
            .nav-menus {
              li {
                .media {
                  .media-body {
                    .txt-dark {
                      color: $dark-all-font-color !important;
                    }
                  }
                }
                .onhover-show-div {
                  background-color: $dark-body-background;
                  box-shadow: 0 0 2px 2px $dark-card-background;
                  &:before {
                    border-bottom: 7px solid $dark-body-background;
                  }
                  &:after {
                    border-bottom: 7px solid $dark-body-background;
                  }
                }
                .notification-dropdown.onhover-show-div {
                  li {
                    border-bottom: 1px solid $dark-border-color;
                    span {
                      color: $dark-small-font-color;
                    }
                    &:hover {
                      background-color: #282e38;
                    }
                  }
                  .bg-light {
                    background-color: #282e38 !important;
                    color: $dark-all-font-color !important;
                  }
                }
                .language-dropdown {
                  li {
                    a {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
            }
            .profile-dropdown {
              li {
                a {
                  color: $dark-all-font-color;
                  svg {
                    color: $dark-all-font-color !important;
                    path,
                    line,
                    circle {
                      color: $dark-all-font-color !important;
                    }
                  }
                  &:hover {
                    color: $primary-color;
                    svg {
                      color: $primary-color !important;
                      line,
                      path,
                      circle {
                        color: $primary-color !important;
                      }
                    }
                  }
                }
              }
            }
            .search-form {
              input {
                background-color: $dark-body-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
            }
            > ul {
              > li {
                .media {
                  .media-body {
                    .txt-dark {
                      color: $dark-all-font-color !important;
                    }
                  }
                }
                .onhover-show-div {
                  background-color: $dark-body-background;
                  box-shadow: 0 0 2px 2px $dark-card-background;
                  &:before {
                    border-bottom: 7px solid $dark-body-background;
                  }
                  &:after {
                    border-bottom: 7px solid $dark-body-background;
                  }
                }
                .notification-dropdown.onhover-show-div {
                  li {
                    border-bottom: 1px solid $dark-border-color;
                    span {
                      color: $dark-small-font-color;
                    }
                  }
                }
                .language-dropdown {
                  li {
                    a {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
            }
          }
          .mega-menu-header {
            .vertical-menu-main {
              .main-nav {
                .main-menu {
                  li {
                    background-color: $dark-card-background;
                  }
                }
              }
            }
          }
        }
        .main-header-left {
          background-color: $dark-card-background;
          .logo-wrapper {
            a {
              .image-dark {
                display: none;
              }
              .image-light {
                display: block;
              }
            }
          }
        }
        .Typeahead-menu {
          background-color: $dark-body-background;
        }
        .ProfileCard {
          &:hover {
            background: $dark-card-background;
          }
        }
        .ProfileCard-realName {
          a,
          span {
            color: $dark-small-font-color !important;
          }
        }
        @media only screen and (max-width: 991px) {
          .main-header-right {
            .nav-right {
              > ul {
                background-color: $dark-card-background;
                box-shadow: 0 2px 2px 2px $dark-border-color;
              }
            }
          }
        }
        @media only screen and (max-width: 575px) {
          .nav-right {
            > ul {
              .search-form {
                .form-control-plaintext {
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                  color: $dark-all-font-color;
                }
              }
            }
          }
        }
      }
      .right-sidebar {
        background-color: $dark-body-background;
        box-shadow: 0 0 11px rgba(69, 110, 243, 0.13);
        .modal-header {
          border-bottom: 1px solid $dark-border-color;
        }
        .friend-list-search {
          background-color: $dark-card-background;
          input {
            background-color: $dark-body-background;
            border: 1px solid $dark-border-color;
          }
        }
        .chat-box {
          .about {
            .name {
              color: $dark-all-font-color;
            }
          }
        }
      }
      /* Main Header ends */
    }
    .page-body-wrapper {
      .page-body {
        color: #313131;
      }
    }
  }
  &.dark-sidebar-body-mix {
    color: $dark-all-font-color;
    background-color: $dark-body-background;
    .custom-datatable {
      table {
        border: 1px solid $dark-border-color;
      }
    }
    .ecommerce-widget {
      border: 1px solid $dark-border-color;
    }
    .config-popup {
      border-bottom: none !important;
    }
    .modal-copy-header {
      h5 {
        color: $dark-all-font-color;
      }
      .btn-close {
        color: $dark-all-font-color;
      }
    }
    .browse {
      .browse-articles {
        h6 {
          border-bottom: 1px solid $dark-border-color;
        }
      }
    }
    .search-knowledge {
      input {
        background-color: $dark-card-background;
        border: 1px solid $dark-border-color;
        color: $dark-small-font-color;
      }
    }
    .btn-outline-light {
      color: $dark-all-font-color;
    }
    .product-wrapper {
      &.sidebaron {
        .product-sidebar {
          .filter-section {
            .card {
              .left-filter {
                background-color: $dark-card-background;
              }
            }
          }
        }
      }
    }
    .search-page {
      .nav-tabs {
        border-bottom: none !important;
        ngb-tabset {
          .nav-tabs {
            border-bottom: 1px solid $dark-border-color !important;
          }
        }
      }
      ul {
        &.search-info {
          li {
            color: inherit;
            + li {
              border-left: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    .cal-month-view {
      background-color: $dark-card-background;
      .cal-cell-row {
        &:hover {
          background-color: $dark-body-background;
        }
        .cal-cell:hover,
        .cal-cell.cal-has-events.cal-open {
          background-color: $dark-border-color;
        }
      }
      .cal-days {
        border-color: $dark-border-color;
        .cal-cell-row {
          border-bottom-color: $dark-border-color;
        }
      }
      .cal-day-cell {
        &:not(:last-child) {
          border-right-color: $dark-border-color;
        }
      }
    }
    .contact-profile {
      .icon-wrapper {
        background-color: $dark-card-background;
      }
    }
    .my-app-custom-plain-container-with-desc {
      figure {
        img,
        .caption {
          border: 1px solid $dark-border-color !important;
        }
        .caption {
          border-top: none !important;
        }
      }
    }
    .modal-gallery-wrapper {
      a {
        > div {
          border: none !important;
        }
      }
    }
    .custom-mesonry,
    .mega-inline,
    .mega-horizontal {
      img,
      .card {
        border: 1px solid $dark-border-color;
      }
    }
    .custom-datatable {
      input {
        border: 1px solid $dark-border-color;
        color: $light-text;
        &::placeholder {
          color: $light-text;
        }
      }
      .ngx-datatable {
        &.bootstrap {
          .datatable-header {
            border: 1px solid $dark-border-color;
            border-bottom: none;
            .datatable-header-cell {
              border-bottom: none;
            }
          }
          .datatable-body {
            border: 1px solid $dark-border-color;
            border-top: none;
            .datatable-selection {
              .datatable-scroll {
                .datatable-row-wrapper {
                  &:last-child {
                    border-bottom: none;
                  }
                }
              }
            }

            .datatable-body-row {
              border-top: 1px solid $dark-border-color;
              &.active {
                background-color: $primary-color;
              }
              &.datatable-row-even {
                background-color: $dark-body-background;
                &:hover {
                  background-color: #28303c;
                }
                &.active {
                  background-color: $primary-color;
                }
              }
              &.datatable-row-odd {
                &:hover {
                  background-color: #28303c;
                }
              }
            }
          }
          .datatable-footer {
            background: $dark-card-background;
            .datatable-pager {
              ul {
                li {
                  &:not(.disabled).active {
                    a {
                      background-color: $primary-color;
                    }
                  }
                  &:not(.disabled):hover {
                    a {
                      border: 1px solid $primary-color;
                    }
                  }
                }
              }
            }
          }
        }
        .datatable-footer {
          .datatable-pager {
            .pager {
              border: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    .timeline-content {
      background: $dark-body-background;
      &::after {
        border-color: transparent transparent transparent $dark-body-background;
      }
    }
    .timeline-item {
      &:nth-child(even) {
        .timeline-content {
          &::after {
            border-color: transparent $dark-body-background transparent
              transparent;
          }
        }
      }
    }
    #demo3,
    #demo4 {
      background-color: $dark-card-background;
    }
    .my-drop-zone {
      background-color: $dark-border-color;
      border: dotted 3px $dark-body-background;
    }
    .upload-input,
    .upload-table {
      input,
      table {
        border: 1px solid $dark-border-color;
      }
    }
    .swal2-popup {
      background: $dark-card-background;
      &.text-dark {
        .swal2-title {
          color: $theme-body-font-color;
        }
      }
      .swal2-title {
        color: $dark-all-font-color;
      }
      .swal2-content {
        color: $dark-small-font-color;
      }
      .swal2-footer {
        border-top: 1px solid $dark-border-color;
      }
      .swal2-styled {
        &:focus {
          box-shadow: 0 0 0 2px $dark-card-background,
            0 0 0 4px rgba(50, 100, 150, 0.4);
        }
      }
      .swal2-file:focus,
      .swal2-input:focus,
      .swal2-textarea {
        color: $dark-all-font-color;
        &:focus {
          border: 1px solid $dark-border-color;
          box-shadow: 0 0 3px $dark-card-background;
        }
      }
    }
    .drag-n-drop {
      &.parent {
        background-color: rgba($dark-card-background, 0.2);
      }
    }
    .cropper {
      outline-color: rgba($dark-card-background, 0.3) !important;
    }
    .custom-datepicker,
    .mutliple-datepicker {
      .ngb-dp-navigation-select {
        .form-select {
          background-color: $white !important;
        }
      }
      .form-select,
      .custom-file-label {
        color: $theme-body-font-color !important;
      }
      .form-select {
        &:disabled {
          color: #6c757d !important;
          background-color: #e9ecef !important;
        }
      }
    }
    .mutliple-datepicker,
    .custom-datepicker-dropdown {
      .custom-day {
        color: $theme-body-font-color;
        &.range,
        &:hover {
          color: $white;
        }
      }
      ngb-datepicker,
      .dropdown-menu {
        background-color: $white !important;
        border: none !important;
      }
      .align-content-between {
        .form-select {
          color: $dark-all-font-color !important;
          border: 1px solid $dark-border-color !important;
        }
      }
      hr {
        border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
      }
    }
    .dropdown-item {
      color: $dark-small-font-color;
      &:hover,
      &:focus {
        background-color: $dark-card-background;
      }
    }
    .modal-footer {
      border-top: 1px solid $dark-border-color;
    }
    .accordion {
      > .card {
        .card-header {
          .btn-link {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .vertical-tab {
      .nav-tabs {
        .nav-item {
          border-color: $dark-border-color;
          .nav-link {
            color: $dark-all-font-color;
            border-color: $dark-border-color;
            &.active {
              background-color: $primary-color;
            }
          }
          &:hover {
            background-color: $dark-body-background;
          }
        }
      }
    }
    #project {
      .nav-tabs {
        .nav-link {
          color: $dark-all-font-color;
          &.active {
            color: $primary-color;
          }
        }
      }
    }
    .server-datatable {
      .table-responsive {
        table {
          border: 1px solid $dark-border-color;
          tr {
            border-top: 1px solid $dark-border-color;
            &:nth-child(even) {
              background-color: #293240;
              td,
              th {
                &:first-child {
                  background-color: #28303c;
                }
              }
              &:hover {
                background-color: #28303c;
              }
            }
            &:nth-child(odd) {
              td,
              th {
                &:first-child {
                  background-color: #2e3a4c;
                }
              }
              &:hover {
                background-color: #28303c;
                td,
                th {
                  &:first-child {
                    background-color: #282f3a;
                  }
                }
              }
            }
          }
        }
      }
    }
    .bit-coin-tab {
      .nav-tabs {
        .nav-link {
          &.active {
            background-color: $primary-color;
          }
        }
      }
    }
    .offcanvas-bookmark {
      &.page-wrapper {
        .page-body-wrapper {
          .page-body {
            .bookmark {
              ul {
                li {
                  form {
                    .form-control-search {
                      .Typeahead-menu {
                        .ProfileCard {
                          .ProfileCard-details {
                            .ProfileCard-realName {
                              a {
                                color: $dark-small-font-color !important;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .bookmark {
      ul {
        li {
          a {
            color: $dark-small-font-color !important;
          }
          .search-form {
            .form-control-search {
              input {
                background-color: $dark-body-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
              &:before {
                background: $dark-border-color;
              }
            }
          }
          .Typeahead-menu {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .custom-datepicker {
      .form-select,
      .custom-file-label {
        background: $white !important;
        color: $theme-body-font-color !important;
      }
    }
    .cart {
      .qty-box {
        .input-group {
          .btn {
            background-color: $dark-border-color !important;
            border-color: $dark-border-color !important;
          }
        }
      }
    }
    .checkout {
      .checkout-details {
        background-color: $dark-body-background;
        border: 1px solid $dark-border-color;
        padding: 40px;
      }
    }
    .order-box {
      .title-box {
        color: #bfc2c6;
        border-bottom: 1px solid $dark-border-color;
      }
      .qty {
        li {
          color: #bfc2c6;
          span {
            color: #bfc2c6;
          }
        }
        border-bottom: 1px solid $dark-border-color;
      }
      .sub-total {
        li {
          color: #bfc2c6;
        }
      }
      .total {
        li {
          color: #bfc2c6;
        }
      }
    }
    .shopping-checkout-option {
      .checkbox_animated {
        &:after {
          border: 2px solid $dark-border-color;
        }
      }
    }
    .animate-chk {
      .radio_animated {
        &:after {
          border: 2px solid $dark-border-color;
        }
      }
    }

    .radio_animated,
    .checkbox_animated {
      &:after {
        background: $dark-body-background;
        border: 2px solid $dark-body-background;
      }
    }
    .product-box {
      .product-img {
        .product-hover {
          ul {
            li {
              .btn {
                color: $dark-small-font-color;
              }
              &:hover {
                .btn {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
    .slider-product {
      border-top: 1px solid $dark-border-color;
      border-bottom: 1px solid $dark-border-color;
    }
    .square-product-setting {
      .icon-grid {
        background-color: $dark-card-background;
        svg {
          color: $dark-all-font-color;
        }
      }
    }
    .bitcoin-form {
      .bitcoin-form-dropdown {
        .onhover-dropdown {
          .btn {
            background-color: $dark-border-color;
            color: $dark-all-font-color;
          }
          .onhover-show-div {
            background-color: $dark-body-background;
            box-shadow: 0 0 2px 2px $dark-body-background;
          }
        }
      }
      .row {
        .input-group {
          .input-group-prepend {
            .input-group-text {
              border: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    .active-order-table,
    .market-table {
      table {
        thead {
          tr {
            th {
              border-top: none !important;
            }
          }
        }
        tbody {
          tr {
            td {
              border-bottom: 1px solid $dark-border-color;
            }
            &:last-child {
              td {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
    .pricing-card-design-2 {
      .pricing-block {
        .pricing-inner {
          ul {
            li {
              border-bottom: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    .flot-chart-container {
      .legend {
        .table {
          tbody {
            background-color: $dark-card-background;
            color: $white;
            .legendLabel {
              padding-left: 5px;
            }
          }
        }
      }
    }
    .nav-dark,
    .nav-pills.nav-dark {
      .nav-link {
        color: $white;
      }
    }
    .page-wrapper {
      /* main-header start*/
      .page-main-header {
        color: $black;
        .default-according {
          .card {
            .btn-link {
              background-color: $white;
              border: 1px solid $light-color;
              color: $black;
            }
          }
        }
        .collapse {
          .card-body {
            background-color: $white;
          }
        }
        .theme-form {
          .form-group {
            input[type="text"],
            input[type="email"],
            textarea {
              border-color: $light-color;
              background-color: $white;
              color: $black;
            }
            label {
              color: $black;
            }
          }
        }
        #accordionoc {
          #collapseicon,
          #collapseicon1 {
            background-color: $white;
          }
        }
        @media only screen and (max-width: 575px) {
          .nav-right {
            > ul {
              .search-form {
                .form-control-plaintext {
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                  color: $dark-all-font-color;
                }
              }
            }
          }
        }
      }
      .right-sidebar {
        background-color: $dark-body-background;
        box-shadow: 0 0 11px rgba(69, 110, 243, 0.13);
        .modal-header {
          border-bottom: 1px solid $dark-border-color;
        }
        .friend-list-search {
          background-color: $dark-card-background;
          input {
            background-color: $dark-body-background;
            border: 1px solid $dark-border-color;
          }
        }
      }
      .page-body-wrapper {
        /* sidebar start */
        &.sidebar-icon {
          .page-sidebar {
            .sidebar-menu {
              .sidebar-header {
                > i {
                  color: $dark-all-font-color;
                }
              }
              li {
                background-color: $dark-card-background;
                &:hover {
                  .single-header,
                  .sidebar-submenu {
                    background-color: $dark-card-background;
                  }
                }
                a:hover + ul {
                  background-color: $dark-card-background;
                }
              }
            }
            .sidebar-user {
              box-shadow: 3px 2px 7px -1px rgb(41, 50, 64);
            }
          }
        }
        .page-sidebar {
          background: $dark-card-background;
          .main-header-left {
            background-color: $white;
          }
          &.native-image-bg {
            background-blend-mode: overlay;
            background-color: #17191d;
          }
          &.navigation-bordered {
            .sidebar-header {
              border-bottom: 1px solid rgba(241, 241, 241, 0.35);
            }
          }
          .sidebar-user {
            background-color: $dark-card-background;
            box-shadow: 3px 2px 8px -1px rgba(255, 255, 255, 0.13);
            h6 {
              color: $light-color;
            }
            p {
              color: $white;
            }
          }
          .sidebar-menu {
            .sidebar-title {
              color: $primary-color;
              border-bottom: 1px solid rgba(241, 241, 241, 0.35);
            }
            > li {
              > a {
                color: $dark-all-font-color;
                &.active {
                  color: $white;
                }
                &:hover {
                  color: $white;
                }
              }
              .label {
                margin-top: 3px;
                margin-right: 5px;
              }
              &:hover > a {
                @extend %common;
                color: $dark-all-font-color;
              }
            }
            li {
              &.sidebar-header {
                color: $warning-color;
              }
            }
            a {
              color: $light-color;
            }
            .sidebar-submenu {
              > li {
                > a {
                  color: $sidebar-submenu-font-color;
                  &:hover {
                    color: $primary-color;
                  }
                  &.active {
                    color: $primary-color;
                  }
                }
              }
            }
          }
          .sidebar-widget {
            border-top: 1px solid $dark-border-color;
            .sidebar-widget-top {
              i {
                background-color: $dark-card-background;
              }
            }
            .sidebar-widget-bottom {
              background-color: $dark-body-background;
            }
          }
        }
        /* sidebar end */
        .page-body {
          .topper-lists {
            table {
              tbody {
                tr {
                  td {
                    p {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
            }
          }
          .header-small {
            color: $dark-small-font-color;
          }
          .statistics {
            p {
              color: $dark-small-font-color;
            }
          }
          .feather-main,
          .professor-table {
            .media-body,
            .professor-block {
              p {
                color: $dark-small-font-color;
              }
            }
          }
          .logs-element {
            span {
              + span {
                color: $dark-small-font-color;
              }
            }
          }
          .progress-block {
            .progress-title {
              span {
                + span {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .ecommerce-icons {
            div {
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .new-users,
          .recent-notification {
            .d-flex {
              .flex-grow-1 {
                p {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .progress-media {
            .media {
              .media-body {
                span {
                  color: $dark-small-font-color;
                }
              }
            }
            .progress-change {
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .topper-lists {
            table {
              tbody {
                tr {
                  td {
                    border-top: none !important;
                    border-bottom: 1px solid $dark-border-color;
                  }
                  &:last-child {
                    td {
                      border-bottom: none;
                    }
                  }
                }
              }
            }
          }
          .notifiaction-media {
            .media {
              .media-body {
                .circle-left {
                  border: 5px solid #334154;
                }
              }
            }
          }
          .upcoming-event {
            .upcoming-innner {
              border-bottom: 1px solid $dark-border-color;
              &:last-child {
                border-bottom: none;
              }
            }
          }
          .professor-table {
            table {
              tbody {
                tr {
                  td {
                    border-top: none !important;
                  }
                }
              }
            }
          }
          .number-widgets {
            .media {
              .media-body {
                h6 {
                  color: $dark-all-font-color;
                }
              }
            }
          }
          .activity {
            .media {
              .gradient-round {
                &.gradient-line-1,
                &.small-line,
                &.medium-line {
                  &:after {
                    background-color: $dark-border-color;
                  }
                }
              }
              .media-body {
                h6 {
                  span {
                    color: $dark-small-font-color;
                  }
                }
              }
            }
          }
          .card.custom-card {
            .card-header {
              border-bottom: none !important;
            }
          }
        }
        .custom-card .card-header img {
          background-color: $black;
          opacity: 0.8;
        }
        /* body start*/
        .page-header {
          .row {
            h3 {
              small {
                color: $dark-small-font-color;
              }
            }
          }
          .breadcrumb-item {
            &.active {
              color: $dark-small-font-color;
            }
          }
        }
        .page-body {
          background-color: $dark-body-background;
          .card {
            background-color: $dark-card-background;
            box-shadow: 0 0 1px 1px $dark-border-color;
            .card-header {
              background-color: $dark-card-background;
              border-bottom: 1px solid $dark-border-color;
              .buttons-right {
                .right-header-dropdown.onhover-show-div {
                  background-color: $dark-body-background;
                  box-shadow: 0 0 2px 2px $dark-body-background;
                  a {
                    color: $dark-all-font-color;
                  }
                }
              }
              &.card-header-border {
                .right-header {
                  span {
                    &:first-child {
                      color: $dark-small-font-color;
                    }
                  }
                  .right-header-dropdown.onhover-show-div {
                    background-color: $dark-body-background;
                    box-shadow: 0 0 2px 2px $dark-body-background;
                    a {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
              > span {
                color: $dark-all-font-color;
              }
              h1,
              h2,
              h3,
              h4,
              h5,
              h6 {
                color: $dark-all-font-color;
              }
              .card-header-right {
                i {
                  color: $dark-all-font-color;
                }
              }
            }
            .alert-dark {
              color: $dark-small-font-color;
              a {
                color: $dark-small-font-color;
              }
            }
            .alert {
              &.alert-custom {
                border-color: $dark-border-color;
              }
            }
            .alert-light.outline,
            .alert-light.outline-2x,
            .alert-light.inverse {
              color: $dark-all-font-color;
            }
            #animation-box {
              .animate-widget {
                p {
                  color: $dark-small-font-color !important;
                }
              }
            }
            .grid-item {
              img {
                background-color: $dark-card-background;
                border: 1px solid $dark-border-color;
              }
            }
            .line {
              color: $dark-all-font-color;
            }
            .table {
              th,
              td {
                color: $dark-all-font-color;
              }
              thead {
                th {
                  border-bottom: 2px solid $dark-border-color;
                }
                .border-bottom-primary {
                  th {
                    border-bottom: 1px solid $primary-color;
                  }
                }
              }
              .table-primary,
              .table-secondary,
              .table-success,
              .table-info,
              .table-warning,
              .table-light,
              .table-danger {
                th,
                td {
                  color: $sidebar-submenu-font-color;
                }
              }
              .bg-primary,
              .bg-secondary,
              .bg-success,
              .bg-info,
              .bg-warning,
              .bg-danger {
                color: $white;
              }
              .bg-light {
                color: $black;
              }
              .thead-light {
                th {
                  color: $black;
                }
              }
              .double,
              .dotted,
              .dashed {
                border-color: $dark-border-color;
              }
              tbody {
                .border-bottom-primary {
                  th,
                  td {
                    border-bottom: 1px solid $primary-color;
                  }
                }
              }
            }
            .table-responsive {
              .table {
                th,
                td {
                  border-top: 1px solid $dark-border-color;
                }
              }
            }
            .table[class*="bg-"] {
              th,
              td {
                color: $white;
              }
            }
            .table-striped {
              tbody {
                tr {
                  &:nth-of-type(odd) {
                    background-color: rgba(0, 0, 0, 0.05);
                    &:hover {
                      th,
                      td {
                        color: $white;
                      }
                    }
                  }
                }
              }
            }
            .table-bordered {
              td,
              th {
                border-color: $dark-border-color;
              }
            }
            .table-inverse {
              tbody {
                tr {
                  &:nth-of-type(odd) {
                    &:hover {
                      td {
                        color: $dark-all-font-color;
                      }
                    }
                  }
                }
              }
            }
            .table-border-vertical {
              tr,
              th,
              td {
                border-right: 1px solid $dark-border-color;
              }
            }
            .table-styling {
              thead,
              tbody {
                th,
                td {
                  color: $white;
                }
              }
            }
            .card-footer {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-border-color;
            }
            .switch {
              .switch-state {
                background-color: $dark-body-background;
              }
              input {
                &:checked {
                  + .switch-state {
                    background-color: $primary-color;
                  }
                }
              }
            }
            .bg-white {
              background-color: $dark-card-background !important;
            }
            .b-l-light {
              border-left: 1px solid $dark-border-color !important;
            }
            .ct-grid {
              stroke: $dark-small-font-color;
            }
            .ct-label {
              color: $dark-small-font-color;
            }
            hr {
              border-top: 1px solid $dark-border-color;
            }
            .calender-widget {
              .cal-date {
                background-color: $dark-card-background;
                border: 1px solid $dark-border-color;
              }
            }
            .contact-form {
              .theme-form {
                border: 1px solid $dark-border-color;
                .form-icon {
                  background-color: $dark-card-background;
                  border: 1px solid $dark-border-color;
                  color: $dark-all-font-color;
                }
              }
            }
            .btn-outline-light,
            .btn-outline-dark,
            .btn-outline-light-2x {
              color: $white !important;
            }
            .btn-outline-light {
              &:hover {
                color: $black !important;
              }
            }
            .border-right {
              border-right: 1px solid $dark-border-color !important;
            }
            .flot-chart-placeholder {
              .flot-text {
                color: $dark-all-font-color !important;
              }
              svg {
                text {
                  fill: $dark-all-font-color;
                }
              }
            }
            .chart-overflow {
              &#line-chart,
              &#column-chart1,
              &#column-chart2 {
                rect {
                  fill: $dark-card-background;
                }
              }
              svg {
                > rect {
                  fill: $dark-card-background;
                }
                > g {
                  > g {
                    > g {
                      text {
                        fill: rgb(177, 177, 178);
                      }
                    }
                  }
                  > text {
                    fill: $dark-all-font-color;
                  }
                }
              }
            }
            .radial-bar {
              &:after {
                background-color: $dark-card-background;
                color: $dark-all-font-color;
              }
            }
            .bar-chart-widget {
              .bottom-content {
                .num {
                  color: $dark-all-font-color;
                  .color-bottom {
                    color: $dark-all-font-color;
                  }
                }
              }
            }
            .b-r-light {
              border-right: 1px solid $dark-border-color !important;
            }
            .chart-container {
              .live-products,
              .turnover,
              .monthly,
              .uses {
                .ct-labels {
                  .ct-label {
                    color: $white;
                  }
                }
                .ct-grid {
                  stroke: $white;
                }
              }
              #browser-uses-chart,
              #website-visiter-chart {
                svg {
                  text {
                    fill: $dark-all-font-color;
                  }
                }
              }
            }
            .status-details {
              h4 {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
            .clipboaard-container {
              .form-control {
                background-color: $dark-body-background;
                color: $dark-small-font-color;
                border: 1px solid $dark-border-color;
              }
            }
            .img-thumbnail {
              background-color: $dark-body-background;
              border: 1px solid $dark-border-color;
            }
            .dataTables_wrapper {
              .dataTables_length {
                select {
                  background-color: $dark-body-background;
                  color: $dark-all-font-color;
                  border: 1px solid $dark-border-color;
                }
              }
              .dataTables_length,
              .dataTables_filter,
              .dataTables_info,
              .dataTables_processing,
              .dataTables_paginate {
                color: $dark-all-font-color;
              }
              .dataTables_paginate {
                border: 1px solid $dark-border-color;
              }
              .dataTables_filter {
                input[type="search"] {
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                  color: $dark-all-font-color;
                }
              }
              table.dataTable.display,
              table.dataTable.order-column.stripe {
                tbody {
                  tr {
                    background-color: $dark-card-background;
                    &:hover {
                      > .sorting_1 {
                        background-color: #28303c;
                      }
                    }
                  }
                  tr.odd {
                    background-color: $dark-body-background;
                    > .sorting_1 {
                      background-color: #28303c;
                    }
                  }
                  tr.even {
                    > .sorting_1 {
                      background-color: rgb(46, 58, 76);
                    }
                  }
                }
              }
              table.dataTable {
                border: 1px solid $dark-border-color;
                thead {
                  th,
                  td {
                    border-bottom: 2px solid $dark-border-color;
                  }
                }
                input,
                select {
                  background-color: $dark-body-background;
                  color: $dark-all-font-color;
                  border: 1px solid $dark-border-color;
                }
                tbody {
                  tr {
                    background-color: $dark-card-background;
                  }
                  td.select-checkbox,
                  th.select-checkbox {
                    &:before {
                      border: 1px solid $dark-small-font-color;
                    }
                  }
                }
              }
              .table-striped {
                tbody {
                  tr {
                    &:nth-of-type(odd) {
                      background-color: $dark-body-background;
                    }
                  }
                }
              }
              .dataTables_paginate {
                .paginate_button {
                  color: $dark-all-font-color !important;
                  &.current,
                  &:active {
                    border-color: $primary-color;
                  }
                }
                .paginate_button.disabled {
                  color: $dark-small-font-color !important;
                  &:hover,
                  :active {
                    color: $dark-small-font-color !important;
                  }
                }
              }
              table.dataTable.row-border,
              table.dataTable.display {
                tbody {
                  th,
                  td {
                    border-top: 1px solid $dark-border-color;
                  }
                }
              }
              table.dataTable.display,
              table.dataTable.order-column.hover {
                tbody {
                  tr.even {
                    &:hover {
                      > .sorting_1 {
                        background-color: #282f3a;
                      }
                    }
                  }
                }
              }
              table.dataTable.hover,
              table.dataTable.display {
                tbody {
                  tr {
                    &:hover {
                      background-color: #28303c;
                    }
                  }
                }
              }
              table.dataTable.cell-border {
                th,
                td {
                  border-top: 1px solid $dark-border-color;
                  border-right: 1px solid $dark-border-color;
                  &:first-child {
                    border-left: 1px solid $dark-border-color;
                  }
                }
              }
              table.dataTable.order-column,
              table.dataTable.display {
                tbody {
                  tr {
                    > .sorting_1,
                    > .sorting_2,
                    > .sorting_3 {
                      background-color: $dark-body-background;
                    }
                  }
                }
              }
            }
            #example-style-3_wrapper {
              #example-style-3 {
                tfoot {
                  border-top: 2px solid $dark-border-color;
                }
              }
            }
            .page-item.disabled {
              .page-link {
                background-color: $dark-card-background;
                border-color: $dark-border-color;
              }
            }
            .page-link {
              background-color: $dark-card-background;
              border: 1px solid $dark-border-color;
              &:hover {
                background-color: $dark-border-color;
              }
            }
            .page-item.active {
              .page-link {
                background-color: $primary-color;
              }
            }
            .ecommerce-widget {
              .icon {
                color: $dark-body-background;
              }
              .total-num {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
            .flot-chart-container-small {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-border-color;
            }
            .product-table {
              #API-2_wrapper {
                #API-2 {
                  tbody {
                    td {
                      span,
                      p {
                        color: $dark-small-font-color;
                      }
                    }
                  }
                }
              }
              h6 {
                color: $dark-small-font-color;
              }
            }
            .nav-material.nav-tabs {
              border-bottom: 1px solid $dark-border-color;
              .nav-item {
                .nav-link {
                  color: $dark-all-font-color;
                }
              }
              .nav-link {
                &.active,
                &:focus,
                &:hover {
                  color: $primary-color;
                }
              }
            }
            .br-theme-bars-1to10,
            .br-theme-bars-movie,
            .br-theme-bars-pill,
            .br-theme-bars-reversed,
            .br-theme-bars-horizontal {
              .br-widget {
                a {
                  background-color: $dark-border-color;
                  &.br-active,
                  &.br-selected {
                    background-color: $primary-color;
                  }
                }
              }
            }
            .br-theme-bars-square {
              .br-widget {
                a {
                  border: 2px solid $dark-border-color;
                  background-color: $dark-card-background;
                  color: $dark-all-font-color;
                  &.br-active,
                  &.br-selected {
                    border: 2px solid $primary-color;
                    color: $primary-color;
                  }
                }
              }
            }
            .br-theme-fontawesome-stars,
            .br-theme-fontawesome-stars-o {
              .br-widget {
                a {
                  &:after {
                    color: $dark-border-color;
                  }
                  &.br-selected,
                  &.br-active {
                    &:after {
                      color: $primary-color;
                    }
                  }
                }
              }
            }
            .scroll-demo {
              border: 1px solid $dark-border-color;
            }
            .search-form {
              input {
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
                background-color: $dark-body-background;
              }
              .form-group {
                &:before {
                  background: $dark-card-background;
                }
              }
            }
            .cd-timeline-content {
              background-color: $dark-border-color;
              &::before {
                border-left: 7px solid $dark-border-color;
              }
            }
            .cd-timeline-block {
              &:nth-child(even) {
                .cd-timeline-content {
                  &::before {
                    border-right-color: $dark-border-color;
                    border-left: transparent;
                  }
                }
              }
            }
            .breadcrumb {
              background-color: $dark-body-background;
            }
            .user-status {
              table {
                td,
                th {
                  border-top: none !important;
                }
              }
            }
            #donut-color-chart-morris-daily,
            #donut-color-chart-morris,
            #browser-uses-chart {
              svg {
                opacity: 0.5;
              }
            }
          }
          .form-builder-header-1,
          .form-builder-2-header {
            background-color: $dark-body-background;
          }
          .form-builder {
            .nav-primary {
              .show {
                > .nav-link {
                  color: $white;
                }
              }
              .nav-link {
                color: $dark-all-font-color;
                &.active {
                  color: $white;
                }
              }
              .nav-pills.nav-primary {
                .show {
                  > .nav-link {
                    color: $white;
                  }
                }
                .nav-link {
                  color: $dark-all-font-color;
                  &.active {
                    color: $white;
                  }
                }
              }
            }
            .drag-box {
              fieldset {
                border: 1px solid $dark-border-color;
              }
            }
            .help-block {
              color: $dark-small-font-color;
            }
          }
          #viewhtml {
            .render {
              background-color: $dark-card-background;
              color: $dark-all-font-color;
              border-color: $dark-border-color;
            }
          }
          .form-builder-column {
            .drag-bx {
              border: 1px dotted $dark-border-color;
            }
          }
          .wizard-4 {
            ul.anchor {
              li {
                a.disabled {
                  color: $dark-body-background;
                }
              }
            }
          }
          .gallery-with-description {
            h4 {
              color: $dark-all-font-color;
            }
            a {
              > div {
                border: 1px solid $dark-border-color;
              }
            }
            p {
              color: $dark-small-font-color;
            }
          }
          .jsgrid-grid-header {
            background-color: $dark-card-background;
            border: 1px solid $dark-border-color;
          }
          .jsgrid-header-row,
          .jsgrid-filter-row {
            > .jsgrid-header-cell,
            > .jsgrid-cell {
              background: $dark-card-background;
              border: 1px solid $dark-border-color;
              input {
                background-color: $dark-body-background;
                border-color: $dark-border-color;
                color: $dark-all-font-color;
              }
            }
            select {
              background-color: $dark-body-background;
              border-color: $dark-border-color;
              color: $dark-all-font-color;
            }
          }
          .jsgrid-row {
            > .jsgrid-cell {
              background-color: $dark-card-background;
            }
          }
          .jsgrid-alt-row {
            > .jsgrid-cell {
              background-color: $dark-body-background;
            }
          }
          .jsgrid-cell,
          .jsgrid-grid-body {
            border: 1px solid $dark-border-color;
          }
          .jsgrid-selected-row {
            > .jsgrid-cell {
              background-color: #28303c;
            }
          }
          .jsgrid {
            .jsgrid-pager {
              [class*="jsgrid-pager"] {
                border: 1px solid $dark-border-color;
              }
              .jsgrid-pager-page {
                a {
                  color: $dark-all-font-color;
                }
              }
            }
          }
          .jsgrid-pager-current-page {
            color: $dark-all-font-color;
            font-weight: 700;
          }
          .sub-title {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          }
          .navs-icon {
            li {
              a {
                color: $dark-all-font-color;
                &:hover {
                  color: $primary-color;
                }
              }
            }
            .separator {
              border: 1px solid $dark-border-color;
            }
          }
          .default-according {
            li {
              .text-muted {
                color: $dark-all-font-color !important;
              }
            }
          }
          .navs-icon.default-according.style-1 {
            li {
              button {
                &:hover {
                  color: $primary-color !important;
                }
              }
            }
          }
          .nav-list {
            .nav-list-disc {
              li {
                a {
                  color: $dark-all-font-color;
                }
                &:hover {
                  color: $primary-color;
                  a {
                    color: $primary-color;
                  }
                }
              }
            }
          }
          .navs-dropdown {
            .onhover-show-div {
              background-color: $dark-body-background;
              box-shadow: 0 0 2px 2px $dark-border-color;
              .navs-icon {
                li {
                  a {
                    svg {
                      path,
                      line,
                      polyline,
                      polygon,
                      rect {
                        color: $dark-all-font-color !important;
                      }
                    }
                    &:hover {
                      svg {
                        path,
                        line,
                        polyline,
                        polygon,
                        rect {
                          color: $primary-color !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .onhover-dropdown {
            &:hover {
              .onhover-show-div {
                &:before {
                  border-bottom: 7px solid $dark-body-background;
                }
              }
            }
          }
          .default-according {
            .card {
              box-shadow: 0px 0px 1px 1px rgb(55, 66, 82);
              .btn-link {
                color: $dark-all-font-color;
              }
              .card-body {
                color: $dark-small-font-color;
              }
            }
          }
          .border {
            border: 1px solid $dark-border-color !important;
          }
          .blog-box {
            .blog-date {
              color: $dark-all-font-color;
            }
            .blog-details,
            .blog-details-main {
              .blog-social {
                li {
                  color: $dark-small-font-color;
                  border-right: 1px solid $dark-border-color;
                  &:first-child {
                    border-right: 1px solid $dark-border-color;
                  }
                  &:last-child {
                    border-right: none;
                  }
                }
              }
              p {
                color: $dark-all-font-color;
              }
              .single-blog-content-top {
                border-top: 1px solid $dark-border-color;
                p {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .comment-box {
            .media {
              h6 {
                span {
                  color: $dark-small-font-color;
                }
              }
              img {
                border: 1px solid $dark-border-color;
              }
            }
            p {
              color: $dark-small-font-color;
            }
            .comment-social {
              li {
                color: $dark-small-font-color;
                &:first-child {
                  border-right: 1px solid $dark-border-color;
                }
              }
            }
            hr {
              border-top: 1px solid $dark-border-color;
            }
          }
          .table-hover {
            tbody {
              tr {
                &:hover {
                  background-color: $dark-body-background;
                  td,
                  th {
                    color: $white;
                  }
                }
              }
            }
          }
          ul.the-icons {
            li {
              border: 1px dotted $dark-border-color;
              color: $sidebar-submenu-font-color;
              &:hover {
                background: $black;
                box-shadow: 0 0 3px $dark-card-background;
              }
            }
          }
          .button-builder-wrap {
            .box {
              background-color: $dark-card-background;
              border: 1px solid $dark-border-color;
            }
            .button-preview {
              h2 {
                color: $dark-all-font-color;
              }
            }
            pre.well {
              background-color: $dark-card-background !important;
            }
          }
          .crm-activity {
            > li {
              + li {
                border-top: 1px solid $dark-border-color;
              }
              h6 {
                color: $dark-all-font-color;
              }
            }
            ul.dates {
              li {
                color: $dark-small-font-color;
              }
            }
          }
          #donut-color-chart-morris,
          #donut-color-chart-morris-daily {
            svg {
              text {
                fill: $dark-all-font-color;
              }
            }
          }
          .custom-card {
            .card-profile {
              img {
                background-color: $dark-card-background;
              }
            }
            .profile-details {
              h6 {
                color: $dark-small-font-color;
              }
            }
            .card-social {
              li {
                a {
                  color: $dark-small-font-color;
                }
              }
            }
            .card-footer {
              > div {
                h6 {
                  color: $dark-small-font-color;
                }
                + div {
                  border-left: 1px solid $dark-border-color;
                }
              }
            }
          }
          .form-control {
            background-color: $dark-body-background;
            color: $dark-all-font-color;
            border: 1px solid $dark-border-color;
          }
          .checkbox,
          .radio {
            label {
              &::before {
                background-color: $dark-body-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
            }
          }
          .dropdown-basic {
            .dropdown {
              .dropdown-content {
                background-color: $dark-body-background;
                a {
                  color: $dark-all-font-color;
                  &:hover {
                    background-color: $dark-card-background;
                  }
                }
                .dropdown-divider {
                  border: 1px solid $dark-border-color;
                }
              }
            }
          }
          .dropup-basic {
            .dropup {
              .dropup-content {
                background-color: $dark-body-background;
                a {
                  color: $dark-all-font-color;
                  &:hover,
                  &.active {
                    background-color: $dark-card-background;
                  }
                }
              }
            }
          }
          .dropzone.dz-clickable {
            .dz-message {
              h6 {
                color: $dark-all-font-color;
              }
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .dropzone {
            .dz-preview {
              background-color: $dark-body-background;
              .dz-details {
                background-color: $dark-card-background;
              }
            }
          }
          .browser-widget {
            .media-body {
              column-rule: 1px solid $dark-border-color;
              h4 {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .email-wrap {
            .email-app-sidebar {
              .media {
                img {
                  border: 2px solid $dark-body-background;
                }
              }
              .main-menu {
                > li {
                  a {
                    color: $dark-all-font-color;
                    &:hover {
                      background-color: $dark-body-background;
                    }
                  }
                }
              }
            }
            .email-right-aside {
              .email-body {
                .inbox {
                  .media.active {
                    background-color: $dark-body-background;
                  }
                }
              }
            }
            .media-body {
              h6 {
                color: $dark-all-font-color;
                small {
                  color: $dark-small-font-color;
                  span {
                    color: $dark-small-font-color;
                  }
                }
              }
              p {
                color: $dark-small-font-color;
              }
            }
            .email-top {
              border-bottom: 1px solid $dark-border-color;
            }
            p {
              color: $dark-small-font-color;
            }
            .email-content {
              .email-top {
                .user-emailid:after {
                  border: 1px solid $dark-border-color;
                }
              }
            }
          }
          .dropdown-menu {
            background-color: $dark-body-background;
            border: 1px solid $dark-border-color;
            a {
              color: $dark-all-font-color;
              &:hover {
                background-color: $dark-card-background;
              }
            }
          }
          .icon-lists {
            div {
              i {
                color: $dark-small-font-color;
              }
              &:hover {
                background-color: $dark-body-background;
              }
            }
          }
          .widget-joins {
            .media {
              .media-body {
                > span {
                  color: $dark-small-font-color;
                }
              }
              .details {
                border-left: 1px solid $dark-border-color;
              }
            }
            &:before,
            &:after {
              background-color: $dark-border-color;
            }
          }
          .redial-social-widget {
            i {
              background-color: $dark-card-background;
            }
          }
          .social-widget-card {
            h5,
            h4 {
              color: $dark-all-font-color;
            }
            span {
              color: $dark-small-font-color;
            }
          }
          .b-b-light {
            border-bottom: 1px solid $dark-border-color !important;
          }
          .b-r-dark {
            border-right: 1px solid $white !important;
          }
          .testimonial {
            i {
              color: $dark-border-color;
            }
            p {
              color: $dark-all-font-color;
            }
            h5 {
              color: $dark-all-font-color;
            }
            span {
              color: $dark-small-font-color;
            }
          }
          .grid-showcase {
            span {
              background-color: $dark-card-background;
              border: 1px solid $dark-border-color;
            }
          }
          .grid-align {
            .row {
              background-color: $dark-border-color;
              border: 1px solid $dark-border-color;
            }
          }
          .border-style {
            .card {
              border: 1px solid $dark-border-color;
            }
          }
          .offer-style {
            .card {
              border: 1px dashed $dark-border-color;
            }
          }
          .page-builder {
            .btn-grid {
              background-color: $dark-card-background;
              color: $dark-small-font-color !important;
              border-right: 1px solid $dark-border-color;
            }
            .ge-mainControls {
              .ge-addRowGroup {
                .ge-row-icon {
                  .column {
                    border-left: 3px solid $dark-card-background;
                  }
                }
              }
            }
            .btn-code,
            .btn-screen {
              color: $dark-small-font-color;
              border-right: 1px solid $dark-border-color;
            }
            .ge-canvas.ge-editing {
              .row {
                background-color: $dark-body-background;
              }
              .column {
                border: 1px solid $dark-border-color;
                background-color: $dark-card-background;
              }
              .ge-tools-drawer {
                .ge-details {
                  input {
                    background-color: $dark-body-background;
                    border-color: $dark-border-color;
                    color: $dark-small-font-color;
                  }
                  .btn-group {
                    a {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
            }
            .ge-content-type-ckeditor {
              color: $dark-all-font-color;
            }
          }
          .search-page {
            .info-block {
              + .info-block {
                border-top: 1px solid $dark-border-color;
              }
            }
          }
          .card-absolute {
            .bg-primary,
            .bg-secondary {
              h5 {
                color: $white;
              }
            }
          }
          .timeline-small {
            .media {
              .timeline-round {
                &.timeline-line-1,
                &.small-line,
                &.medium-line {
                  &:after {
                    background-color: $dark-border-color;
                  }
                }
              }
            }
          }
          /* body end*/
        }
        footer {
          border-top: 1px solid $dark-body-background;
          background-color: $dark-card-background;
        }
        .form-select,
        .custom-file-label {
          background: $dark-body-background;
          color: $dark-all-font-color;
        }
        .footer-fix {
          background-color: $dark-card-background;
          border-top: 1px solid $dark-border-color;
        }
      }
      .dt-button-info {
        background-color: $dark-card-background;
        border: 1px solid $dark-small-font-color;
        h2 {
          background-color: $dark-card-background;
        }
      }
      .chat-box {
        .about {
          .name {
            color: $dark-all-font-color;
          }
        }
        .chat-menu {
          border-left: 1px solid $dark-border-color;
          .nav-tabs {
            border-bottom: 1px solid $dark-border-color;
            .nav-item {
              .nav-link {
                &.active {
                  color: $dark-all-font-color !important;
                }
              }
            }
          }

          .user-profile {
            .image {
              .icon-wrapper {
                background-color: $dark-card-background;
                box-shadow: 1px 1px 3px 1px $dark-body-background;
              }
              .avatar {
                img {
                  border: 5px solid $dark-border-color;
                }
              }
            }
            .social-media {
              a {
                color: $dark-small-font-color;
              }
            }
            .follow {
              span {
                color: $dark-small-font-color;
              }
              .follow-num {
                color: $dark-all-font-color;
              }
            }
          }
        }
        .status {
          color: $dark-small-font-color;
          p {
            color: $dark-all-font-color !important;
          }
        }
        .chat-right-aside {
          .chat {
            .chat-header {
              border-bottom: 1px solid $dark-border-color;
              .chat-menu-icons {
                li {
                  a {
                    i {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
              img {
                box-shadow: 1px 1px 4px 1px $dark-body-background;
              }
            }
            .chat-msg-box {
              .my-message {
                border: 1px solid $dark-border-color;
              }
              .message {
                color: $dark-all-font-color;
              }
              .other-message {
                background-color: $dark-body-background;
              }
            }
            .chat-message {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-border-color;
              .input-group {
                .form-control {
                  background-color: $dark-card-background;
                }
              }
            }
          }
        }
        .chat-history {
          .call-icons {
            ul {
              li {
                border: 1px solid $dark-border-color;
                a {
                  color: $dark-small-font-color;
                }
              }
            }
          }
        }
        .bitcoin-chat {
          .chat {
            .chat-msg-box {
              .message {
                &.my-message {
                  background-color: #1c222b;
                  .chat-user-img {
                    border: 2px solid $dark-body-background;
                  }
                }
                &.other-message {
                  .chat-user-img {
                    border: 2px solid $dark-body-background;
                  }
                }
              }
            }
          }
        }
      }
      pre {
        background-color: $dark-border-color;
        color: $dark-all-font-color;
      }
      .scorlled {
        background-color: $dark-body-background;
      }
      .input-group-air {
        box-shadow: 0 3px 20px 0 $dark-border-color;
      }
      .input-group-solid {
        .input-group-text,
        .form-control {
          background: $dark-body-background;
          border: 1px solid $dark-border-color;
          color: $dark-all-font-color;
        }
      }
      .semilight-bg-color,
      .header-bg-color {
        background-color: $white;
      }
      .list-group-item {
        background-color: $dark-card-background;
        border: 1px solid rgba(255, 255, 255, 0.3);
        color: $dark-all-font-color;
        &.active {
          background-color: $primary-color;
          border-color: $primary-color;
          color: $white;
        }
        &:hover,
        &:focus {
          z-index: 1;
          text-decoration: none;
        }
      }
      .list-group-item-action {
        &:hover:not(.active),
        &:focus {
          background-color: $dark-body-background;
        }
      }
      .list-group-flush {
        .list-group-item {
          border-left: 0;
          border-right: 0;
          border-radius: 0;
        }
      }
      .list-group-item-primary {
        color: #004085;
        background-color: #b8daff;
      }
      .list-group-item-secondary {
        color: #383d41;
        background-color: #d6d8db;
      }
      .list-group-item-success {
        color: #155724;
        background-color: #c3e6cb;
      }
      .list-group-item-danger {
        color: #721c24;
        background-color: #f5c6cb;
      }
      .list-group-item-warning {
        color: #856404;
        background-color: #ffeeba;
      }
      .list-group-item-info {
        color: #0c5460;
        background-color: #bee5eb;
      }
      .list-group-item-light {
        color: #818182;
        background-color: #fdfdfe;
      }
      .list-group-item-dark {
        color: #1b1e21;
        background-color: #c6c8ca;
      }
      .auth-bg {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.9);
        .authentication-box {
          .card {
            background-color: $dark-card-background;
            .theme-form {
              .form-group {
                input {
                  color: $dark-all-font-color;
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                }
                label {
                  color: $dark-all-font-color;
                }
              }
              .checkbox {
                label {
                  color: $dark-all-font-color;
                  &::before {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
          }
          h3,
          h4,
          h6 {
            color: $white;
          }
        }
      }
      .auth-bg-video {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.7);
        .authentication-box {
          h4,
          h3 {
            color: $white;
          }
          h6 {
            color: $dark-small-font-color;
          }
          .card {
            background-color: $dark-card-background;
            .theme-form {
              .form-group {
                input[type="text"],
                input[type="password"] {
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                  color: $dark-all-font-color;
                }
              }
              .checkbox {
                label {
                  &::before {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
          }
        }
      }
      .error-wrapper {
        .maintenance-icons {
          li {
            i {
              color: $dark-border-color;
            }
            &:nth-child(3) {
              i {
                color: $dark-border-color;
              }
            }
          }
        }
      }
      .modal-content {
        background-color: $dark-card-background;
        .modal-header {
          border-bottom: 1px solid $dark-border-color;
          .btn-close {
            color: $dark-small-font-color;
          }
        }
        .modal-footer {
          border-top: 1px solid $dark-border-color;
        }
      }
      .comingsoon {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.9);
        .comingsoon-inner {
          h5 {
            color: $dark-all-font-color;
          }
          .countdown {
            border-top: 1px solid $dark-border-color;
            border-bottom: 1px solid $dark-border-color;
            .title {
              color: $dark-all-font-color;
            }
          }
          .coming-soon-bottom-link {
            a {
              color: $dark-all-font-color;
            }
          }
        }
      }
      .theme-form {
        .login-divider {
          border-top: 1px solid $dark-border-color;
          &:before {
            background: $dark-border-color;
            color: $dark-all-font-color;
          }
        }
      }
      .authentication-main {
        background-color: $dark-border-color;
        .auth-innerright {
          .reset-password-box {
            .card {
              background-color: $dark-card-background;
            }
            .reset-password-link {
              color: $dark-small-font-color;
            }
            .theme-form {
              .form-group {
                label {
                  color: $dark-small-font-color;
                }
                .form-control {
                  background-color: $dark-body-background;
                  color: $dark-all-font-color;
                  border-color: $dark-border-color;
                }
              }
              .opt-box {
                background-color: $dark-border-color;
              }
            }
          }
          .authentication-box {
            h4 {
              color: $dark-all-font-color;
            }
            h6 {
              color: $dark-small-font-color;
            }
            h3 {
              color: $dark-all-font-color;
            }
            .card {
              background-color: $dark-card-background;
              .theme-form {
                .form-group {
                  input[type="text"],
                  input[type="password"] {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-border-color;
                    color: $dark-all-font-color;
                  }
                }
                .checkbox {
                  label {
                    &::before {
                      background-color: $dark-body-background;
                      border: 1px solid $dark-border-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .default-according {
        .card {
          background-color: $dark-card-background;
          .btn-link {
            background-color: $dark-card-background;
            border: 1px solid $dark-card-background;
            color: $white;
          }
          .text-muted {
            color: $dark-small-font-color !important;
          }
        }
        .bg-primary {
          .btn-link {
            background-color: $primary-color;
            border: 1px solid $primary-color;
          }
        }
        .bg-secondary {
          .btn-link {
            background-color: $secondary-color;
            border: 1px solid $secondary-color;
          }
        }
      }
      .collapse {
        .card-body {
          background-color: $dark-card-background;
        }
      }
      @media screen and (max-width: 1660px) {
        .chat-box {
          .chat-history {
            .call-icons {
              ul {
                li {
                  border: 1px solid $dark-border-color;
                }
              }
            }
            .total-time {
              h2 {
                color: $dark-all-font-color;
              }
            }
          }
        }
        .caller-img {
          img {
            opacity: 0.7;
          }
        }
        .chat-box {
          .chat-history {
            .call-content {
              > div {
                background-color: rgba(0, 0, 0, 0.75);
                background-blend-mode: overlay;
              }
            }
          }
        }
      }
      @media only screen and (max-width: 1199px) {
        .chat-menu {
          border-top: 1px solid $dark-border-color;
          background-color: $dark-card-background;
        }
        .error-wrapper {
          .maintenance-icons {
            li {
              i {
                color: $dark-color;
              }
            }
          }
        }
      }
      @media only screen and (max-width: 575px) {
        .user-profile {
          .hovercard {
            .info {
              .user-designation {
                border-top: 1px solid $dark-border-color;
                border-bottom: 1px solid $dark-border-color;
              }
            }
          }
        }
        .widget-joins {
          .border-after-xs {
            &:after {
              background-color: $dark-border-color;
            }
          }
        }
      }
    }
    .lg-backdrop {
      background-color: $dark-body-background;
    }
    .lg-outer {
      .lg-thumb-outer {
        background-color: $dark-card-background;
      }
    }
    .drag {
      background-color: $dark-card-background;
      form {
        background: $dark-card-background;
        box-shadow: 0 0 30px $dark-border-color;
        border: 1px dotted $dark-border-color;
        input {
          background-color: $dark-body-background;
          border: 1px solid $dark-border-color;
          color: $dark-all-font-color;
        }
        .help-block {
          text-transform: capitalize;
          color: $dark-small-font-color;
        }
      }
    }
    .draggable {
      input {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
        border: 1px solid $dark-border-color;
      }
      p {
        color: $dark-small-font-color;
      }
      select {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
        border: 1px solid $dark-border-color;
      }
      .radio,
      .checkbox {
        label {
          &::before {
            background-color: $dark-body-background;
            border: 1px solid $dark-border-color;
          }
        }
      }
    }
    .select2-container--default {
      .select2-selection--multiple,
      .select2-selection--single {
        background-color: $dark-body-background;
        border: 1px solid $dark-border-color !important;
      }
      .select2-search--inline {
        .select2-search__field {
          color: $dark-all-font-color;
        }
      }
      .select2-selection--single {
        .select2-selection__rendered {
          color: $dark-all-font-color;
        }
      }
      .select2-search--dropdown {
        .select2-search__field {
          background-color: $dark-body-background;
          border: 1px solid $dark-border-color;
          color: $dark-all-font-color;
        }
      }
    }
    .select2-dropdown {
      background-color: $dark-body-background;
      border: 1px solid $dark-border-color;
    }
    .select2-drpdwn {
      .form-control-primary {
        border-color: $primary-color !important;
        color: $primary-color !important;
      }
      .form-control-secondary {
        border-color: $secondary-color !important;
        color: $secondary-color !important;
      }
      .form-control-success {
        border-color: $success-color !important;
        color: $success-color !important;
      }
      .form-control-info {
        border-color: $info-color !important;
        color: $info-color !important;
      }
      .form-control-warning {
        border-color: $warning-color !important;
        color: $warning-color !important;
      }
      .form-control-danger {
        border-color: $danger-color !important;
        color: $danger-color !important;
      }
      .form-control-inverse {
        border-color: $white !important;
        color: $white !important;
      }
      .form-control-primary-fill {
        background-color: $primary-color !important;
        color: $white !important;
      }
      .form-control-secondary-fill {
        background-color: $secondary-color !important;
        color: $white !important;
      }
      .form-control-success-fill {
        background-color: $success-color !important;
        color: $white !important;
      }
      .form-control-info-fill {
        background-color: $info-color !important;
        color: $white !important;
      }
      .form-control-warning-fill {
        background-color: $warning-color !important;
        color: $white !important;
      }
      .form-control-danger-fill {
        background-color: $danger-color !important;
        color: $white !important;
      }
      .form-control-inverse-fill {
        background-color: $white !important;
        color: $black !important;
      }
    }
    .select2-container--default.select2-container--disabled {
      .select2-selection--single {
        background-color: $dark-border-color;
      }
    }
    .theme-form {
      .form-group {
        select.form-control:not([size]):not([multiple]) {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          border-color: $dark-border-color;
        }
        input[type="text"],
        input[type="email"],
        input[type="search"],
        input[type="password"],
        input[type="number"],
        input[type="tel"],
        input[type="date"],
        input[type="datetime-local"],
        input[type="time"],
        input[type="datetime-local"],
        input[type="month"],
        input[type="week"],
        input[type="url"],
        input[type="file"],
        select {
          border-color: $dark-border-color;
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          &::-webkit-input-placeholder {
            color: $dark-small-font-color;
          }
        }
        .form-control {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
        }
        textarea {
          border-color: $dark-border-color;
        }
      }
      .form-divider {
        border-top: 1px solid $dark-border-color;
        &::before {
          background: $dark-card-background;
          color: $dark-all-font-color;
        }
      }
    }
    .CodeMirror {
      background: $dark-card-background;
      border: 1px solid $dark-small-font-color;
      color: $dark-all-font-color;
      .CodeMirror-code {
        pre {
          background: $dark-card-background;
        }
      }
    }
    .editor-toolbar {
      border-top: 1px solid $dark-small-font-color;
      border-left: 1px solid $dark-small-font-color;
      border-right: 1px solid $dark-small-font-color;
      a {
        color: $dark-all-font-color !important;
        &:hover,
        &.active {
          background: $dark-border-color;
        }
      }
      i.separator {
        border-left: 1px solid $dark-small-font-color;
        border-right: 1px solid $dark-small-font-color;
      }
    }
    .editor-toolbar.disabled-for-preview {
      a {
        &:not(.no-disable) {
          background: $dark-border-color;
        }
      }
    }
    .editor-preview {
      background-color: $dark-card-background;
    }
    .editor-toolbar.fullscreen,
    .editor-preview-side {
      background: $dark-card-background;
      border: 1px solid $dark-small-font-color;
    }
    .u-step {
      background: $dark-border-color;
      &.active,
      &.current {
        background: $primary-color;
        color: $white;
      }
    }
    .u-step-title,
    .u-pearl-title {
      color: $dark-all-font-color;
    }
    .u-step-number {
      background-color: $dark-card-background;
    }
    .u-pearl {
      &:before {
        background-color: $dark-border-color;
      }
    }
    .u-pearl-number,
    .u-pearl-icon {
      background: $dark-body-background;
      border: 2px solid $dark-body-background;
    }
    .u-pearl.disabled {
      .u-pearl-icon,
      .u-pearl-number {
        background: $dark-border-color;
        border: 2px solid $dark-border-color;
      }
      &:after {
        background-color: #334053;
      }
    }
    .u-pearl.error {
      &:after {
        background-color: $dark-body-background;
      }
    }
    .note-editor.note-frame {
      border-color: $dark-border-color;
      .note-editing-area {
        .note-editable {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
        }
      }
    }
    .swal-modal {
      background-color: $dark-card-background;
      .swal-title {
        color: $dark-all-font-color;
      }
      .swal-text {
        color: $dark-small-font-color;
      }
      .swal-content__input {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
      }
    }
    .nav-tabs {
      border-bottom: 1px solid $dark-border-color;
      .nav-link {
        &.active {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border-color: $dark-border-color $dark-border-color
            $dark-card-background;
        }
        &:hover,
        &:focus {
          border-color: $dark-border-color $dark-border-color $dark-border-color;
        }
      }
      .nav-item.show {
        .nav-link {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border-color: $dark-border-color $dark-border-color
            $dark-card-background;
        }
      }
    }
    .nav-tabs.nav-bottom {
      .nav-item {
        .nav-link {
          &.active,
          &.focus,
          &.hover {
            border-color: $dark-card-background $dark-border-color
              $dark-border-color;
          }
        }
      }
    }
    .nav-material.nav-left,
    .nav-material.nav-right {
      .nav-link {
        color: $dark-all-font-color;
        &.active {
          color: $primary-color;
        }
      }
      .show {
        > .nav-link {
          color: $primary-color;
        }
      }
    }
    .nav-material.nav-left.nav-secondary {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          border-left-color: $secondary-color;
          color: $secondary-color !important;
        }
      }
      .show > .nav-link {
        border-left-color: $secondary-color;
        color: $secondary-color !important;
      }
      .nav-item.show {
        color: $secondary-color !important;
        border-left-color: $secondary-color;
      }
    }
    .nav-material.nav-left.nav-success {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $success-color !important;
        }
      }
      .show > .nav-link {
        border-left-color: $success-color;
        color: $success-color !important;
      }
      .nav-item.show {
        color: $success-color !important;
        border-left-color: $success-color;
      }
    }
    .nav-material.nav-right.nav-info {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $info-color !important;
        }
      }
      .show > .nav-link {
        border-left-color: $info-color;
        color: $info-color !important;
      }
      .nav-item.show {
        color: $info-color !important;
        border-left-color: $info-color;
      }
    }
    .nav-material.nav-secondary {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $secondary-color !important;
        }
      }
      .nav-item {
        &.show {
          color: $secondary-color !important;
        }
      }
    }
    .nav-material.nav-success {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $success-color !important;
        }
      }
      .nav-item {
        &.show {
          color: $success-color !important;
        }
      }
    }
    .dropdown-divider {
      border-top: 1px solid $dark-border-color;
    }
    .icon-hover-bottom {
      background-color: $dark-card-background;
      box-shadow: 0px 0px 1px 1px $dark-border-color;
      .icon-title {
        color: $dark-all-font-color;
      }
      span {
        color: $dark-small-font-color;
      }
      .form-group {
        input {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          border: 1px solid $dark-border-color;
        }
      }
      .icon-first {
        i {
          color: $dark-small-font-color;
        }
      }
    }
    code {
      background-color: #d8ecff;
      border-radius: 2px;
    }
    #cd-timeline {
      &::before {
        background: $dark-border-color;
      }
    }
    .timeliny {
      border-top: 1px solid $dark-border-color;
      border-bottom: 1px solid $dark-border-color;
      &::before {
        background-color: $white;
      }
      .timeliny-dot {
        background-color: $dark-card-background;
        border: 1px solid $white;
        &::before {
          color: $dark-all-font-color;
        }
      }
      .timeliny-timeline {
        .timeliny-timeblock {
          &.active {
            .timeliny-dot {
              &::before {
                color: $dark-all-font-color;
              }
            }
          }
          &:not(.inactive):not(.active) {
            .timeliny-dot {
              &:hover {
                background-color: $white;
                &::before {
                  color: $dark-all-font-color;
                }
              }
            }
          }
          .timeliny-dot {
            &:hover {
              &::after {
                background-color: $dark-card-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
    }
    .todo {
      .todo-list-wrapper {
        #todo-list {
          li {
            .task-container {
              background: $dark-card-background;
              border-bottom: 1px solid $dark-border-color;
              .task-label {
                color: $dark-all-font-color;
              }
              &:hover {
                h4 {
                  color: $white;
                }
              }
              .task-action-btn {
                .action-box {
                  &:hover:not(.active) {
                    background: $dark-card-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
            &.completed {
              .task-container {
                .task-label {
                  color: $primary-color;
                }
                .complete-btn {
                  &:hover {
                    border: 1px solid $success-color !important;
                  }
                }
              }
            }
          }
        }
        .todo-list-footer {
          .new-task-wrapper {
            textarea {
              background-color: $dark-card-background;
              border: 1px solid $dark-border-color;
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    .user-profile {
      .ttl-info {
        h6 {
          color: $dark-small-font-color;
        }
        span {
          color: $dark-all-font-color;
        }
      }
      .hovercard {
        .info {
          .title {
            a {
              color: $dark-all-font-color;
            }
          }
        }
        .user-image {
          .avatar {
            img {
              border: 10px solid $dark-card-background;
            }
          }
          .icon-wrapper {
            background-color: $dark-card-background;
          }
        }
        .tabs-scoial {
          border-bottom: none !important;
        }
      }
      .follow {
        .follow-num {
          color: $dark-all-font-color;
        }
      }
      .profile-img-style {
        .user-name {
          color: $dark-all-font-color;
        }
        p {
          color: $dark-small-font-color;
        }
      }
    }
    .introjs-helperLayer {
      background-color: rgba(54, 64, 74, 0.9);
      border: rgba(255, 255, 255, 0.5);
    }
    .jstree-default {
      .jstree-node,
      .jstree-icon {
        color: #455869 !important;
      }
      .jstree-anchor {
        color: $dark-small-font-color;
      }
      .jstree-clicked {
        color: $white;
        background-color: transparent;
      }
      .jstree-hovered {
        background-color: $dark-body-background;
        border: 1px solid $dark-border-color;
      }
    }
    span.twitter-typeahead {
      .tt-menu {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
      }
      .tt-suggestion {
        color: $dark-all-font-color;
        &:hover,
        &:focus {
          background-color: $dark-card-background;
        }
      }
    }
    .typography {
      small {
        color: $dark-all-font-color;
      }
    }
    .blockquote-footer {
      color: $dark-small-font-color;
    }
    .code-box-copy {
      pre {
        background-color: $dark-body-background;
        code {
          background-color: $dark-body-background;
        }
      }
      pre[class*="language-"] {
        border: 1px solid $dark-border-color;
      }
      .code-box-copy__btn {
        background-color: $dark-body-background;
        border: 1px solid $dark-border-color;
        color: $white;
      }
      code[class*="language-"],
      pre[class*="language-"] {
        text-shadow: 0px 1px $black;
        ::selection {
          text-shadow: none;
          background: $dark-card-background;
        }
      }
    }
    table.fixedHeader-floating {
      background-color: $dark-body-background;
    }
    .dt-button-info {
      background-color: $dark-card-background;
      border: 1px solid $dark-border-color;
      h2 {
        background-color: $dark-card-background;
        border-bottom: 1px solid $dark-border-color;
      }
    }
    #keytable_wrapper,
    #column-selector_wrapper,
    #auto-fill_wrapper,
    #custom-button_wrapper,
    #class-button_wrapper,
    #keyboard-btn_wrapper,
    #multilevel-btn_wrapper,
    #pagelength-btn_wrapper,
    #basic-colreorder_wrapper,
    #state-saving_wrapper,
    #real-time_wrapper,
    #basic-fixed-header_wrapper,
    #fixed-header-footer_wrapper,
    #export-button_wrapper,
    #excel-cust-bolder_wrapper,
    #cust-json_wrapper,
    #basic-key-table_wrapper,
    #focus-cell_wrapper,
    #responsive_wrapper,
    #new-cons_wrapper,
    #show-hidden-row_wrapper,
    #basic-row-reorder_wrapper,
    #full-row_wrapper,
    #rest-column_wrapper {
      .dataTables_paginate {
        border: none;
      }
    }
    #example-style-8_wrapper {
      table.dataTable.stripe,
      table.dataTable.display {
        tbody {
          tr.odd {
            background-color: $dark-border-color;
          }
        }
      }
    }
    .error-wrapper {
      .sub-content {
        color: $dark-all-font-color;
      }
    }
    .b-light {
      border: 1px solid $dark-border-color !important;
    }
    .modal-content {
      background-color: $dark-card-background;
      .modal-header {
        border-bottom: 1px solid $dark-border-color;
      }
      .ui-front {
        .form-control {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border: 1px solid $dark-border-color;
        }
      }
    }
    .stepwizard {
      .stepwizard-row {
        &:before {
          background-color: $dark-small-font-color;
        }
      }
    }
    .modal {
      .theme-close {
        background-color: $dark-card-background !important;
      }
    }
    .token.atrule,
    .token.attr-value,
    .token.keyword {
      color: $info-color;
    }
    .token {
      &.boolean,
      &.constant,
      &.deleted,
      &.number,
      &.property,
      &.symbol,
      &.tag {
        color: $danger-color;
      }
    }
    .loader-box {
      &.card-loader {
        background-color: $dark-card-background;
      }
    }
    .my-gallery {
      &.gallery-with-description {
        img {
          border: 1px solid $dark-border-color !important;
          border-bottom: none !important;
        }
      }
    }
    @each $var in $alert-name {
      $i: index($alert-name, $var);
      .alert-#{$var}.inverse {
        color: $dark-all-font-color;
      }
    }
    .alert-theme {
      span {
        + span {
          + span {
            border: 1px solid $primary-color;
            background-color: $dark-body-background;
            border-radius: 4px;
          }
        }
      }
      i {
        background-color: $primary-color;
        color: $white;
        padding: 20px;
        margin-right: 20px !important;
      }
    }
    .user-card {
      .user-deatils {
        h6 {
          color: $dark-small-font-color;
        }
      }
      .card-footer {
        > div {
          &:first-child {
            border-right: 1px solid $dark-border-color;
          }
        }
        .user-footer {
          h6 {
            color: $dark-small-font-color;
          }
          svg {
            path,
            rect {
              color: $dark-small-font-color;
            }
          }
        }
      }
    }
    .dashboard-chat {
      .chat {
        .media {
          .media-body {
            .message-main {
              p {
                background-color: $dark-body-background;
              }
              &.smiley-bg {
                background-color: $dark-body-background;
              }
            }
          }
        }
        .right-side-chat {
          .media-body {
            p {
              &:before {
                border-left: 7px solid $dark-body-background;
              }
            }
          }
        }
        .left-side-chat {
          .media-body {
            p {
              &:before {
                border-right: 7px solid $dark-body-background;
              }
            }
          }
        }
      }
    }
    .selling-update {
      border: 2px solid $dark-border-color;
    }
    .bitcoin-accordion {
      .card {
        .media-accordion {
          .media:nth-child(2) {
            background-color: #1c222b;
          }
          .media {
            .media-body {
              p {
                color: $dark-small-font-color;
              }
            }
            + .media {
              border-top: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    .invest-dropdown {
      .right-header-dropdown {
        &.onhover-show-div {
          background-color: $dark-body-background;
          box-shadow: 0 0 2px 2px $dark-body-background;
          a {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .server-card-bg {
      background-color: #181f27 !important;
    }
    .server-widgets {
      .bottom-server {
        h5 {
          .second-color {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .select2-drpdwn-project {
      .form-control {
        background-color: $dark-card-background !important;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
      }
    }
    .tabs-project {
      .nav-item {
        .nav-link {
          color: $dark-all-font-color;
        }
      }
    }
    .current-progress,
    .order-history {
      table {
        tr {
          td,
          th {
            border-top: none !important;
          }
        }
      }
      .title-orders {
        background-color: $dark-body-background;
      }
    }
    .navigation-option {
      ul {
        li {
          a {
            color: $dark-all-font-color;
          }
          &:hover {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .product-box {
      .product-details {
        h6 {
          color: $dark-all-font-color;
        }
        span {
          color: $dark-small-font-color;
        }
      }
      .product-img {
        .product-hover {
          ul {
            li {
              background-color: $dark-card-background;
              &:hover {
                background-color: $black;
                color: $white;
              }
            }
          }
        }
      }
    }
    .browse {
      .browse-articles {
        ul {
          li {
            a {
              color: $dark-all-font-color;
            }
            &:hover {
              background-color: $dark-body-background;
            }
          }
        }
      }
    }
    @each $btn-name, $btn-color in (primary, $primary-color),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .radio-#{$btn-name} {
        input[type="radio"] {
          & + label {
            &::before {
              border-color: $btn-color !important;
            }
            &::after {
              background-color: $btn-color;
            }
          }
          &:checked {
            & + label {
              &::before {
                border-color: $btn-color !important;
              }
              &::after {
                background-color: $btn-color;
              }
            }
          }
        }
      }
    }

    @each $btn-name, $btn-color in (primary, $primary-color),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .checkbox-#{$btn-name} {
        label {
          &::before {
            border-color: $btn-color !important;
          }
        }
      }
    }

    @each $btn-name, $btn-color in (primary, $primary-color),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .checkbox-solid-#{$btn-name} {
        label {
          &:before {
            background-color: $btn-color !important;
            border-color: $btn-color !important;
            color: $white !important;
          }
        }
        input[type="checkbox"] {
          &:checked {
            & + label {
              &::before {
                background-color: $btn-color !important;
                border-color: $btn-color !important;
                color: $white !important;
              }
              &::after {
                color: $white !important;
              }
            }
          }
        }
      }
    }
    .bootstrap-datetimepicker-widget {
      ul {
        li {
          color: $dark-all-font-color;
          border-bottom: 1px solid $dark-border-color;
        }
      }
      tr {
        th,
        td {
          &:hover {
            background-color: $dark-card-background;
          }
          span {
            &:hover {
              background-color: $dark-card-background;
            }
          }
          &.prev,
          &.next {
            &:hover {
              background-color: $dark-card-background;
            }
          }
        }
        &:first-child {
          th {
            &:hover {
              background-color: $dark-card-background;
            }
          }
        }
      }
    }
    .btn-transparent {
      color: $dark-all-font-color;
    }
    #cal-basic {
      .fc-toolbar {
        .fc-left,
        .fc-right {
          .fc-next-button {
            .fc-icon-right-single-arrow {
              &:after {
                color: $dark-small-font-color;
              }
            }
          }
          .fc-prev-button {
            .fc-icon-left-single-arrow {
              &:after {
                color: $dark-small-font-color;
              }
            }
          }
        }
        .fc-left {
          .fc-button-group {
            .fc-month-button {
              color: $white !important;
            }
          }
        }
      }
    }
    .fc-button-group {
      .fc-basicWeek-button,
      .fc-basicDay-button,
      .fc-agendaWeek-button,
      .fc-agendaDay-button {
        color: $black !important;
      }
      .fc-month-button {
        color: $white !important;
      }
    }
    #cal-agenda-view {
      .fc-toolbar {
        .fc-right {
          .fc-button-group {
            .fc-month-button,
            .btn-light {
              color: $black !important;
            }
            .fc-agendaWeek-button {
              color: $white !important;
              &:hover {
                color: $black !important;
              }
            }
          }
        }
      }
    }
    .basic-calendar,
    #cal-bg-events,
    #cal-event-colors {
      .fc-toolbar {
        .fc-right {
          .fc-button-group {
            .fc-basicWeek-button,
            .btn-light {
              color: $black !important;
            }
            .fc-month-button {
              color: $white !important;
              &:hover {
                color: $black !important;
              }
            }
          }
        }
      }
    }
    .categories {
      ul {
        li {
          a {
            color: $dark-small-font-color;
          }
          &:hover {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .main {
      .langChoice {
        background: $dark-body-background;
        color: $white;
        border: 1px solid $dark-border-color;
      }
    }
    .socialprofile {
      .likes-profile {
        h5 {
          span {
            color: $dark-all-font-color;
          }
        }
      }
      span {
        color: $dark-small-font-color;
      }
    }
    .social-status {
      .media {
        .media-body {
          span {
            + span {
              color: $dark-small-font-color;
            }
          }
          p,
          .light-span {
            color: $dark-small-font-color;
          }
        }
      }
      form {
        .form-group {
          .form-control-plaintext {
            border: 1px solid $dark-border-color;
          }
        }
      }
    }
    .filter-cards-view,
    .timeline-content {
      p {
        color: $dark-small-font-color;
      }
      .comment-number {
        i {
          color: $dark-small-font-color;
        }
      }
      .comments-box {
        .input-group {
          .form-control {
            border: none !important;
          }
          .input-group-append {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .social-chat {
      .media-body {
        border: 1px solid $dark-border-color;
        &:after {
          border-right: 7px solid $dark-card-background;
        }
      }
      span {
        span {
          color: $dark-small-font-color;
        }
      }
    }
    .details-about {
      .your-details {
        p {
          color: $dark-small-font-color;
        }
      }
    }
    .activity-log {
      .my-activity {
        p {
          color: $dark-small-font-color;
        }
      }
    }
    &.rtl {
      .page-main-header {
        .main-header-right {
          .nav-right {
            > ul {
              > li {
                border-right: none;
                &:nth-child(2) {
                  border-right: 1px solid $dark-border-color;
                }
                &:nth-child(5) {
                  border-right: none;
                  border-left: 1px solid $dark-border-color;
                }
                &:nth-child(4) {
                  border-left: none;
                }
              }
            }
          }
        }
      }
    }
    .bookmark {
      ul {
        li {
          a {
            color: $dark-all-font-color !important;
          }
        }
      }
    }
  }
}
/**=====================
   55. theme layout CSS ends
==========================**/
